import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { getHiddenKeywordListingNoRedux, linkKeywordToVisible } from '../../Services/keywordsApis';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

function LinkHiddenKeyword({ open, handleOpen, getListing, vid,keywordsForId}) {
  const [loading, setLoading] = useState(false);
  const blurRef=useRef(null);
  const [groupData, setGroupData] = useState(false);
  useEffect(()=>{
     setIntialData();
  },[keywordsForId])

  const setIntialData=()=>{
        if(keywordsForId){
         let newArr= keywordsForId?.map((a)=>({value:a?.aid,label:a?.all_keyword}));
         setGroupData(newArr);
         formik.setFieldValue('hidden_keywords', newArr?.map(a=>a?.label)?.join(","));
        }
  }

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await linkKeywordToVisible(values)
    if(resp){
      let payloadForLogs = {
        action: action?.link,
        module: modulesNames?.keywords,
        description: logsMessagesList?.keyword?.keywordVisibleLinked,
      };
      await adminAddLogs(payloadForLogs);
      resetForm({
        hidden_keywords : "",
        status : "0",
      })
      getListing()
      handleOpen(false)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      visible_keyword_id: vid,
      hidden_keywords : "",
      status : "1",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      visible_keyword_id: Yup.string().required('Visible keyword id is required.'),
      hidden_keywords: Yup.string().required('Hidden keyword name is required.'),
      status: Yup.string().required('Status is required.')
    }),
    onSubmit: handleConfirm,
  });

  const getGroupsListing = async (inputValue) =>{
    if(inputValue && inputValue.length >= 2){
      let data = await getHiddenKeywordListingNoRedux({
        search: inputValue,
        page: "1",
        limit: '20'
      })   
      if(data){
        let updatedData = data?.map((item, index)=>{
          return{
            value: item.aid,
            label: item.all_keyword
          }
        })
        return  updatedData || []
      }
      return []
    }
  }

  const handleData = (data, index)=>{
    if(data){
      setGroupData(data);
      formik.setFieldValue('hidden_keywords', data?.map(a=>a?.label)?.join(","));
    }else{
      formik.setFieldValue('hidden_keywords', formik?.values?.hidden_keywords?formik?.values?.hidden_keywords:'');

    }
      
  }

  return (
    <div>
      <Dialog
        maxWidth={'xs'}
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
        fullWidth
      >
        <DialogTitle variant='h3'>Link Keyword</DialogTitle>
        <DialogContent>
        <Box
            noValidate
            component="form"
            sx={{
              // width: 400
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomAsyncSelect
                  promiseOptions={getGroupsListing}
                  closeMenuOnSelect={false}
                  controlStyle={{
                    padding: 10
                  }}
                  // isMulti={false}
                  width={'100%'}
                  dropDownZIndex={2000}
                  cacheOptions={true}
                  value={groupData}
                  handleChange={(e) => {
                    handleData(e);
                    blurRef?.current?.blur();
                  }}
                  ref={blurRef}
                  error={formik.touched.hidden_keywords && Boolean(formik.errors.hidden_keywords)}
                  helperText={formik.touched.hidden_keywords && formik.errors.hidden_keywords}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="status"
                  label="Status"
                  placeholder="Status"
                  value={formik.values.status}
                  select
                  SelectProps={{
                    native: true
                  }}
                  InputLabelProps={{ shrink: true }}
                  onChange={formik.handleChange}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.status && Boolean(formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                >
                  <option value={"0"}>Inactive</option>
                  <option value={"1"}>Active</option>
                </CustomInput>
              </Grid>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}>
              <CustomButton color={'buttonPrimary'} type="submit" loading={loading}
               
              >Confirm</CustomButton>
              <CustomButton
                onClick={() => {
                    formik.resetForm({
                      hidden_keyword_id : "",
                      status : "0",
                    });
                    handleOpen(false)}
                }
                disabled={loading}
              >Cancel</CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default LinkHiddenKeyword;
