import React, { useEffect, useState } from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { Box, Typography } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { commonItemsSelector } from '../../redux/common';
import { useDispatch, useSelector } from 'react-redux';
import { getCountry } from '../../Services/commonService';
import Divder from '../CommonComp/Divder';
import MatTable from '../Tables/MatTable';
import { DATA_LIMIT } from '../../data/constants';
import CountryListModal from '../Modals/CountryListModal';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'Country ID',
    id: 'country_id',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
       }}

      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Country Name',
    id: 'country_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'States Available',
    id: 'states_available',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Status',
    id: 'status',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData=="1"? "Active": "Inactive"}
      </span>
    ),
  },
];

function CountryList() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedField, setSelectedField] = useState(null);
  const [openModal, setOpenModal] = useState(false); 
  const { loading, countryData } = useSelector(commonItemsSelector);
  const dispatch = useDispatch();

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...params,
    };
    dispatch(getCountry(payload));
    setPage(Number(payload.page));
    // handleStateToggle(Math.random())
  };

  useEffect(() => {
    getListing();
  }, []);

  //making data format for table input
  useEffect(() => {
    if (countryData?.items?.rows?.length >= 0) {
      setTableListing(countryData?.items?.rows || []);
    }
  }, [countryData.items]);

  const handleModal = (value = false) => {
    setOpenModal(value);
    if (!value) {
      setTimeout(() => {
        setSelectedField(null);
      }, 100);
    }
  };

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton className="buttonDense" sx={{ bgcolor: 'buttonInfo.main' }} onClick={() => {
              handleModal(true);
              setSelectedField(data);
            }}>Edit</CustomButton>
            </div>
      </>
    );
  };


  return (
    <>
      <CustomBox padding={3} sx={{minHeight: 200}}>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: 'wrap'}}>
          <Typography variant="h3" sx={{ mb:{md: 0, xs: 1} }}>Country List</Typography>
          <CustomButton color={'buttonPrimary'} onClick={()=>{
            handleModal(true);
            setSelectedField(null);
          }}>
            Add
          </CustomButton>
        </Box>
        <Divder spacing={1} />
        <MatTable
          loading={loading}
          showCheckbox={false}
          columns={column}
          data={tableListing}
          action={actionBody}
          page={page}
          perPage={limit}
          total={countryData?.items?.count}
          setPage={setPage}
          handlePageChange={(newPage) => {
            getListing({
              page: String(newPage),
            });
          }}
          pagination={true}
          disableSearch={true}
        />
        <CountryListModal open={openModal} handleOpen={handleModal} data={selectedField} getListing={getListing}/>
      </CustomBox>
    </>
  );
}
export default CountryList;
