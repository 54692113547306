import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import {
  formatDate,
  getNewParameters,
  getObjectSubset,
  getParameters,
} from '../../lib/helper';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import { contributorItemsSelector } from '../../redux/contributor';
import { contributorNocList, deleteNoc } from '../../Services/ContributorApis';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import AddPayment from '../Modals/AddPayment';
import { Box } from '@material-ui/core';
import ConfirmationModal from '../Modals/ConfirmationModal';
import { adminAddLogs } from '../../Services/commonService';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'ID',
    id: 'image_noc_id',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Name',
    id: 'noc_name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData || 'N/A'}
        
      </span>
    ),
  },
  {
    label: 'Created At',
    id: 'created_at',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'dd-mm-yyyy')}
      </span>
    ),
  },
];

function NocListing() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { contributorNocsList } = useSelector(contributorItemsSelector);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { emailId, contributorId } = useParams();
  const [confirmDeleteStatus, setConfirmDeleteStatus] = useState(false);

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      contributor_email: emailId,
      contributor_id: contributorId,
      ...allParams,
      ...params,
    };
    dispatch(contributorNocList(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, [emailId]);

  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }
    getListing();
  }, [allParams]);

  useEffect(() => {
    if (contributorNocsList?.items?.rows?.length >= 0) {
      setTableListing(contributorNocsList?.items?.rows);
    }
  }, [contributorNocsList?.items?.rows]);

  const handleAdd = (value = false) => {
    setAddModal(value);
  };

  const handleEdit = (value, data = {}) => {
    setEditModal(value);
    setSelectedData(data);
  };

  const handleConfirmDelete = async (value) => {
    if (value && selectedData) {
      const payload = { image_noc_id: selectedData.image_noc_id };
      const resp = await deleteNoc(payload);
      if (resp) {
          let payloadForLogs = {
            action: action?.delete,
            module: modulesNames?.contributors,
            description: logsMessagesList?.contributor?.contributorNOCDelete,
          };
          await adminAddLogs(payloadForLogs);
        getListing({ page: '1' });
        setPage(1);
        setSelectedData(null);
      } else {
        setSelectedData(null);
      }
    }
  };

  const handleConfirmModalOpen = (value, data = null) => {
    setSelectedData(data);
    setConfirmDeleteStatus(value);
  };

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            toolTipTittle={'Delete NOC'}
            onClick={() => {
              handleConfirmModalOpen(true, data);
            }}
            className="buttonDense noMinWidth"
            sx={{
              bgcolor: 'buttonDanger.main',
              color: 'buttonDanger.contrastText',
            }}
          >
            Delete
          </CustomButton>
          <CustomButton
            toolTipTittle={'Open NOC'}
            // onClick={() => {
             
            //   window.open(`https://s3.ap-south-1.amazonaws.com/awsimages.imagesbazaar.com/image_noc/${data?.noc_name}`, '_blank'); 
            // }}
            className="buttonDense noMinWidth"
            sx={{
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            {/* to={`fileFolderList/${row?.contributor_id}`} state={row?.contributor_id} */}
             <Link  to={`/contributor/contributorPdf/${data?.noc_name}`}  
            //  target="_blank"
              style={{color:'white'}}>View</Link>

          </CustomButton>
        </div>
      </>
    );
  };

  return (
    <>
      <CustomBox>
        <MatTable
          loading={contributorNocsList?.loading}
          showCheckbox={false}
          columns={column}
          data={tableListing}
          onApplyFilter={(data = {}) => {
            getListing({
              page: String(1),
              start_date: '',
              end_date: '',
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
            if (Object.keys(data).length > 0) {
              setAllParams({
                ...allParams,
                page: String(1),
                ...getObjectSubset(data, 'start_date', 'end_date'),
              });
            } else {
              setAllParams({
                page: String(1),
              });
            }
          }}
          action={actionBody}
          page={page}
          perPage={limit}
          total={contributorNocsList?.items?.count || 0}
          setPage={setPage}
          handleSearch={(searchTerm) => {
            getListing({
              page: String(1),
              search: searchTerm,
            });
            setAllParams({
              ...allParams,
              page: String(1),
              search: searchTerm,
            });
          }}
          handlePageChange={(newPage) => {
            getListing({
              page: String(newPage),
            });
            setAllParams({
              ...allParams,
              page: String(newPage),
            });
          }}
          toolbarEnable={false}
          pagination={true}
          filtersUrlData={allParams || {}}
        />

        {addModal && (
          <AddPayment
            type={'add'}
            open={addModal}
            handleOpen={handleAdd}
            setPage={setPage}
            handleLast={getListing}
            contributorId={emailId}
          />
        )}
        {editModal && (
          <AddPayment
            type={'edit'}
            open={editModal}
            handleOpen={handleEdit}
            setPage={setPage}
            handleLast={getListing}
            contributorId={emailId}
            selectedData={selectedData}
          />
        )}
        {confirmDeleteStatus && (
          <ConfirmationModal
            open={confirmDeleteStatus}
            handleConfirm={handleConfirmDelete}
            setOpen={handleConfirmModalOpen}
            confirmationTitle={'Delete NOC'}
          />
        )}
      </CustomBox>
    </>
  );
}

export default NocListing;
