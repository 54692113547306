import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { updateAdminUserPassword } from '../../Services/adminUsersApis';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

function EditPasswordAdminUserModal({ open, handleOpen, getListing, selectedData }) {
  const [loading, setLoading] = useState(false);
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await updateAdminUserPassword(values)
    if (resp) {
      let payloadForLogs = {
        action:action?.update,
        module: modulesNames?.adminAndAccess,
        description:logsMessagesList?.adminAndAccess?.adminPasswordUpdate
      };
      await adminAddLogs(payloadForLogs);
      resetForm();
      getListing();
      handleOpen(false);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      admin_id: selectedData?.admin_id || "",
      new_password: '',
      confirm_password: ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      admin_id: Yup.string().required('Admin id is required.'),
      new_password: Yup.string().required('New Password is required.'),
      confirm_password: Yup.string().required('Please confirm your password.'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Update Password
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="email"
                  label="Email"
                  placeholder="Email"
                  value={selectedData?.email}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="new_password"
                  label="New Password"
                  placeholder="New Password"
                  value={formik.values.new_password}
                  onChange={(e) => {
                    formik.setValues((values) => ({
                      ...values,
                      'new_password': e.target.value,
                      'confirm_password': e.target.value
                    }))
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Submit
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    password: '',
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EditPasswordAdminUserModal;
