import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import AdminLogsUsers from './AdminLogsUsers'

function AdminLogsContainer() {
  return (
    <MainLayout navTitle={"Admin Logs"} navSubTitle={"Welcome to ImagesBazaar"}>
      <AdminLogsUsers />
    </MainLayout>
  )
}

export default AdminLogsContainer