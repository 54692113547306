import React, { useState } from 'react';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid, Typography } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import { useFormik } from 'formik';
import { updateFolderLimit } from '../../Services/ContributorApis';
import { adminAddLogs } from '../../Services/commonService';
import { toast } from 'react-toastify';
import {
  action,
  logsMessagesList,
  modulesNames,
} from '../../data/logsMessages';

export default function FolderLimitModal({
  open,
  setOpen,
  setUpdateData,
  setPage,
  status,
  updateData,
  confirmationTitle,
  getListing,
  confirmationDesc,
  confirmText,
  cancleText,
  data,
  render,
}) {
  const [loading, setLoading] = useState(false);
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    if (status && updateData) {
      if (values.limit <= 30 && values.limit > updateData.folderImages) {
        const resp = await updateFolderLimit({
          contributor_id: updateData.contributor_id,
          folder_name: updateData.folder_name,
          images_limit: values.limit,
        });
        if (resp) {
          let payloadForLogs = {
            action: action?.update,
            module: modulesNames?.contributors,
            description:
              logsMessagesList?.contributor?.contributorFolderLimitUpdate,
          };
          await adminAddLogs(payloadForLogs);
          getListing({ page: '1' });
          setPage(1);
          setUpdateData(null);
          resetForm({
            limit: '',
          });
          setOpen(false);
          setLoading(false);
        } else {
          setUpdateData(null);
        }
      } else {
        toast.error(
          'Folder limit can not exceed 30 and should be greater than image count!',
        );
        // setOpen(false);
        setLoading(false);
      }
      // handleLast();
    }
  };
  const formik = useFormik({
    initialValues: {
      limit: data?.limit || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      limit: Yup.string().required('Limit is required.'),
    }),
    onSubmit: handleConfirm,
  });
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {!!confirmationTitle && (
        <DialogTitle component={'div'} id="alert-dialog-title">
          <Typography variant="h3">{confirmationTitle}</Typography>
        </DialogTitle>
      )}
      {!!confirmationDesc || !!render ? (
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            {!!confirmationDesc && (
              <DialogContentText id="alert-dialog-description">
                <Typography variant="p" color={'red'}>
                  {confirmationDesc}
                </Typography>
              </DialogContentText>
            )}
            {render && render()}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
                marginLeft: '5px',
                marginRight: '5px',
              }}
            >
              <CustomInput
                variant="outlined"
                fullWidth
                name="limit"
                label="Folder Limit"
                placeholder="Folder Limit"
                value={formik.values.limit}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.limit && Boolean(formik.errors.limit)}
                helperText={formik.touched.limit && formik.errors.limit}
              />
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Confirm
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    limit: '',
                  });
                  setOpen(false);
                }}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      ) : null}
    </Dialog>
  );
}

FolderLimitModal.defaultProps = {
  open: false,
  setOpen: () => {},
  handleConfirm: () => {},
  confirmationTitle: 'Alert!!',
  confirmationDesc: 'Are you sure you want to update limit?',
  confirmText: 'Confirm',
  cancleText: 'Cancel',
  render: null,
};
