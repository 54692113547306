import { Grid, MenuItem, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';
import { sendMail } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import Divder from '../CommonComp/Divder';
import { ExpiryEmailData } from '../../data/expiryEmailData';
import TextEditor from '../CommonComp/TextEditor/TextEditor';
import { formatDate } from '../../lib/helper';
import { useNavigate } from 'react-router-dom';
import { getUserDatalist } from '../../Services/userApis';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

function ExpiryEmailPage({ orderId, email, planId, couponName, couponAmount, expiry}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [userDetail, setUserDetail] = useState(null)
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await sendMail(values)
    if(resp){
      let payloadForLogs = {
        action: action?.emailSent,
        module: modulesNames?.orders,
        description: logsMessagesList?.order?.expiryMail,
      };
      await adminAddLogs(payloadForLogs);
      navigate(-1)
    }
    setLoading(false)
  };
 
  const formik = useFormik({
    initialValues: {
      mail_to: userDetail?.user_name?.trim() || email?.trim() || '',
      mail_cc: userDetail?.cc_mail ||'',
      subject: "Last day today to Renew your Subscription",
      expiry_days: 'select',
      content: `<p>Hi${userDetail?.first_name ?" " + userDetail?.first_name + ' ' + userDetail?.last_name : " User"},</p>
      <p><br/></p>
      <p>Our records indicate that your Subscription Pack <strong>Order no.${orderId}</strong> will expire on <strong>${formatDate(expiry, 'mon dd yyyy')}</strong>.</p>
      ${couponAmount ? `<p><strong>RENEW NOW and get INR ${couponAmount} OFF!</strong></p>`: null}
      ${couponName ? `<p><strong>Coupon Code: ${couponName} (Valid till ${formatDate(expiry, 'mon dd yyyy')})</strong></p><p> </p>`: null}
      <p>If you have any questions about your Subscription Renewal, kindly feel free to call us on <strong>1800-11-6869</strong>.</p>
      <p><br/></p>
      <p>Best Regards,</p>
      <p>Support Team</p>
      <p><a href=${ApiConfig.LIVE_WEBSITE_URL} rel="noopener noreferrer" target="_blank">${ApiConfig.LIVE_WEBSITE_URL}</a></p>`,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      mail_to: Yup.string().email('Please enter Email').required('Please enter Email'),
      subject: Yup.string().required('Please enter subject'),
      content: Yup.string().required('Please enter content'),
    }),
    onSubmit: handleConfirm,
  });

  const handleChange = (content)=>{
    if(content == '<p><br></p>'){
      formik.setFieldValue('content', '')
    }else{
      formik.setFieldValue('content', content)
    }
  }

  const handleUserFetch = async () =>{
    try {
      setLoading(true);
      let userData = await getUserDatalist({ user_name: email });
      if (userData) {
        setUserDetail(userData)
      }
      setLoading(false);
    }catch(e){
      setLoading(false);
    }
  }

  useEffect(()=>{
    if(email){
      handleUserFetch()
    }
  },[email])

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <CustomBox padding={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CustomInput
                variant="outlined"
                fullWidth
                select
                name="expiry_days"
                label="Expiry Days"
                placeholder="Expiry Days"
                value={formik.values.expiry_days}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e)=>{
                  formik.setFieldValue('expiry_days', e.target.value);
                  if(e.target.value != 'select'){
                    if(e.target.value == 'today'){
                      formik.setFieldValue('subject', `Last day ${e.target.value} to Renew your Subscription.`);
                    }else{
                      formik.setFieldValue('subject', `Last ${e.target.value} left to Renew your Subscription.`);
                    }
                  }
                }}
              >
              {
                ExpiryEmailData?.ExpiryType?.map((item, index)=>{
                  return <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                })
              }
              </CustomInput>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="mail_to"
                label="Mail to"
                disabled
                placeholder="Mail to"
                value={formik.values.mail_to}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.mail_to &&Boolean(formik.errors.mail_to)}
                helperText={formik.touched.mail_to && formik.errors.mail_to}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="mail_cc"
                label="Mail cc"
                placeholder="Mail cc"
                value={formik.values.mail_cc}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="subject"
                label="Subject"
                placeholder="Subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.subject &&Boolean(formik.errors.subject)}
                helperText={formik.touched.subject && formik.errors.subject}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h5' style={{marginBottom: 8, paddingLeft: 3}}>Mail Body</Typography>
              <TextEditor content={formik.values.content} handleChange={handleChange} 
                error={formik.touched.content && Boolean(formik.errors.content)}
                helperText={formik.touched.content && formik.errors.content}
              />
            </Grid>
            <Grid item xs={12} style={{textAlign: "end"}}>
              <CustomButton type="submit" loading={loading} color={'buttonPrimary'} sx={{ padding: '8px 20px' }}>
                Send
              </CustomButton>
            </Grid>
          </Grid>
        </CustomBox>
        <Divder spacing={1} />
        
      </form>
    </div>
  );
}

export default ExpiryEmailPage;
