import { Search } from '@material-ui/icons';
import { Box, Grid, InputAdornment, MenuItem, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';
import { orderDetalData } from '../../data/OrderData';
import { orderListingItemsSelector, setOrderDetails } from '../../redux/orderListing';
import { confirmOrder, editOrderInvoice, orderDetailsApi } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import Divder from '../CommonComp/Divder';
import MatTable from '../Tables/MatTable';
import { crypt, decrypt, formatDate, getDataFromAppSetting, humanize, validDate } from '../../lib/helper';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { commonItemsSelector } from '../../redux/common';

import { adminAddLogs, companyByNameNoRedux, getCountry, getState } from '../../Services/commonService';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { userItemsSelector } from '../../redux/user';
import { loginItemsSelector } from '../../redux/login';
import CustomLoading from '../CommonComp/CustomLoading/CustomLoading';
import OrderDetailsInput from './OrderDetailsInput';
import EditInvoiceItems from '../Modals/EditInvoiceItems';
import { Edit } from '@mui/icons-material';
import UserDetailInputField from '../User/UserDetailInputField';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';

const column = [
  {
    label: '',
    id: 'image',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <CustomImage
        style={{ maxWidth: 150, borderRadius: 8 }}
        src={`${ApiConfig.BASE_IMAGE_URL}${columnData?.shootid}/${columnData?.rank}-${row?.type === '1' ? columnData?.Name : columnData?.video_name}.jpg`}
      />
    ),
  },
  {
    label: 'Item ID',
    id: 'image_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Type',
    id: 'image_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Rights',
    id: 'right',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <span style={{ display: 'inline-block', width: 'max-content', maxWidth: 300, wordBreak: 'break-all' }}>
        {row?.is_star === "1" ? (
          <>
            <div>{row?.right || "Non-Exclusive"}</div>
            {/* <div style={{ marginTop: "5px" }}>
              License: {row?.license}
            </div> */}
            <div style={{ marginTop: "5px" }}>
              Industry: {row?.industry}
            </div>
            <div style={{ marginTop: "5px" }}>
              Territory: {String(row?.state).length ? row?.state : row?.country}
            </div>
            <div style={{ marginTop: "5px" }}>
              Duration: {row?.duration_for_star}
            </div>
          </>
        ) : (
          <>
            <div>{row?.right || "Non-Exclusive"}</div>
            {row?.duration ? (
              <div>{row?.duration}</div>
            ) : null}
          </>
        )}
      </span>
    ),
  },
  {
    label: 'Dimensions (Pixels.)',
    id: 'dimension',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Amount (INR)',
    id: 'price',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
];
const columnIvs = [
  {
    label: '',
    id: 'image',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <>
        <div style={{
          color: "#fff", padding: 10, borderRadius: 7, ...(row?.quality?.toLowerCase()?.includes('large')
            ? { backgroundColor: "green" } :
            row?.quality?.toLowerCase()?.includes('web') ? { backgroundColor: '#ba36c1' } :
              row?.quality?.toLowerCase()?.includes('small') ? { backgroundColor: 'rgb(58 152 235)' } :
                { backgroundColor: '#404d93' })
        }}>
          <Typography variant='h6' sx={{ textAlign: "center" }}>{row?.quality}</Typography>
        </div>
      </>
    ),
  },
  {
    label: 'Plan Name',
    id: 'image_name',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {humanize(row?.quality)}Size-{columnData}
      </span>
    ),
  },
  {
    label: 'Max Download',
    id: 'dimension',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {row?.image_name}
      </span>
    ),
  },
  {
    label: 'Validity of Packages',
    id: 'image_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData} Days
      </span>
    ),
  },
  {
    label: 'Packages Values',
    id: 'price',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
];

const TotalStripe = ({ totalAmount, discount, orderDiscount, planInfo, voucherDiscount, orderType, orderServiceTax, orderNetPayableAmountData }) => {
  return <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: 'wrap' }}>
    <Box sx={{ display: "flex", alignItems: "center", }}>
      <Typography variant='h6' sx={{ marginLeft: (theme) => theme.spacing(1) }}>Total Amount:</Typography>
      <Typography variant='mediumBold' sx={(theme) => ({ marginLeft: theme.spacing(1), color: theme.palette.info.main })}>{totalAmount}</Typography>
    </Box>
    {discount && (orderType?.toLowerCase() == 'nplan') ? <Box sx={{
      display: "flex",
      alignItems: "center",
    }}>
      <Typography variant='h6' sx={{ marginLeft: (theme) => theme.spacing(1) }}>Discount:</Typography>
      <Typography variant='mediumBold' sx={(theme) => ({ marginLeft: theme.spacing(1), color: theme.palette.info.main })}>{orderDiscount}</Typography>
    </Box> : null}
    {planInfo && (orderType?.toLowerCase() == 'iv') ? <Box sx={{
      display: "flex",
      alignItems: "center",
    }}>
      <Typography variant='h6' sx={{ marginLeft: (theme) => theme.spacing(1) }}>Coupon ({planInfo?.coupon_name}):</Typography>
      <Typography variant='mediumBold' sx={(theme) => ({ marginLeft: theme.spacing(1), color: theme.palette.info.main })}>{voucherDiscount}</Typography>
    </Box> : null}
    <Box sx={{
      display: "flex",
      alignItems: "center",
    }} onClick={() => {
    }}>
      <Typography variant='h6' sx={{ marginLeft: (theme) => theme.spacing(1) }}>Service Tax @18%:</Typography>
      <Typography variant='mediumBold' sx={(theme) => ({ marginLeft: theme.spacing(1), color: theme.palette.info.main })}>{orderServiceTax}</Typography>
    </Box>
    <Box sx={{
      display: "flex",
      alignItems: "center",
    }} onClick={() => {
    }}>
      <Typography variant='h6' sx={{ marginLeft: (theme) => theme.spacing(1) }}>Net Payable Amount:</Typography>
      <Typography variant='h6' sx={(theme) => ({ marginLeft: theme.spacing(1), color: theme.palette.info.main })}>{orderNetPayableAmountData}</Typography>
    </Box>
  </Box>
}

function EditInvoicePage({ orderId }) {
  const { loading, singleOrderDetail } = useSelector(orderListingItemsSelector);
  const { user } = useSelector(loginItemsSelector);
  const [orderData, setOrderData] = useState([]);
  const [totalAmount, setTotalAmount] = useState("");
  const [discount, setDiscount] = useState("0");
  const [voucherAmt, setVoucherAmt] = useState("0");
  const [planInfo, setPlanInfo] = useState(null);
  const [serviceTax, setserviceTax] = useState("18");
  const [countryList, setCountryList] = useState([]);
  const [countryShortName, setCountryShortName] = useState('in');
  const [orderType, setOrderType] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { stateData, countryData, appSetting } = useSelector(commonItemsSelector);
  const [stateList, setStateList] = useState([]);
  const [groupData, setGroupData] = useState(null);
  const [selectedCountry, setSelcetedCountry] = useState('');
  const [selectedState, setSelectedState] = useState({});
  const [orderUpdateLoading, setOrderUpdateLoading] = useState(false);
  const [editItemModalOpen, setEditItemModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const { orderServiceTax, orderDiscount, voucherDiscount, orderNetPayableAmountData } = useMemo(() => {
    let totalServiceTax = 0;
    let totalDiscount = 0;
    let voucherDiscount = 0;
    let netPayAmt = 0;

    if (Number.isInteger(Number(totalAmount))) {
      if (discount && (orderType?.toLowerCase() == 'nplan')) {
        totalDiscount = Number(totalAmount) * (Number(discount) / 100);
      }
      if (voucherAmt && (orderType?.toLowerCase() == 'iv')) {
        voucherDiscount = Number(voucherAmt);
      }
      totalServiceTax = Number(totalAmount - totalDiscount - voucherDiscount) * (Number(serviceTax) / 100);
      netPayAmt = totalServiceTax + Number(totalAmount - totalDiscount - voucherDiscount)
    }
    return {
      orderServiceTax: String(totalServiceTax),
      orderDiscount: String(totalDiscount),
      voucherDiscount: String(voucherDiscount),
      orderNetPayableAmountData: String(netPayAmt)
    }
  }, [totalAmount, discount, voucherAmt, serviceTax])
  

  const handleConfirm = async (values, { resetForm }) => {
    try {
      setOrderUpdateLoading(true)
      let payload = {
        order_id: orderId,
        client_name: values.client,
        company_name: values.company_name,
        address: values.address,
        state: values.state,
        special_comment: values.special_comments,
        closed_by: values.closed_by == 'Select' ? "" : values.closed_by,
        comment: values.comments,
        ordered_by: values.ordered_by,
        gst_number: values.gst_number,
        zip_code: values.zip_code,
      }
      if (values.order_status != 0) {
        let resp = await editOrderInvoice(payload);
        if (resp) {
          // navigate('/order?tab=3', {replace: true})
          let payloadForLogs = {
            action: action?.update,
            module: modulesNames?.orders,
            description: logsMessagesList?.order?.confirmOrderInvoiceEdit,
          };
          await adminAddLogs(payloadForLogs);
          navigate(-1)
          dispatch(setOrderDetails({}));
        }
      } else {
        toast.error('Please change order status.')
      }
      setOrderUpdateLoading(false)
    } catch (e) {
      setOrderUpdateLoading(false)
    }

  };

  useEffect(() => {
    if (Object.keys(singleOrderDetail?.items)?.length) {
      setSelcetedCountry(singleOrderDetail?.items?.invoice?.country || "")
      setSelectedState(singleOrderDetail?.items?.invoice?.states[0]);
    }
  }, [singleOrderDetail]);

  let gstValidationSchema = {};

  if (selectedCountry == 'India') {
    gstValidationSchema = {
      gst_number: Yup.string()
        .trim().matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
          'Please enter valid GST Number',
        )
        .test('isValidGST', 'Please enter correct GST Number',
          function (value) {
            let isValid = value?.startsWith(
              selectedState?.state_code?.toString().length > 1
                ? selectedState?.state_code
                : `0${selectedState?.state_code}`
            );
            return value === '' || value === null || value === undefined
              ? true
              : isValid;
          },
        ),
    };
  }

  let validationShape = Yup.object().shape({
    order_id: Yup.string().required('Order id is requried.'),
    paid_on: Yup.string().required('Paid on is requried.'),
    client: Yup.string().required('Client is requried.'),
    address: Yup.string().required('Address is requried.'),
    ...gstValidationSchema
  })

  if (singleOrderDetail?.items?.order_type?.toLowerCase() == 'iv') {
    validationShape = Yup.object().shape({
      order_id: Yup.string().required('Order id is requried.'),
      paid_on: Yup.string().required('Paid on is requried.'),
      address: Yup.string().required('Address is requried.'),
      ...gstValidationSchema
    })
  }

  const validationSchema = validationShape;

  const formik = useFormik({
    initialValues: {
      order_status: "",
      order_id: orderId || "",
      payment_type: "",
      client: "",
      company_name: "",
      company_address: "",
      address: "",
      name: "",
      tel: "",
      mob: "",
      zip_code: "",
      // agency: "",
      email: "",
      // paid_by: "",
      // subscription_converted_by: "",
      state: "",
      // company_group: "",
      // invoice_discount: "",
      // discount_type: "",
      // user_type: "",
      credit_period: "Select",
      approved_mode: "Select",
      special_comments: "",
      ordered_by: "",
      ordered_on: "",
      additional_email: "",
      closed_by: "Select",
      payment_status: "",
      paid_on: "",
      accepted_or_rejected_on: "",
      purchase_order: "",
      comments: "",
      gst_number: "",
      // download_link: ""
    },
    validationSchema: validationSchema,
    onSubmit: handleConfirm,
  });

  const getOrderDetails = async () => {
    dispatch(orderDetailsApi(orderId));
  };

  const calculateTotalAmount = (orderDetail) => {
    if (orderDetail?.length) {
      let totalAmountCalc = 0
      orderDetail?.map((item) => {
        totalAmountCalc = Number(totalAmountCalc) + Number(item.price)
      })
      setTotalAmount(String(totalAmountCalc))
    } else {
      setTotalAmount('N/A')
    }
  }

  const handleData = (data, index) => {
    if (data) {
      setGroupData(data)
      formik.setFieldValue('company_name', data?.value)
      formik.setFieldValue('company_address', data?.company_address)
    } else {
      formik.setFieldValue('company_name', "")
      formik.setFieldValue('company_address', "")
      setGroupData(null)
    }

  }

  const getAsyncListing = async (inputValue) => {
    if (inputValue && inputValue.length > 2) {
      let data = await companyByNameNoRedux({ name: inputValue })
      if (data) {
        let updatedData = data?.map((item, index) => {
          return {
            value: item.company_name,
            label: item.company_name,
            ...item
          }
        })
        return updatedData || []
      }
      return []
    }
  }

  const appSettingDurationMap = () => {
    if (appSetting && appSetting?.items) {
      const durationPriceDataFromAppSetting = getDataFromAppSetting(
        appSetting?.items?.features?.stars,
        "key_name",
        "duration"
      );
      const durationData = JSON.parse(
        durationPriceDataFromAppSetting[0]?.key_value || "{}"
      );
      const outputArray = [];
      for (const label in durationData) {
        const value = durationData[label];
        outputArray.push({ label, value });
      }
      return outputArray
    }
  }


  useEffect(() => {
    getOrderDetails();
    getCountryDetails();

  }, []);

  useEffect(() => {
    try {
      let items = Object.keys(singleOrderDetail.items).length;
      if (items) {
        if (singleOrderDetail?.items?.order_status == 1) {
          // setOrderData(singleOrderDetail?.items?.order_details?.map(v => ({...v, order_type: singleOrderDetail?.items?.order_type || ""})) || []);
          let arrayOrderData = [];
          arrayOrderData = singleOrderDetail?.items?.order_details?.map((v) => {
            let obj = ({
              ...v,
              order_type: singleOrderDetail?.items?.order_type || '',
            })
            let newArr = appSettingDurationMap();
            if (newArr?.length) {
              let duration_for_star = newArr?.find((dur) => {
                return dur?.value == v?.duration_for_star || dur?.label == v?.duration_for_star
              })
              if (duration_for_star) {
                obj['duration_for_star'] = duration_for_star?.label;
              }
            }
            if (v?.state) {
              let stateList = v?.state?.split(",");
              if (stateList?.length > 5)
                obj['state'] = 'Pan India'
            }
            return obj;
          }
          )
          setOrderData(arrayOrderData);
          setDiscount(singleOrderDetail?.items?.user?.discount)
          if (singleOrderDetail?.items?.previous_order_id) {
            if (singleOrderDetail?.items?.plan_info) {
              setVoucherAmt(String(singleOrderDetail?.items?.plan_info?.coupon_amount))
              setPlanInfo(singleOrderDetail?.items?.plan_info)
            }
          }
          setOrderType(singleOrderDetail?.items?.order_type)
          formik.setValues({
            order_status: singleOrderDetail?.items?.order_status,
            order_id: orderId || "",
            payment_type: singleOrderDetail?.items?.payment_type || "",
            client: singleOrderDetail?.items?.invoice?.client || "",
            company_name: singleOrderDetail?.items?.invoice?.company_name || "",
            company_address: singleOrderDetail?.items?.company_address || "",
            address: singleOrderDetail?.items?.invoice?.address || "",
            name: singleOrderDetail?.items?.user?.first_name + " " + singleOrderDetail?.items?.user?.last_name || "",
            tel: singleOrderDetail?.items?.user?.phone || "",
            mob: singleOrderDetail?.items?.user?.mobile || "",
            email: singleOrderDetail?.items?.user?.email || "",
            state: singleOrderDetail?.items?.invoice?.states?.length ? singleOrderDetail?.items?.invoice?.states[0]?.state_name : "",
            credit_period: singleOrderDetail?.items?.grouping_manage?.period?.toLowerCase()?.includes('select') ? "Select" : singleOrderDetail?.items?.grouping_manage?.period || 'Select',
            approved_mode: singleOrderDetail?.items?.grouping_manage?.approval_mode?.toLowerCase()?.includes('select') ? "Select" : singleOrderDetail?.items?.grouping_manage?.approval_mode || 'Select',
            special_comments: singleOrderDetail?.items?.grouping_manage?.special_comment || "",
            ordered_by: singleOrderDetail?.items?.invoice?.ordered_by || "",
            country: singleOrderDetail?.items?.invoice?.country || "",
            ordered_on: singleOrderDetail?.items?.ordered_on || '',
            additional_email: singleOrderDetail?.items?.grouping_manage?.additional_email || "",
            closed_by: singleOrderDetail?.items?.grouping_manage?.closed_by?.toLowerCase()?.includes('select') ? 'Select' : singleOrderDetail?.items?.grouping_manage?.closed_by || "Select",
            gst_number: singleOrderDetail?.items?.invoice?.gst_number || "",
            zip_code: singleOrderDetail?.items?.invoice?.zip_code || "",
            payment_status: singleOrderDetail?.items?.payment_status,
            paid_on: formatDate(singleOrderDetail?.items?.payment_on, "yyyy-mm-ddThh:mm") || "",
            accepted_or_rejected_on: formatDate(singleOrderDetail?.items?.accepted_or_rejected_on, "yyyy-mm-ddThh:mm") || "",
            purchase_order: singleOrderDetail?.items?.purchase_order || "",
            comments: singleOrderDetail?.items?.comment || "",
          })
          calculateTotalAmount(singleOrderDetail.items?.order_details || [])
        } else {
          toast.error('This order either rejected or pending please check and try again with confirmed order.')
          navigate('/order', { replace: true })
        }
      }
      getStateData(singleOrderDetail?.items?.user || {})
    } catch (e) { }
  }, [singleOrderDetail]);

  const getStateData = async (user) => {
    if (user?.countries?.length) {
      dispatch(getState(user?.countries[0]?.country_id));
    }
  };

  const handleChangeCountry = async (e) => {
    formik.setFieldValue('country', e.target.value);
    formik.setFieldValue('state', '');
    setSelcetedCountry(e.target.value || '');
    setSelectedState({});
    let country_id = 0;
    countryData?.items?.filter((val) => {
      if (val.country_name === e.target.value) {
        country_id = val.country_id;
        setCountryShortName(val?.country_short_name?.toLowerCase());
        formik.setFieldValue('mobile', '');
      }
    });
    dispatch(getState(country_id));
  };

  useEffect(() => {
    if (stateData && Array.isArray(stateData?.items)) {
      let data = stateData?.items?.map((item) => {
        return {
          label: item?.state_name,
          value: item?.state_id,
        };
      });
      setStateList(data);
    }
  }, [stateData]);

  const handleStateChange = (val) => {
    let stateSelected = stateData?.items?.filter(
      (item) => item.state_name == val
    );
    if (stateSelected.length) {
      setSelectedState(stateSelected[0]);
    }
  };

  const getCountryDetails = async () => {
    dispatch(getCountry());
  };

  useEffect(() => {
    if (countryData && Array.isArray(countryData?.items)) {
      let data = countryData?.items?.map((item) => {
        return {
          label: item?.country_name,
          value: item?.country_id,
        };
      });
      setCountryList(data);
    }
  }, [countryData]);


  useEffect(() => {
    formik.setFieldTouched("gst_number", true, true);
  }, [selectedState])

  const handleOpen = (value) => {
    setEditItemModalOpen(value)
  }

  if (!Object.keys(singleOrderDetail?.items).length) {
    return <CustomBox padding={3}>
      <CustomLoading loading={singleOrderDetail?.loading} />
    </CustomBox>
  }


  const actionBody = (data) => {
    return (
      <>
        {data?.order_type != "IV" ? <div style={{ display: "flex", justifyContent: "end" }}>
          <CustomButton toolTipTittle={'Edit Item'} onClick={() => {
            setSelectedItem(data)
            handleOpen(true)
          }} className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Edit /></CustomButton>
        </div> : <></>}
      </>
    );
  };

  return (
    <div>
      <CustomBox padding={3}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Box><strong>Order ID:</strong> {formik.values.order_id}</Box>
            <Box><strong>Mode of Payment:</strong> {formik.values.payment_type}</Box>
            <Box><strong>Name:</strong> {formik.values.name}</Box>
            <Box><strong>Client:</strong> {formik.values.client}</Box>
            <Box><strong>Company Name:</strong> {formik.values.company_name}</Box>
          </Grid>
          <Grid item md={6}>
            <Box><strong>Order Status:</strong> {formik.values.order_status == 1 ? 'Confirmed' : formik.values.order_status == 2 ? 'Pending' : 'Rejected'}</Box>
            <Box><strong>Ordered On:</strong> {formatDate(formik.values.ordered_on, "dd-mm-yyyy hh:mm")}</Box>
            <Box><strong>Email:</strong> {formik.values.email}</Box>
            <Box><strong>Telephone No.:</strong> {formik.values.tel}</Box>
            <Box><strong>Mobile:</strong> {formik.values.mob}</Box>
          </Grid>
        </Grid>
      </CustomBox>
      <Divder spacing={1} />
      <form onSubmit={formik.handleSubmit}>
        <CustomBox padding={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                name="client"
                label="Client"
                placeholder="Client"
                value={formik.values.client}
                onChange={formik.handleChange}
                error={formik?.touched?.client && Boolean(formik.errors.client)}
                helperText={formik?.touched?.client && formik.errors.client}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                name="company_name"
                label="Company Name"
                placeholder="Company Name"
                value={formik.values.company_name}
                onChange={formik.handleChange}

              />
            </Grid>

            <Grid item xs={12} md={6}>
              <OrderDetailsInput
                name="address"
                label="Address"
                placeholder="Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik?.touched?.address && Boolean(formik.errors.address)}
                helperText={formik?.touched?.address && formik.errors.address}
                multiline
              />
            </Grid>

          </Grid>
        </CustomBox>
        {orderData.length > 0 && <>
          <Divder spacing={1} />
          <CustomBox padding={0}>
            <Typography variant='h3' sx={{ marginLeft: (theme) => theme.spacing(1), padding: 2 }}>Invoice Items</Typography>
            <MatTable
              loading={singleOrderDetail?.loading}
              showCheckbox={false}
              action={actionBody}
              columns={singleOrderDetail.items?.order_type == 'IV' ? columnIvs : column || []}
              data={orderData || []}
              pagination={false}
              toolbarEnable={false}
            />
          </CustomBox>
        </>}
        <Divder spacing={1} />
        <CustomBox padding={3}>
          {singleOrderDetail?.items?.order_status == 0 ?
            <TotalStripe totalAmount={totalAmount} discount={discount} orderDiscount={orderDiscount} planInfo={planInfo} voucherDiscount={voucherDiscount} orderType={orderType} orderServiceTax={orderServiceTax}
              orderNetPayableAmountData={orderNetPayableAmountData} /> :
            <TotalStripe totalAmount={singleOrderDetail?.items?.invoice?.initial_price} discount={singleOrderDetail?.items?.invoice?.discount}
              orderDiscount={singleOrderDetail?.items?.invoice?.discount} planInfo={planInfo} voucherDiscount={voucherDiscount} orderType={orderType} orderServiceTax={singleOrderDetail?.items?.invoice?.tax}
              orderNetPayableAmountData={singleOrderDetail?.items?.invoice?.final_price} />}
        </CustomBox>
        <Divder spacing={1} />
        <CustomBox padding={3}>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6} md={4} lg={3}>

              <UserDetailInputField

                name="country"

                label="Country"

                id="country"

                placeholder="Country"

                SelectProps={{

                  native: true,

                }}

                select

                value={formik.values.country}

                InputLabelProps={{ shrink: true }}

                InputProps={{

                  classes: {

                    notchedOutline: 'rounded',

                  },

                }}

                onChange={(e) => handleChangeCountry(e)}

                error={formik.touched.country && Boolean(formik.errors.country)}

                helperText={formik.touched.country && formik.errors.country}

              >

                <option key={'Select Country'} value={''}>

                  Select Country

                </option>

                {countryList &&

                  countryList?.map((country) => (

                    <option key={country.value} value={country.label}>

                      {country.label}

                    </option>

                  ))}

              </UserDetailInputField>

            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                name="state"
                select
                SelectProps={{
                  native: true,
                }}
                label="Select State"
                placeholder="Select State"
                value={formik.values.state}

                onChange={(e) => {
                  formik.handleChange(e);
                  handleStateChange(e.target.value);
                }}
              >
                <option key={"Select State"} value={""}>
                  Select State
                </option>
                {stateList &&
                  stateList?.map((state) => {
                    return (
                      <option key={state.value} value={state.label}>
                        {state.label}
                      </option>
                    );
                  })}
              </OrderDetailsInput>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                select
                name="credit_period"
                label="Credit Period"
                placeholder="Credit Period"
                value={formik.values.credit_period}
                disabled
                onChange={(e) => {
                  let extended_date = formatDate(new Date().setDate(new Date().getDate() + Number(e.target.value)), "yyyy-mm-ddThh:mm")
                  formik.setValues((values) => ({
                    ...values,
                    'credit_period': e.target.value,
                    'paid_on': extended_date,
                    'accepted_or_rejected_on': extended_date
                  }))
                }}
              >
                {orderDetalData?.createPeriod?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label} {!option.label.toLowerCase()?.includes('select') ? option.label > 0 ? "days" : "day" : null}
                  </MenuItem>
                ))}
              </OrderDetailsInput>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                select
                name="approved_mode"
                label="Approved Mode"
                placeholder="Approved Mode"
                value={formik.values.approved_mode}
                onChange={formik.handleChange}
                disabled
              >
                {orderDetalData?.approvalMode?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </OrderDetailsInput>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                name="purchase_order"
                label="Purchase Order"
                placeholder="Purchase Order"
                value={formik.values.purchase_order}
                onChange={formik.handleChange}
                disabled
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                label="Order By"
                placeholder="Order By"
                name="ordered_by"
                value={formik.values.ordered_by}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                label="GST Number"
                placeholder="GST Number"
                value={formik.values.gst_number}
                onChange={(e) => {
                  formik.setFieldTouched('gst_number', true, true);
                  formik.setFieldValue(
                    'gst_number',
                    e.target.value.toUpperCase(),
                  );
                }}
                inputProps={{
                  maxLength: 15,
                }}
                error={
                  formik.touched.gst_number &&
                  Boolean(formik.errors.gst_number)
                }
                helperText={
                  formik.touched.gst_number && formik.errors.gst_number
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                label="Zip code"
                placeholder="Zip code"
                name="zip_code"
                value={formik.values.zip_code}
                onChange={(e) => {
                  // if(!isNaN(e.target.value)){
                  formik.setFieldValue('zip_code', e.target.value);
                  // }
                }}
                error={
                  formik.touched.zip_code &&
                  Boolean(formik.errors.zip_code)
                }
                helperText={
                  formik.touched.zip_code && formik.errors.zip_code
                }
                inputProps={{ maxLength: 15 }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                name="additional_email"
                label="Additional Email"
                placeholder="Additional Email"
                value={formik.values.additional_email}
                onChange={formik.handleChange}
                disabled
              />
            </Grid>
            {/* <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                type="datetime-local"
                label="Date & Time"
                placeholder="Date & Time"
                value={"2017-06-13T13:00"}
                
              />
            </Grid> */}
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                select
                name="closed_by"
                label="Closed by"
                placeholder="Closed by"
                value={formik.values.closed_by}
                // disabled
                onChange={formik.handleChange}
              >
                {orderDetalData?.closedBy?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </OrderDetailsInput>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                select
                name={'payment_status'}
                label="Payment Status"
                placeholder="Payment Status"
                value={formik.values.payment_status}
                onChange={formik.handleChange}
                disabled
              >
                {orderDetalData?.paymentStatus?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </OrderDetailsInput>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                name="paid_on"
                type="datetime-local"
                label="Payment Due Date"
                placeholder="Payment Due Date"
                disabled
                value={formatDate(formik.values.paid_on, "yyyy-mm-ddThh:mm")} //2017-06-13T13:00
                onChange={(e) => {
                  // if(formik.values.accepted_or_rejected_on){
                  //   if(validDate(formik.values.accepted_or_rejected_on, e.target.value)
                  //     && validDate(e.target.value, formik.values.ordered_on)){
                  //     formik.handleChange(e)
                  //   }
                  // }else{
                  if (validDate(e.target.value, formik.values.ordered_on)) {
                    formik.setValues((values) => ({
                      ...values,
                      'paid_on': e.target.value,
                      'accepted_or_rejected_on': e.target.value
                    }))
                  } else {
                    toast.error('Payment date should be greater than ordered date')
                  }
                  // }
                }}
                error={formik.touched.paid_on && Boolean(formik.errors.paid_on)}
                helperText={formik.touched.paid_on && formik.errors.paid_on}
              />
            </Grid>
            {/* <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                name="accepted_or_rejected_on"
                type="datetime-local"
                label="Confirmed/Rejected Date"
                placeholder="Confirmed/Rejected Date"
                value={formatDate(formik.values.accepted_or_rejected_on, "yyyy-mm-ddThh:mm")} //2017-06-13T13:00
                onChange={(e)=>{
                  if(validDate(e.target.value)){
                    formik.setFieldValue('accepted_or_rejected_on', e.target.value)
                  }else{
                    toast.error('Date should be greater then current date')
                  }
                }}
                
              />
            </Grid> */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={5}>
                  <OrderDetailsInput
                    name="comments"
                    label="Comments"
                    placeholder="Comments"
                    value={formik.values.comments}
                    onChange={formik.handleChange}
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                  <OrderDetailsInput
                    name="special_comments"
                    label="Special Comments"
                    placeholder="Special Comments"
                    value={formik.values.special_comments}
                    onChange={formik.handleChange}
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                label="Download Link"
                placeholder="Download Link"
                value={formik.values.download_link}
                
              />
            </Grid> */}
            {singleOrderDetail?.items?.order_status == 1 ? <Grid item xs={12} sx={{ justifyContent: "flex-end", display: "flex" }}>
              <CustomButton type="submit" loading={orderUpdateLoading || singleOrderDetail?.loading} sx={{ padding: '12px 15px' }} color={'buttonPrimary'}>Update</CustomButton>
            </Grid> : null}
          </Grid>
        </CustomBox>
      </form>
      {editItemModalOpen && <EditInvoiceItems open={editItemModalOpen} handleOpen={handleOpen} data={selectedItem} getListing={getOrderDetails} />}
    </div>
  );
}

export default EditInvoicePage;
