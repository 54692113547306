import { Box, Checkbox, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  breakStringIntoLines,
  crypt,
  downloadBlobXLSFile,
  fetchFile,
  formatDate,
  getNewParameters,
  getObjectSubset,
  getParameters,
  paymentModeModification,
} from '../../lib/helper';
import { orderListingItemsSelector } from '../../redux/orderListing';
import { customHistory } from '../../Route/CustomBrowserRouter';
import {
  cancleIrn,
  generateIrn,
  markConfirmInvoiceUnpaid,
} from '../../Services/irnApi';
import {
  ConfirmedOrderListings,
  ConfirmedOrderListingsExportXLS,
  markAsPaidOrder,
  rejectInvoice,
} from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import OrderPaidProcessModal from '../Modals/OrderPaidProcessModal';
import MatTable from '../Tables/MatTable';
import { Edit, Upload } from '@mui/icons-material';
import ConfirmationModal from '../Modals/ConfirmationModal';
import CloseIcon from '@mui/icons-material/Close';
import { Mail, PictureAsPdf } from '@material-ui/icons';
import ApiConfig from '../../config/ApiConfig';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import UploalPDFFileModal from '../Modals/UploalPDFFileModal';
import AllFilters from './AllFilters';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SearchBy, SearchByConfirmed } from '../../data/userData';
import {
  action,
  logsMessagesList,
  modulesNames,
} from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

const column = [
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (
      <Link
        className="textDecoration-none link-text"
        to={`orderDetails/${columnData}`}
      >
        <Typography variant="span" style={{ whiteSpace: 'nowrap' }}>
          {columnData}
        </Typography>
      </Link>
    ),
    nextLine: true,
  },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Order Date',
    id: 'ordered_on',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'dd/mm/yyyy')}
      </span>
    ),
  },
  {
    label: 'Payment Mode',
    id: 'payment_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData ? paymentModeModification(columnData) : 'N/A'}
      </span>
    ),
    nextLine: true,
  },
  {
    label: 'Invoice ID',
    id: 'invoice_id',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => {
      if (row?.order_status > 0) {
        return columnData ? (
          <Link
            className="textDecoration-none link-text"
            to={`../../invoice?orderId=${crypt('order_id', row?.order_id)}`}
            target={'_blank'}
          >
            {columnData}
          </Link>
        ) : (
          <Typography variant="span" style={{ whiteSpace: 'nowrap' }}>
            {columnData}
          </Typography>
        );
      }
      return null;
    },
    nextLine: true,
  },
  {
    label: 'Payment Status',
    id: 'payment_status',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <>
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          {columnData == '0' ? 'Unpaid' : 'Paid'}
        </span>
        {row?.paid_on ? (
          <Typography variant="p" display={'block'} className="colorHiglight2">
            {formatDate(row.paid_on, 'dd/mm/yyyy')}
          </Typography>
        ) : null}
        {row?.previous_order_id ? (
          <Typography variant="p" display={'block'} className="colorHiglight">
            RefID:{row?.previous_order_id}
          </Typography>
        ) : null}
      </>
    ),
    nextLine: true,
  },
  {
    label: 'Order Status',
    id: 'order_status',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <>
        <Typography
          variant="span"
          sx={{ color: 'success.main', display: 'inline-block' }}
        >
          C
        </Typography>
        <Typography variant="span" component={'div'}>
          {row?.order_type}
        </Typography>
      </>
    ),
    nextLine: true,
  },
  {
    label: 'Clearence Date',
    id: 'payment_on',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'dd/mm/yyyy')}
      </span>
    ),
    nextLine: true,
  },

  {
    label: 'Comments',
    id: 'comment',
    format: (columnData, rowIndex, formatCallback, func, errors, row) => (
      <Box
        style={{ maxWidth: 300, width: 'max-content', wordBreak: 'break-word' }}
      >
        <Box>
          <CustomButton
            toolTipTittle={'Upload PO'}
            className="buttonDense noMinWidth"
            onClick={() => {
              func(row);
            }}
            sx={{
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
              padding: '2px 3px !important',
            }}
          >
            <Upload style={{ fontSize: 22 }} />
          </CustomButton>

          {row?.purchase_order_detail && (
            <CustomButton
              toolTipTittle={'Download PO'}
              className="buttonDense noMinWidth"
              onClick={() => {
                toast.promise(
                  fetchFile(
                    `${ApiConfig.downloadPo}?filename=${row?.purchase_order_detail}`,
                  ),
                  {
                    pending: 'Downloading your file....',
                    success: 'Downloaded Successfully.',
                    error: 'Failed to download file.',
                  },
                );
              }}
              sx={{
                bgcolor: 'buttonSecondary.main',
                color: 'buttonSecondary.contrastText',
                padding: '2px 3px !important',
              }}
            >
              <PictureAsPdf style={{ fontSize: 22 }} />
            </CustomButton>
          )}
        </Box>
        {!!columnData && (
          <Tooltip title={columnData}>
            <span style={{ display: 'inline-block', marginTop: 5 }}>
              {/* {createSubString(columnData, () => { }, 62)} */}
              {breakStringIntoLines(columnData, 20)}
            </span>
          </Tooltip>
        )}
        {!!row?.special_comment && (
          <Tooltip title={row?.special_comment}>
            <Box
              className="colorHiglight fontWeight500"
              style={{ display: 'block', marginTop: 5 }}
            >
              Spl comment: {breakStringIntoLines(row?.special_comment, 20)}
              {/* {createSubString(row?.special_comment, () => { }, 62)} */}
            </Box>
          </Tooltip>
        )}
      </Box>
    ),
  },

  {
    label: 'Paid Process',
    id: 'paid_process',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <>
        {row?.payment_status == '0' ? (
          <Checkbox
            color="primary"
            checked={arr.fields.includes(row?.order_id)}
            inputProps={{
              'aria-labelledby': row?.order_id,
            }}
            onClick={(event) => {
              arr?.func(row, event);
            }}
            sx={{ padding: 0 }}
          />
        ) : null}
      </>
    ),
    nextLine: true,
  },
];

function ConfirmedOrder() {
  const [orderListing, setOrderListing] = useState([]);
  const [paidProcessArr, setPaidProcessArr] = useState([]);
  const [paidProcessUser, setPaidProcessUser] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [allParams, setAllParams] = useState(getParameters());
  const [open, setOpen] = useState(false);
  const [confirmRejectInvoiceModal, setConfirmRejectInvoiceModal] =
    useState(false);
  const [generateIRNModal, setGenerateIRNModal] = useState(false);
  const [unpaidInvoiceModal, setUpaidInvoiceModal] = useState(false);
  const [cancelIRNModal, setCancelIRNModal] = useState(false);
  const [rejectInvoiceData, setRejectInvoiceData] = useState({});
  const [generateIRNData, setGenerateIRNData] = useState({});
  const [unpaidOrderDetails, setUnpaidOrderDetails] = useState({});
  const [cancleIRNData, setCancelIRNData] = useState({});
  const [rejectComment, setRejectComment] = useState('');
  const { loading, confirmedOrders } = useSelector(orderListingItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const [selectedRow, setSelectedRow] = useState(null);
  const [uploadFileModal, setUploadFileModal] = useState(false);
  const [expLoading, setExpLoading] = useState(false);
  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'Search By',
      id: 'search_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: SearchByConfirmed,
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          // newState[index].value = e.target.value;
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);
  const getOrderListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      order_status: String(1),
      ...allParams,
      ...params,
    };
    if (payload?.start_date) {
      payload['start_date'] =
        formatDate(payload?.start_date, 'yyyy-mm-dd') + '  00:00:00';
    }
    if (payload?.end_date) {
      payload['end_date'] =
        formatDate(payload?.end_date, 'yyyy-mm-dd') + '  23:59:59';
    }
    dispatch(ConfirmedOrderListings(payload));
    setPage(Number(payload.page));
  };
  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getOrderListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };
  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;

      newState[0].value = '';
      newState[1].value = '';
      return newState;
    });
  };

  useEffect(() => {
    if (confirmedOrders?.items?.orders?.length >= 0) {
      let data = confirmedOrders?.items?.orders?.map((item) => {
        return {
          order_id: item.order_id,
          user_id: item.user_id,
          email: item.user.email,
          payment_type: item.payment_type,
          ordered_on: item.ordered_on,
          accepted_or_rejected_on: item.accepted_or_rejected_on,
          previous_order_id: item.previous_order_id,
          payment_on: item.payment_on,
          order_type: item.order_type,
          order_status: item.order_status,
          paid_on: item.paid_on,
          payment_status: item.payment_status,
          invoice_id: item?.invoice?.invoice_id || null,
          gst_number: item?.invoice?.gst_number || null,
          comment: item?.comment,
          special_comment: item?.grouping_manage?.special_comment || null,
          irn_status: item?.irn_status,
          purchase_order_detail: item?.purchase_order_detail,
          purchase_order: item?.purchase_order,
        };
      });
      setOrderListing(data);
    }
  }, [confirmedOrders.items]);

  const renderFields = () => {
    return (
      <Box sx={{ mt: 2 }}>
        <CustomInput
          variant="outlined"
          fullWidth
          name="comment"
          label="Reject Comment"
          placeholder="Comment"
          value={rejectComment}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            classes: {
              notchedOutline: 'rounded',
            },
          }}
          onChange={(e) => {
            setRejectComment(e.target.value);
          }}
        />
      </Box>
    );
  };

  useEffect(() => {
    getOrderListing();
  }, []);

  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }
    getOrderListing();
  }, [allParams]);

  const handleOpen = (value) => {
    setOpen(value);
  };
  const handleCofirmModalOpen = (value) => {
    setConfirmRejectInvoiceModal(value);
  };
  const handleGenerateIRNModalOpen = (value) => {
    setGenerateIRNModal(value);
  };
  const handleunpaidInvoiceModalOpen = (value) => {
    setUpaidInvoiceModal(value);
  };

  const handleunpaidInvoice = async (value) => {
    if (value && unpaidOrderDetails) {
      let payload = {
        order_id: unpaidOrderDetails?.order_id,
        payment_status: '0',
      };
      let resp = await markConfirmInvoiceUnpaid(payload);
      if (resp) {
        let payloadForLogs = {
          action: action?.markAsUnpaid,
          module: modulesNames?.orders,
          description: logsMessagesList?.order?.confirmOrderMarkAsUnPaid,
        };
        await adminAddLogs(payloadForLogs);
        getOrderListing();
      }
    } else {
      setUnpaidOrderDetails(null);
    }
  };

  const handleCancelIRNModalOpen = (value) => {
    setCancelIRNModal(value);
  };

  const handleFileModalOpen = (value) => {
    setUploadFileModal(value);
    if (!value) {
      setSelectedRow(null);
    }
  };

  const handleUploadPO = (data) => {
    setSelectedRow(data);
    handleFileModalOpen(true);
  };

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          {data?.payment_status == 1 && (
            <CustomButton
              toolTipTittle={'Mark Unpaid'}
              onClick={() => {
                setUnpaidOrderDetails(data);
                handleunpaidInvoiceModalOpen(true);
              }}
              className="buttonDense noMinWidth"
              sx={{
                bgcolor: 'buttonSecondary.main',
                color: 'buttonSecondary.contrastText',
              }}
            >
              <CheckCircleIcon />{' '}
            </CustomButton>
          )}

          {data.irn_status != '3' ? (
            data.irn_status != '1' &&
            data.order_status == '1' &&
            data?.gst_number ? (
              <CustomButton
                className="buttonDense"
                sx={{
                  fontSize: '12px',
                  whiteSpace: 'nowrap',
                  bgcolor: 'buttonSuccess.main',
                  color: 'buttonSuccess.contrastText',
                }}
                onClick={() => {
                  handleGenerateIRNModalOpen(true);
                  setGenerateIRNData(data?.order_id);
                }}
              >
                Generate IRN
              </CustomButton>
            ) : null
          ) : null}

          <CustomButton
            toolTipTittle={'Edit Invoice'}
            onClick={() => {
              navigate(
                `editInvoice/${crypt(
                  ApiConfig.REACT_APP_SALT + 'order_id',
                  data?.order_id,
                )}`,
              );
            }}
            className="buttonDense noMinWidth"
            sx={{
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            <Edit />
          </CustomButton>
          <CustomButton
            toolTipTittle={'Send Mail'}
            onClick={() => {
              navigate(
                `sendEMailOrder/${crypt(
                  ApiConfig.REACT_APP_SALT + 'order_id',
                  data?.order_id,
                )}`,
              );
            }}
            className="buttonDense noMinWidth"
            sx={{
              bgcolor: 'buttonPrimary.main',
              color: 'buttonPrimary.contrastText',
            }}
          >
            <Mail />
          </CustomButton>
          <a
            className="textDecoration-none"
            href={`${ApiConfig.LIVE_WEBSITE_URL}/deliverImages?order_id=${crypt(
              ApiConfig.REACT_APP_SALT + 'order_id',
              data?.order_id,
            )}`}
            target={'_blank'}
            style={{ color: theme.palette.primary.contrastText }}
          >
            <CustomButton
              toolTipTittle={'Deliver Order'}
              className="buttonDense noMinWidth"
              sx={{
                bgcolor: 'buttonSecondary.main',
                color: 'buttonSecondary.contrastText',
                whiteSpace: 'nowrap',
              }}
            >
              <PermMediaIcon />
            </CustomButton>
          </a>
          <CustomButton
            toolTipTittle={'Reject Invoice'}
            onClick={() => {
              handleCofirmModalOpen(true);
              setRejectInvoiceData(data);
            }}
            className="buttonDense noMinWidth"
            sx={{
              bgcolor: 'buttonDanger.main',
              color: 'buttonDanger.contrastText',
            }}
          >
            <CloseIcon />
          </CustomButton>
        </div>
      </>
    );
  };

  const handlePaidProcess = async (data, e) => {
    let updateArr = [...paidProcessArr];
    let index = updateArr.findIndex((item) => item == data?.order_id);

    if (e?.target?.checked) {
      if (updateArr.length == 0 || data.email === paidProcessUser) {
        if (index == -1) {
          updateArr.push(data?.order_id);
          setPaidProcessArr(updateArr);
        }
      } else {
        e.target.checked = false;
        toast.warning(
          `Please select orders of same user currently you have selected for ${paidProcessUser}`,
        );
      }
      if (updateArr.length == 1 && paidProcessUser == '') {
        setPaidProcessUser(data.email);
      }
    } else {
      if (updateArr.length > 0 || data.email === paidProcessUser) {
        if (index > -1) {
          updateArr.splice(index, 1);
          setPaidProcessArr(updateArr);
        }
      } else {
        e.target.checked = false;
      }
      if (updateArr.length == 0) {
        setPaidProcessUser('');
      }
    }
  };

  const handleConfirmRejectInvoice = async (value) => {
    if (value && rejectInvoiceData) {
      let resp = await rejectInvoice({
        invoice_id: rejectInvoiceData.invoice_id,
        comment: rejectComment,
      });
      if (resp) {
        let payloadForLogs = {
          action: action?.invoiceReject,
          module: modulesNames?.orders,
          description: logsMessagesList?.order?.confirmOrderInvoiceRejected,
        };
        await adminAddLogs(payloadForLogs);
        getOrderListing();
      }
      setRejectComment('');
    } else {
      setRejectInvoiceData(null);
      setRejectComment('');
    }
  };

  const handleGenerateIRN = async (value) => {
    if (value && generateIRNData) {
      let resp = await generateIrn({ order_id: generateIRNData });
      if (resp) {
        let payloadForLogs = {
          action: action?.irnGenerate,
          module: modulesNames?.orders,
          description: logsMessagesList?.order?.confirmOrderIRNGenerate,
        };
        await adminAddLogs(payloadForLogs);
        getOrderListing();
      }
    } else {
      setGenerateIRNData(null);
    }
  };

  const handleCancelIrn = async (value) => {
    if (value && cancleIRNData) {
      let resp = await cancleIrn({ order_id: cancleIRNData });
      if (resp) {
        getOrderListing();
      }
    } else {
      setCancelIRNData(null);
    }
  };

  const getExportFile = async (params = {}) => {
    try {
      let payload = {
        order_status: String(1),
        ...allParams,
        ...params,
      };
      delete payload.page;
      delete payload.limit;

      setExpLoading(true);
      let resp = await ConfirmedOrderListingsExportXLS(payload);
      if (resp) {
        downloadBlobXLSFile(resp?.data, `ConfirmedOrdersList`);
      } else {
        toast.error('Something went wrong.');
      }
      setExpLoading(false);
    } catch (e) {
      console.log(e);
      setExpLoading(false);
    }
  };
  return (
    <>
      <MatTable
        loading={confirmedOrders?.loading}
        showCheckbox={false}
        columns={column}
        data={orderListing}
        onApplyFilter={(data = {}) => {
          getOrderListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        showPageLimit={true}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        perPage={limit}
        total={confirmedOrders?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getOrderListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getOrderListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        columnWiseData={[
          {
            id: 'paid_process',
            value: {
              fields: paidProcessArr,
              func: handlePaidProcess,
            },
          },
          {
            id: 'comment',
            value: handleUploadPO,
          },
        ]}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        // filterEnable
        rangeDateFilter
        // dropdownFilter={true}
        disableSearch
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
            extraButton={() => {
              return (
                <>
                  <CustomButton
                    sx={{
                      fontSize: '12px',
                      padding: '12px 11px',
                      marginTop: 1,
                      whiteSpace: 'nowrap',
                      bgcolor: 'buttonSecondary.main',
                      color: 'buttonSecondary.contrastText',
                      [theme.breakpoints.down('md')]: {
                        flex: 1,
                      },
                    }}
                    onClick={() => {
                      if (paidProcessArr.length && paidProcessUser) {
                        handleOpen(true);
                      } else {
                        toast.error('Atleast one order selection is required.');
                      }
                    }}
                  >
                    Mark as paid
                  </CustomButton>
                </>
              );
            }}
          />
        )}
      />
      {unpaidInvoiceModal && (
        <ConfirmationModal
          open={unpaidInvoiceModal}
          handleConfirm={handleunpaidInvoice}
          setOpen={handleunpaidInvoiceModalOpen}
          confirmationDesc={'Are you sure want to Mark it unpaid?'}
        />
      )}
      {open && (
        <OrderPaidProcessModal
          open={open}
          handleOpen={handleOpen}
          setPage={setPage}
          paidProcessArr={paidProcessArr}
          setPaidProcessUser={setPaidProcessUser}
          paidProcessUser={paidProcessUser}
          setPaidProcessArr={setPaidProcessArr}
        />
      )}
      {confirmRejectInvoiceModal && (
        <ConfirmationModal
          open={confirmRejectInvoiceModal}
          handleConfirm={handleConfirmRejectInvoice}
          setOpen={handleCofirmModalOpen}
          render={renderFields}
        />
      )}
      {generateIRNModal && (
        <ConfirmationModal
          open={generateIRNModal}
          handleConfirm={handleGenerateIRN}
          setOpen={handleGenerateIRNModalOpen}
          confirmationDesc={'Are you sure want to Generate IRN?'}
        />
      )}
      {cancelIRNModal && (
        <ConfirmationModal
          open={cancelIRNModal}
          handleConfirm={handleCancelIrn}
          setOpen={handleCancelIRNModalOpen}
          confirmationDesc={'Are you sure want to Cancel IRN?'}
        />
      )}
      {uploadFileModal && (
        <UploalPDFFileModal
          open={uploadFileModal}
          handleOpen={handleFileModalOpen}
          data={selectedRow}
          getListing={getOrderListing}
        />
      )}
    </>
  );
}

export default ConfirmedOrder;
