import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid} from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { formatDate} from '../../lib/helper';
import { adminAddLogs } from '../../Services/commonService';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import { suspendVideo } from '../../Services/videosApis';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';

export default function SuspendVideoModal({ open, handleOpen, data, getListing }) {
  const [loading, setLoading] = React.useState(false);
  
  const handleUpload = async(values, { resetForm })=>{
    try{
      setLoading(true)
        let payload = {
          video_name: data.Name,
          suspended_date: values.suspended_date,
        }
        let resp = await suspendVideo(payload)
        if(resp){
          let payloadForLogs = {
            action: action?.suspend,
            module: modulesNames?.videos,
            description: logsMessagesList?.video?.videoSuspend,
          };
          await adminAddLogs(payloadForLogs);
          getListing();
          handleOpen(false);
        }
        setLoading(false);
        resetForm({
          video_name: "",
          suspended_date: ""
        });
    }catch(e){
      setLoading(false)
      console.log(e, 'error')
    }
  }
  const formik = useFormik({
    initialValues: {
      video_name: data.Name || "",
      suspended_date: formatDate(data.suspendate, "yyyy-mm-dd") || ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      video_name: Yup.string()
        .required('Video name is required.'),
      suspended_date: Yup.date()
        .required('Date is required.')

    }),
    onSubmit: handleUpload,
  });


  return (
    <>
      <Dialog
        maxWidth={'xs'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant='h3'>Suspend Video</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} textAlign={'center'}>
                <CustomImage
                  style={{ width: 'auto', maxHeight: 200, borderRadius: 8 }}
                  src={data.image_url}
                />
              </Grid>
              <Grid item xs={12} >
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="video_name"
                  label="Video name"
                  placeholder="Video name"
                  value={formik.values.video_name}
                  disabled
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.video_name &&
                    Boolean(formik.errors.video_name)
                  }
                  helperText={
                    formik.touched.video_name && formik.errors.video_name
                  }
                />
              </Grid>
              <Grid item xs={12} >
                <CustomInput
                  variant="outlined"
                  fullWidth
                  type="date"
                  name="suspended_date"
                  label="Suspended Date"
                  placeholder="Suspended Date"
                  value={formik.values.suspended_date}
                  onChange={(e) => {
                    formik.setFieldValue('suspended_date', formatDate(e.target.value, "yyyy-mm-dd"));
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.suspended_date &&
                    Boolean(formik.errors.suspended_date)
                  }
                  helperText={
                    formik.touched.suspended_date && formik.errors.suspended_date
                  }
                />
              </Grid>
            </Grid>
            <div style={{display: "flex", justifyContent: "end", marginTop: 15}}>
              <CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Suspend</CustomButton>
              <CustomButton onClick={() => handleOpen(false)} disabled={loading}>Close</CustomButton>
            </div>
          </Box>
        </DialogContent>
        {/* <DialogActions>
					<CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Upload</CustomButton>
					<CustomButton onClick={() => handleOpen(false)}>Close</CustomButton>
        </DialogActions> */}
      </Dialog>
    </>
  );
}
