import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';
import {
  crypt,
  formatDate,
  getNewParameters,
  getObjectSubset,
  getParameters,
  downloadBlobFile,
} from '../../lib/helper';
import {
  imageListingItemsSelector,
} from '../../redux/image';
import {
  imageListingsViewMost
} from '../../Services/imageApis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MatTable from '../Tables/MatTable';
import AllFilters from '../Order/AllFilters';
import { SearchByPending } from '../../data/imageData';

const column = [
  {
    label: 'Image',
    id: 'image_url',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}
      >
        <img style={{ maxWidth: 200, maxHeight: 200 }} src={`${columnData}`} />
      </span>
    ),
    numeric: 'center',
  },
  {
    label: 'Image ID',
    id: 'Name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', whiteSpace: 'nowrap' }}
        onClick={() => {}}
      >
        {columnData}
      </span>
    ),
  },
  // {
  //   label: 'Rank',
  //   id: 'rank',
  //   format: (columnData) => (
  //     <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
  //       {columnData}
  //     </span>
  //   ),
  // },

  // {
  //   label: 'Contributor',
  //   id: 'is_contributor',
  //   format: (columnData, rowIndex, formatCallback, func, errors, row) => (<span style={{ display: 'inline-block' }}>
  //     <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}><Typography variant='span' className='fontWeight600'>IsContributor:</Typography> {columnData == 1 ? 'yes' : 'No'}</span><br/>
  //     <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}><Typography variant='span' className='fontWeight600'>Name:</Typography> {row?.contributor}</span>
  //   </span>)
  // },

  {
    label: 'Shoot ID',
    id: 'shootid',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'View Count',
    id: 'view_count',
    format: (columnData) => (
      <span
        style={{ display: 'block', textAlign: 'center', whiteSpace: 'nowrap' }}
      >
        {columnData}
      </span>
    ),
    nextLine: false,
    numeric: 'center',
  },


  {
    label: 'Sold Count',
    id: 'sold_count',
    format: (columnData) => (
      <span
        style={{ display: 'block', textAlign: 'center', whiteSpace: 'nowrap' }}
      >
        {columnData}
      </span>
    ),
    nextLine: false,
    numeric: 'center',
  },
];


function HighestViewed() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const [editImageData, setEditImageData] = useState({});
  const { allImagesViewMost } = useSelector(imageListingItemsSelector);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(20);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [filtersArray, setFiltersArray] = useState([
   
    {
      label: 'From Date',
      id: 'start_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[1].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'end_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'Search By',
      id: 'search_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          newState[3].value='';
          return newState;
        });
      },
      style: {},
      dataArr: SearchByPending,
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          // newState[index].value = e.target.value;
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };

  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
        newState[1].isVisible = true;
        newState[1].value = '';
        newState[2].isVisible = true;
        newState[2].value = '';
      newState[3].isVisible = true;
      newState[3].value = '';

      return newState;
    });
  };

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      insertion_status: 'old',
      sortBy:'view_count',
      ...allParams,
      ...params,
    };

    if (payload?.start_date) {
      payload['start_date'] =
        formatDate(payload?.start_date, 'yyyy-mm-dd') + '  00:00:00';
    }
    if (payload?.end_date) {
      payload['end_date'] =
        formatDate(payload?.end_date, 'yyyy-mm-dd') + '  23:59:59';
    }

    dispatch(imageListingsViewMost(payload));
    setPage(Number(payload.page));
    setEditImageData({});
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }
    getListing();
  }, [allParams]);
 
  //making data format for table input
  useEffect(() => {
    if (allImagesViewMost?.items?.users?.length >= 0) {
      let data = allImagesViewMost?.items?.users?.map((item) => {
        return {
          image_url: `${ApiConfig.BASE_IMAGE_URL}${item?.shootid}/${item?.rank}-${item?.Name}.jpg`,
          Name: item?.Name,
          contributor: item,
          rank: item.rank, //=>edit
          rank1: item.rank1,
          shootid: item.shootid,
          is_contributor: item?.is_contributor,
          pricing: item.pricing, //=>edit
          suspendate: item.suspendate,
          createddate: item?.createddate,
          contributor: item.contributor,
          modelreleaseid: item?.modelreleaseid,
          available: item.available,
          type: item.type, //=>edit dropdown value [I, S]
          createddate: item.createdAt,
          imgstatus: item.imgstatus,
          suspend: item.imgstatus,
          rank1: item.rank1, //=>edit
          keywords: item.Name, //=>edit
          sold_count: item.sold_count,
          is_star: item?.is_star,
          watermark: item?.watermark,
          sold_count: item?.sold_count,
          total_sold_count: item?.total_sold_count,
          view_count:item?.view_count
        };
      });
      setTableListing(data);
    }
  }, [allImagesViewMost.items]);

  return (
    <>
      <MatTable
        loading={allImagesViewMost?.loading}
        showCheckbox={false}
        columns={ column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        // action={actionBody}
        page={page}
        perPage={limit}
        showPageLimit={true}
        pageLimitArray={[20, 50, 100, 200]}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        total={allImagesViewMost?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm, shootid, image_id) => {
          getListing({
            page: String(1),
            search: searchTerm,
            shoot_id: Number(sid),
            // ShootID: shoot_id,
            // ImageId: image_id,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
            shoot_id: Number(sid),
            // ShootID: shoot_id,
            // ImageId: image_id,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
            loading={loading}
          />
        )}
        disableSearch
      />
    </>
  );
}

export default HighestViewed;
