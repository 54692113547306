import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import { modelItemsSelector } from '../../redux/model';
import { AllImageModelListings } from '../../Services/modelApi';
import AssignImageToModelEditAssigned from '../Modals/AssignImageToModelEditAssigned';
import EditIcon from '@mui/icons-material/Edit';
import { SearchByAssignModel } from '../../data/modelData';
import AllFilters from '../Order/AllFilters';
const limit = 10;

const column = [
  {
    label: 'SNO',
    id: 'sno',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>
    )
  },
  {
    label: 'Name',
    id: 'Name',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Release ID',
    id: 'modelreleaseid',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Model ID',
    id: 'modelid2',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 500, width: "max-content" }}>{columnData || 'N/A'}</span>)
  },

];

function AllImageModel() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, modelImageList } = useSelector(modelItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [addImageModel, setAddImageModel] = useState(false);
  const [selectedData, setSelectedData] = useState(false);

  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'Search By',
      id: 'search_by',
      type: 'select',
      linkedWith: null,
      value: allParams?.search_by || '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[1].value = "";
          return newState;
        });
      },
      style: {},
      dataArr: SearchByAssignModel,
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: allParams?.search || '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },

  ]);

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };

  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[0].value = '';
      newState[1].value = '';
      return newState;
    });
  }

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...allParams,
      ...params
    }
    dispatch(AllImageModelListings(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])
  //making data format for table input
  useEffect(() => {
    if (modelImageList?.items?.data) {
      setTableListing(modelImageList?.items?.data?.rows)
    }

  }, [modelImageList?.items]);

  const handleAdd = (value = false) => {
    setAddImageModel(value);
  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <CustomButton className="noMinWidth buttonDense" onClick={() => {
            setSelectedData(data)
            handleAdd(true)
          }}
            toolTipTittle={'Edit'}
            sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}>
            <EditIcon />
          </CustomButton>
        </div>
      </>
    );
  };

  return (
    <>
      <MatTable
        loading={modelImageList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        page={page}
        perPage={limit}
        total={modelImageList?.items?.data?.count
          || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}

        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
          />
        )}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        disableSearch
        rangeDateFilter
      />
      {addImageModel && <AssignImageToModelEditAssigned open={addImageModel} handleOpen={handleAdd} setPage={setPage} handleLast={getListing} data={selectedData} />}
    </>
  );
}

export default AllImageModel;
