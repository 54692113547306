import React from 'react'
import { useSelector } from 'react-redux';
import { commonItemsSelector } from '../../redux/common';
import EmailSetup from './EmailSetup';
import JobDescription from './JobDescription';
import CountryList from './CountryList';
import StateList from './StateList';
import EmailPage from './EmailPage';
import ExclusivePricing from './ExclusivePricing';
import StarsSetting from './StarsSetting';
import PaymentMethods from './PaymentMethods';

function AppSettingsBody({activeTab}) {
  const {loading, appSetting} = useSelector(commonItemsSelector)
  return (
    <>
      {activeTab == 1 && <EmailSetup data={appSetting?.items?.features?.contact_us_emails || []} parentKey={'contact_us_emails'} />}
      {activeTab == 2 && <JobDescription />}
      {activeTab == 3 && <CountryList />}
      {activeTab == 4 && <StateList />}
      {activeTab == 5 && <EmailPage/>}
      {activeTab == 6 && <ExclusivePricing data={appSetting?.items?.features?.exclusive || []} parentKey={'exclusive'}/>}
      {activeTab == 7 && <StarsSetting data={appSetting?.items?.features?.stars || []} parentKey={'stars'}/>}
      {/* {activeTab == 2 && <SmtpInfo data={appSetting?.items?.features?.smtp || []} />}
      {activeTab == 3 && <S3Bucket data={appSetting?.items?.features?.s3_bucket || []} />} */}
      {/* {activeTab == 8 && <Banners data={appSetting?.items?.features?.banner_images || []} parentKey={'banner_images'}/>} */}
      {activeTab == 8 && <PaymentMethods data={appSetting?.items?.features?.payment_methods || []}/>}

    </>
  )
}

export default AppSettingsBody