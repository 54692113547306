import axios from 'axios';
import ApiConfig from '../config/ApiConfig';
import { toast } from 'react-toastify';
import { errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import { setAppSetting, setAppSettingError, setCountryData, setCountryDataError, setIsStateAvailable, setJobData, setJobDataError, setLoading, setStateData, setStateDataError } from '../redux/common';
import { setAllAdminLogsListing, setAllAdminLogsListingError, setAllAdminLogsListingLoading } from '../redux/adminLogs';

import { setItemInStorage } from '../storage/Storage';

export const appSettingApi = () => {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const resp = await axios.get(`${ApiConfig.appSetting}`);
        if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
          setItemInStorage('appSetting', JSON.stringify({...resp.data.data}));
          dispatch(setAppSetting(resp.data.data));
          dispatch(setLoading(false));
        } else {
          throw resp
        }
      } catch (e) {
        dispatch(
          setAppSettingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        dispatch(setLoading(false));
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
      }
    };
};

export const updateSettingApi = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.updateSetting}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};

export const addSettingApi = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.addSetting}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};

export const fileUpload = async (filePayload, header) => {
  try {
    const resp = await axios.post(`${ApiConfig.fileUpload}`, filePayload, header);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      return resp.data
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};

//fileUploadImage

export const fileUploadImage = async (filePayload, header) => {
  try {
    const resp = await axios.post(`${ApiConfig.uploadImage}`, filePayload, header);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      // return true
      return resp.data
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
export const fileUploadImages = async (filePayload, header) => {
  try {
    const resp = await axios.post(`${ApiConfig.uploadImage}`, filePayload, header);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      return true
      // return resp.data
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};

export const fileUploadImageBanners = async (filePayload, header) => {
  try {
    const resp = await axios.post(`${ApiConfig.uploadImageBanners}`, filePayload, header);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
     // return true
       return resp.data;
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};

export const fileUploadZip = async (filePayload, header) => {
  try {
    const resp = await axios.post(`${ApiConfig.uploadZip}`, filePayload, header);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
     // return true
       return resp.data;
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
///api/admin/UploadHomeBannerImages

export const getJobDescription = (payload) => {
  return async (dispatch) => {
    try {
      let url = `${ApiConfig?.getJobDescription}?page=${payload?.page || ""}&limit=${payload?.limit || ""}`
      if(payload?.admin){
        url += `&admin=${payload?.admin}` 
      }
      dispatch(setLoading(true));
      const resp = await axios.get(url);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setJobData(resp?.data?.data));
        dispatch(setLoading(false));
      } else {
        throw resp
      }
    } catch (e) {
      dispatch(
        setJobDataError({
          isError: true,
          message: errorMessage(e),
        })
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setLoading(false));
    }
  };
};

export const getCountry = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const resp = await axios.get(`${ApiConfig.getCountry}?page=${payload?.page || ""}&limit=${payload?.limit || ""}`);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setCountryData(resp?.data?.data));
        dispatch(setLoading(false));
      } else {
        throw resp
      }
    } catch (e) {
      dispatch(
        setCountryDataError({
          isError: true,
          message: errorMessage(e),
        })
      );
      toast.error( errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setLoading(false));
    }
  };
};

export const getCountryWithoutRedux = async () => {
  try {
    const resp = await axios.get(`${ApiConfig.getCountry}`);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data
    } else {
      throw resp
    }
  } catch (e) {
    toast.error( errorMessage(e), {
      autoClose: 2500,
    });
    return null
  }
};

export const getState = (payload, type='') => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        let url = `${ApiConfig.getState}?country_id=${payload}`
        if(type == 'list'){
          url = `${ApiConfig.getState}?country_id=${payload.country_id}&page=${payload?.page || ""}&limit=${payload?.limit || "" }&search=${payload?.search || "" }`
        }
        
        const resp = await axios.get(url);
        if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
          dispatch(setStateData(resp?.data?.data));
          dispatch(setLoading(false));
          if (resp?.data?.data?.length > 0) {
            dispatch(setIsStateAvailable(true));
          } else {
            dispatch(setIsStateAvailable(false));
          }
        } else {
          throw resp
        }
      } catch (e) {
        console.log(e)
        dispatch(
          setStateDataError({
            isError: true,
            message: errorMessage(e),
          })
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setIsStateAvailable(false));
        dispatch(setLoading(false));
      }
    };
  }
};

export const getStateNoRedux = async(payload, type='') => {
  if (payload !== undefined) {
      try {
        let url = `${ApiConfig.getState}?country_id=${payload}`
        if(type == 'list'){
          url = `${ApiConfig.getState}?country_id=${payload.country_id}&page=${payload?.page || ""}&limit=${payload?.limit || "" }&search=${payload?.search || "" }`
        }
        
        const resp = await axios.get(url);
        if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
          return resp?.data?.data;
        } else {
          throw resp
        }
      } catch (e) {
        console.log(e)
       
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
      }

  }
};

export const getImageInfo = async(payload) => {
  if (payload !== undefined) {
      try {
        const resp = await axios.get(
          `${ApiConfig.imageInfo}/${payload?.name}?type=${payload?.type}`
        );
        if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
          return resp.data.data
        } else {
          throw resp
        }
      } catch (e) {
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
      }
    };
};

export const addJobDescription = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.addJob}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};

export const updateJobDescription = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.updateJobDesc}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};

export const addCountry = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.addCountry}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};

export const updateCountry = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.updateCountry}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};

export const addState = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.addState}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};

export const updateState = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.updateState}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};

export const companyByNameNoRedux = async (payload) => {
  if (payload !== undefined) {
    try{
      const  resp = await axios.get(`${ApiConfig.companyName}/${payload.name}`);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data
      } else {
        throw resp
      }
    }catch(e){
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  };
};



export const BackgroundImageNoRedux = async () => {
  
    try{
      const  resp = await axios.get(`${ApiConfig.backgroundImage}`);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data
      } else {
        throw resp
      }
    }catch(e){
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
 
};

export const adminAddLogs = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.adminLogs}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const AdminLogsListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllAdminLogsListingLoading(true));
        const resp = await axios.post(`${ApiConfig.ListOfAdminLogs}`, payload);
        
        if (resp?.data?.status >= STATUS_200 && resp?.data?.status < 300) {
          dispatch(setAllAdminLogsListing(resp.data.data));
          dispatch(setAllAdminLogsListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllAdminLogsListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllAdminLogsListingLoading(false));
      }   
    };
  }
};