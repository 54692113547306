import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, MenuItem } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addCountry, adminAddLogs, updateCountry} from '../../Services/commonService';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';

export default function CountryListModal({ open, handleOpen, data, getListing }) {
  const [loading, setLoading] = React.useState(false);

  const handleDataSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true)
      if (data) {
        let resp = await updateCountry({
          country_id: data.country_id,
          country_name: values.country_name,
          states_available: values.states_available,
          status: values.status,
        })
        if (resp) {
          let payloadForLogs = {
            action: action?.update,
            module: modulesNames?.settings,
            description: logsMessagesList?.setting?.countryUpdate,
          };
          await adminAddLogs(payloadForLogs);
          getListing();
          handleOpen(false);
        }
        setLoading(false);
      } else {
        let resp = await addCountry({
          country_name: values.country_name,
          statesAvailable: values.states_available,
          status: values.status,
        })
        if (resp) {
          let payloadForLogs = {
            action: action?.add,
            module: modulesNames?.settings,
            description: logsMessagesList?.setting?.countryAdd,
          };
          await adminAddLogs(payloadForLogs);
          getListing();
          handleOpen(false);
        }
        setLoading(false);
      }
    } catch (e) {
      console.log(e, 'error');
    }
  };
  const formik = useFormik({
    initialValues: {
      country_name: data?.country_name || '',
      status: data?.status || '1',
      states_available: data?.states_available || '1',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      country_name: Yup.string().required('Please enter job description.'),
      status: Yup.string().required('This field is required.'),
      states_available: Yup.string().required('This field is required.'),
    }),
    onSubmit: handleDataSubmit,
  });

  return (
    <>
      <Dialog
        maxWidth={'xs'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
        fullWidth
      >
        <DialogTitle variant="h3">Country Add/Update</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="country_name"
                  label="Country Name"
                  placeholder="Country Name"
                  value={formik.values?.country_name}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched?.country_name &&
                    Boolean(formik.errors?.country_name)
                  }
                  helperText={
                    formik.touched?.country_name && formik.errors?.country_name
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="states_available"
                  label="States Available"
                  placeholder="States Available"
                  select
                  value={formik.values?.states_available}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched?.states_available &&
                    Boolean(formik.errors?.states_available)
                  }
                  helperText={
                    formik.touched?.states_available && formik.errors?.states_available
                  }
                  onChange={formik.handleChange}
                >
                  <MenuItem key={"0"} value={"0"}>Not Available</MenuItem>
                  <MenuItem key={"1"} value={"1"}>Available</MenuItem>
                </CustomInput>
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="status"
                  label="Status"
                  placeholder="Status"
                  select
                  value={formik.values?.status}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched?.status &&
                    Boolean(formik.errors?.status)
                  }
                  helperText={
                    formik.touched?.status && formik.errors?.status
                  }
                  onChange={formik.handleChange}
                >
                  <MenuItem key={"0"} value={"0"}>Inactive</MenuItem>
                  <MenuItem key={"1"} value={"1"}>Active</MenuItem>
                </CustomInput>
              </Grid>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}>
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Save
              </CustomButton>
              <CustomButton
                onClick={() => {
                  handleOpen(false);
                  formik.resetForm()
                }}
                disabled={loading}
              >
                Close
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
