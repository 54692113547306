import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allAdminLogsList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  
};

export const adminLogsSlice = createSlice({
  name: 'adminLogs',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllAdminLogsListing: (state, action) => {
      state.allAdminLogsList.items = action.payload;
      state.allAdminLogsList.error = {
        isError: false,
        message: '',
      };
    },
    setAllAdminLogsListingLoading: (state, action) => {
        state.allAdminLogsList.loading = action.payload;
      },
    setAllAdminLogsListingError: (state, action) => {
      state.allAdminLogsList.items = {};
      state.allAdminLogsList.error = action.payload;
    },
  },
});

export default adminLogsSlice.reducer;

export const {
  setLoading,
  setAllAdminLogsListing,
  setAllAdminLogsListingError,
  setAllAdminLogsListingLoading,
} = adminLogsSlice.actions;

export const adminLogsSelector = (state) => state.adminLogs;
