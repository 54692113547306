import React, { useState } from 'react';
import UploadImageCsvModal from '../../Components/Modals/UploadImageCsvModal';

function AddKeywords({ handleNavParams, handleImageCsvModal, uploadKeywords }) {
  const [tabValue, setTabValue] = useState(1);

  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };
  return (
    <>
      <UploadImageCsvModal open={uploadKeywords} handleOpen={handleImageCsvModal}/>
      </>
  );
}

export default AddKeywords;
