import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { deleteFromWishlist, downloadImageAdminUser, temporaryUserListing, userWishlistApi } from '../../Services/userApis';
import { useDispatch, useSelector } from 'react-redux';
import { addCompanyGroup, companyGroupsListings } from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';
import { imageListingsNoRedux } from '../../Services/imageApis';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import PreviewImageComp from '../ProposalManagement/PreviewImageComp';
import { commonItemsSelector } from '../../redux/common';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import CustomIconButtons from '../CommonComp/Buttons/IconButtons';
import Favorite from '@mui/icons-material/Favorite';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import ApiConfig from '../../config/ApiConfig';
import { toast } from 'react-toastify';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

const limit = DATA_LIMIT;
const largePackIds = [10118, 10119, 10120];
const webPackIds = [10121, 10122, 10123];
const pendingOrderHeader = [
  {
    label: "Select",
    id: 1,
  },
  {
    label: "Order ID",
    id: 2,
  },
  {
    label: "Invoice ID",
    id: 3,
  },
  {
    label: "Package",
    id: 4,
  },
  {
    label: "Remaining",
    id: 5,
  },
  {
    label: "Client",
    id: 6,
  },
];

function DownloadImagesUnderPackModal({ open, handleOpen, heading, userId, singleUserActiveIvsOrderDetail, getActiveIvsData }) {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('image')


  const getImageListing = async (inputValue) => {
    if (inputValue && inputValue.length > 1) {
      let data = await imageListingsNoRedux({ search: inputValue, type: type })
      if (type === 'image') {
        let updatedData = data?.map((item, index) => {
          return {
            value: item.Name,
            label: item.Name,
            rank: item.rank,
            sno: item.sno,
            shootid: item.shootid,
            image_name: item.Name,
            price: null,
            quality: null
          }
        })
        return updatedData || []
      }
      else if (type === 'video') {
        let updatedData = data?.map((item, index) => {
          return {
            value: item.video_name,
            label: item.video_name,
            rank: item.rank,
            sno: item.video_id,
            shootid: item.shootid,
            image_name: item.video_name,
            price: null,
            quality: null
          }
        })
        return updatedData || []
      }
      return []
    }
  }

  const handleImageDownload = async (values) => {
    try {
      setLoading(true)
      const resp = await downloadImageAdminUser({
        order_id: values?.plan_data?.order_id,
        image_name: values?.image?.image_name,
        client: values?.client_name,
        type: type === 'image' ? '1' : '2'
      })
      if (resp) {
        handleOpen(false)
        getActiveIvsData()
        let payloadForLogs = {
          action: action?.download,
          module: modulesNames?.users,
          description: logsMessagesList?.users?.userImageDownload,
        };
        await adminAddLogs(payloadForLogs);
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const handleType = (val) => {
    setType(val);
  }

  const formik = useFormik({
    initialValues: {
      image: '',
      client_name: '',
      plan_data: '',
    },
    validationSchema: Yup.object({
      image: Yup.object().required("Please select image"),
      client_name: Yup.string().required("Please enter Name"),
      plan_data: Yup.object().required("Please select plan"),
    }),
    onSubmit: handleImageDownload,
  });


  // useEffect(()=>{
  //   if(userId){
  //     getData()
  //   }
  // },[userId])

  return (
    <div>
      <Dialog
        maxWidth={'lg'}
        open={open}
        fullWidth
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle component='div' padding={3} sx={{ display: 'flex', alignItems: "center" }}>
          <Typography variant="h3">{heading}</Typography>
          {handleOpen && <CustomIconButtons onClick={() => { handleOpen(false); }} sx={{ padding: '13px 15px', ml: 'auto' }}
            color={'iconButtonPrimary'} disabled={loading}><CloseIcon /></CustomIconButtons>}
        </DialogTitle>
        <DialogContent>
          <Box noValidate component="div" sx={{
            minWidth: 300,
            height: "100%"
          }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      select
                      SelectProps={{
                        native: true,
                        style: {
                          height: 40
                        }
                      }}
                      name="type"
                      label="Type"
                      placeholder="Type"
                      value={type}
                      onChange={(e) => {
                        handleType(e?.target.value)

                      }}
                      style={{ maxWidth: 150 }}
                    >
                      <option key={'image'} value="image">Image</option>
                      <option key={'video'} value="video">Video</option>
                    </CustomInput>

                  </Grid>
                  {type === 'image' &&
                    <Grid item xs={12}>
                      <CustomAsyncSelect
                        promiseOptions={getImageListing}
                        closeMenuOnSelect={false}
                        controlStyle={{
                          padding: 5
                        }}
                        cacheOptions={true}
                        handleChange={(e) => {
                          formik.setFieldValue('image', e)
                        }}
                        width={'100%'}
                        dropDownZIndex={100000}
                        labelShrink={true}
                        label="Search Image"
                        isMulti={false}
                        error={
                          formik.touched.image &&
                          Boolean(formik.errors.image)
                        }
                        helperText={
                          formik.touched.image && formik.errors.image
                        }
                      />
                    </Grid>
                  }
                  {type === 'video' &&
                    <Grid item xs={12}>
                      <CustomAsyncSelect
                        promiseOptions={getImageListing}
                        closeMenuOnSelect={false}
                        controlStyle={{
                          padding: 5
                        }}
                        cacheOptions={true}
                        handleChange={(e) => {
                          formik.setFieldValue('image', e)
                        }}
                        width={'100%'}
                        dropDownZIndex={100000}
                        labelShrink={true}
                        label="Search Video"
                        isMulti={false}
                        error={
                          formik.touched.image &&
                          Boolean(formik.errors.image)
                        }
                        helperText={
                          formik.touched.image && formik.errors.image
                        }
                      />
                    </Grid>
                  }
                  <Grid item xs={12}>
                    <Box className="border-1 rounded-3" mt={2} px={2} py={1} minHeight={150}>
                      <Typography variant="h6"> Preview</Typography>
                      {formik?.values?.image ?
                        <Box sx={{ display: "flex", overflow: "hidden", overflowX: "auto" }}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: 175, marginRight: 2, marginBottom: 2, bgcolor: (theme) => theme.backgroundColor.box.primary, px: 0.5, pb: 1, borderRadius: 5, position: 'relative' }}>
                            <CustomImage
                              src={`${ApiConfig.BASE_IMAGE_URL}${formik?.values?.image?.shootid}/${formik?.values?.image?.rank}-${formik?.values?.image?.image_name}.jpg`}
                              style={{
                                maxWidth: 170,
                                marginTop: 5,
                                maxHeight: 110,
                                borderRadius: 10,
                                width: 165,
                                height: 110
                              }}
                            />
                            <Typography variant="h6" mt={1} pl={1}>{formik?.values?.image?.image_name}</Typography>
                          </Box>
                        </Box> : <Typography variant="p" component={'p'} className="fontWeight500" textAlign={"center"} pt={4}>No Data Available</Typography>}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={7}>
                <form onSubmit={formik.handleSubmit} noValidate>
                  <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
                    <Table aria-label="Pending Orders">
                      <TableHead>
                        <TableRow sx={{
                          backgroundColor: '#ddd',
                        }}>
                          {pendingOrderHeader?.map((data, index) => {
                            return (
                              <TableCell key={index} sx={{
                                backgroundColor: "inherit",
                                "&:hover": { backgroundColor: "inherit" },
                                borderBottom: "none",
                              }}
                              >
                                <Typography component="div" variant="mainsectiontitle">
                                  {data?.label}
                                </Typography>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {singleUserActiveIvsOrderDetail?.map((data, index) => {
                          return (
                            <TableRow key={index} sx={{
                              backgroundColor: `${largePackIds?.includes(data?.plan_id) ? "#f0ffea" : webPackIds?.includes(data?.plan_id) ? "#fdeafe" : "white"}`,
                            }}>
                              <TableCell sx={{ backgroundColor: "inherit", "&:hover": { backgroundColor: "inherit" }, }}>
                                <div className="sub-package-main-checkbox">
                                  <input
                                    className="sip-table-checkbox"
                                    name="package-name"
                                    id="package-name"
                                    checked={formik.values.plan_data?.order_id === data?.order_id ? true : false}
                                    type="radio"
                                    value={data?.order_id}
                                    onChange={() => {
                                      formik.setValues((values) => ({
                                        ...values,
                                        'plan_data': data,
                                        'client_name': data?.invoice?.client
                                      }))
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell sx={{ backgroundColor: "inherit", "&:hover": { backgroundColor: "inherit" }, }}>
                                <Typography component="div" variant="sectiondata">{data?.order_id}</Typography>
                              </TableCell>
                              <TableCell sx={{ backgroundColor: "inherit", "&:hover": { backgroundColor: "inherit" }, }}>
                                <Typography component="div" variant="sectiondata" >{data?.invoice?.invoice_id}</Typography>
                              </TableCell>
                              <TableCell sx={{ backgroundColor: "inherit", "&:hover": { backgroundColor: "inherit" }, }}>
                                <Typography component="div" variant="sectiondata"   >{data?.type}</Typography>
                              </TableCell>
                              <TableCell sx={{ backgroundColor: "inherit", "&:hover": { backgroundColor: "inherit" }, }}>
                                <Typography component="div" variant="sectiondata"  >{`${data?.remaining_images}/${data?.total_images}`}</Typography>
                              </TableCell>
                              <TableCell sx={{ backgroundColor: "inherit", "&:hover": { backgroundColor: "inherit" }, }}>
                                <Typography component="div" variant="sectiondata"   >{data?.invoice?.client || ""}</Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Box>
                  {singleUserActiveIvsOrderDetail?.length > 0 ? <Box sx={{ display: 'flex', marginTop: 5 }}>
                    <CustomInput
                      name="client_name"
                      label="Client/Designated End User"
                      placeholder="Client/Designated End User"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      fullWidth
                      onChange={(e) => {
                        formik.setFieldValue("client_name", e.target.value);
                      }}
                      id="client_name"

                      value={formik.values.client_name}

                      error={
                        formik.touched.client_name &&
                        Boolean(formik.errors.client_name)
                      }
                      helperText={
                        formik.touched.client_name && formik.errors.client_name
                      }
                    />
                    <div>
                      <CustomButton loading={loading} type="submit" onClick={() => {
                        if (formik?.errors?.plan_data) {
                          toast.error(formik?.errors?.plan_data)
                        }
                      }} sx={{ padding: '14px 15px', marginLeft: 3, mt: 0.7 }} color={'buttonSecondary'}>Download</CustomButton>
                    </div>
                  </Box> : <Typography component="h5" variant="sectiondata" sx={{ textAlign: 'center', mt: 5 }}>No plans are available</Typography>}
                </form>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DownloadImagesUnderPackModal;
