import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { addVisibleKeyword } from '../../Services/keywordsApis';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import {
  appSettingApi,
  getCountry,
  getImageInfo,
  getJobDescription,
  getState,
} from '../../Services/commonService';
import { createContributor } from '../../Services/ContributorApis';
import { commonItemsSelector } from '../../redux/common';
import { useDispatch, useSelector } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RadioField from '../CommonComp/CustomInput/RadioField';
import {
  upgradeImage,
  upgradeImagePrice,
  userCartsApi,
} from '../../Services/userApis';
import { adminAddLogs } from '../../Services/commonService';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
const limit = 10;

function UpgradeImageOrVideoModal({ open, handleOpen, userData, userId }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [dataArr, setDataArr] = useState([]);
  const { appSetting } = useSelector(commonItemsSelector);
  const [imageDetail, setImageDetail] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const [imagePrice, setImagePrice] = useState(null);
  const [userSelectedImageQuality, setUserSelectedImageQuality] =
    useState(null);

  const handleCartList = () => {
    let listPayload = {
      page: String(1),
      limit: limit - 5,
    };
    dispatch(userCartsApi(userId, listPayload));
  };
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let resp = await upgradeImage(values);
    if (resp) {
      handleOpen(false);
      handleCartList();
    }
    let payloadForLogs = {
      action: action?.upgrade,
      module: modulesNames?.users,
      description: logsMessagesList?.users?.userUpgradeImageAddedToCart,
    };
    await adminAddLogs(payloadForLogs);
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      image_type_up: userData?.quality || '',
      image_id: imageDetail?.sno || '',
      image_type: '',
      type: userData?.type || '',
      user_id: userId,
      //image_type_up
      price: '',
      // proposal_id : proposalId || "",
      // payment_mode : "cheque-demand-draft",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      image_type: Yup.string().required('User Email is required.'),
      image_type_up: Yup.string().required('Image Type is required.'),
      image_id: Yup.string().required('Image Id is required.'),
      type: Yup.string().required('Type is required.'),
      price: Yup.string().required('Price is required.'),
    }),
    onSubmit: handleConfirm,
  });

  useEffect(() => {
    getListing();
  }, [userData]);

  useEffect(() => {
    getImageData();
  }, [userData]);

  useEffect(() => {
    upgradeImagePr();
  }, [userData]);

  const upgradeImagePr = async () => {
    if (userData?.image_name) {
      let resp = await upgradeImagePrice({
        image_name: userData?.image_name,
        order_id: userData?.order_id,
        user_id: userId,
      });
      if (resp) {
        setImagePrice(resp?.data);
      } else {
        setImagePrice(null);
      }
    }
  };

  const getImageData = async () => {
    if (userData?.image_name) {
      let resp = await getImageInfo({ name: userData?.image_name, type: '1' });
      if (resp) {
        setImageDetail(resp);
      } else {
        setImageDetail(null);
      }
    }
  };

  const getListing = () => {
    dispatch(appSettingApi());
  };

  useEffect(() => {
    if (userData?.type === '1') {
      let tempArray =
        appSetting?.items?.subscriptions?.singleImagePrice?.filter((img) => {
          if (userData?.quality === 'WEB') {
            return (
              img?.image_type == 'SMALL' ||
              img?.image_type == 'MEDIUM' ||
              img?.image_type == 'LARGE'
            );
          }
          if (userData?.quality === 'SMALL') {
            return img?.image_type == 'LARGE' || img?.image_type == 'MEDIUM';
          }
          if (userData?.quality === 'MEDIUM') {
            return img?.image_type === 'LARGE';
          }
        });
      setDataArr(tempArray);
      let selPrice = appSetting?.items?.subscriptions?.singleImagePrice?.find(
        (img) => img?.image_type === userData?.quality,
      );
      if (selPrice) {
        setUserSelectedImageQuality(selPrice?.price);
      } else {
        setUserSelectedImageQuality(8000);
      }
    } else if (userData?.type === '2') {
      if (userData?.quality === 'WEB')
        setDataArr(
          appSetting?.items?.subscriptions?.singleVideoPrice?.filter(
            (a) => a?.video_type != 'SMALL',
          ),
        );
    }
  }, [appSetting?.items]);

  const handleSelectPlan = (val) => {
    formik.setFieldValue('image_type', val?.image_type);
    if (imagePrice?.perimageprice) {
      formik?.setFieldValue(
        'price',
        val?.price - Number(imagePrice?.perimageprice),
      );
    } else {
      formik?.setFieldValue('price', val?.price - userSelectedImageQuality);
    }
  };

  const calCulatePrice = (price) => {
    if (imagePrice?.orderType == 'IV') {
      return price - Number(imagePrice?.perimageprice);
    } else {
      return price - userSelectedImageQuality;
    }
  };

  return (
    <div>
      <Dialog
        maxWidth={'xl'}
        fullWidth
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
      >
        <DialogTitle variant="h3">
          Upgrade {userData?.type === '1' ? 'Image' : 'Video'}
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={4}>
                <img
                  src={`${ApiConfig.BASE_IMAGE_URL}${userData?.shoot_id}/${userData?.rank}-${userData?.image_name}.jpg`}
                  className="img-fluid"
                />
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell align="right">
                          Actual Price&nbsp;(INR)
                        </TableCell>
                        <TableCell align="right">
                          Upgrade Price&nbsp;(INR)
                        </TableCell>
                        <TableCell align="center">
                          Dimensions&nbsp;(Pixels)
                        </TableCell>
                        <TableCell align="right">File Format</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* //appSetting?.items?.subscriptions?.singleImagePrice */}
                      {userData?.type === '1' &&
                        dataArr?.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {/* <RadioField
                              name="plan_type"
                              // label={"Plan"}
                              row
                              // value={formik.values.email}
                              value={''}
                              // onChange={(e)=>{handleChange('followup_status', e.target.value)}}
                              // error={formik.touched.email && Boolean(formik.errors.email)}
                              // helperText={ formik.touched.email && formik.errors.email}
                              radioData={['a']}
                            /> */}

                              <input
                                className="sip-table-checkbox"
                                id={`SelectedPlan${6}`}
                                type="radio"
                                value={formik?.image_type}
                                name="payment-method-name"
                                checked={
                                  formik?.values?.image_type === row?.image_type
                                }
                                onChange={() => {
                                  handleSelectPlan(row);
                                }}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.image_type}
                            </TableCell>
                            <TableCell align="right">{row.price}</TableCell>
                            <TableCell align="right">
                              {/* {row.price-userData?.amount} */}
                              {/* {calCulatePrice(row?price)} */}
                              {calCulatePrice(row?.price)}
                            </TableCell>
                            <TableCell align="right">
                              {row.Dimensions}
                            </TableCell>
                            <TableCell align="right">
                              {row.file_format}
                            </TableCell>
                          </TableRow>
                        ))}
                      {userData?.type === '2' &&
                        dataArr?.map((row, index) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {/* <RadioField
                              name="plan_type"
                              // label={"Plan"}
                              row
                              // value={formik.values.email}
                              value={''}
                              // onChange={(e)=>{handleChange('followup_status', e.target.value)}}
                              // error={formik.touched.email && Boolean(formik.errors.email)}
                              // helperText={ formik.touched.email && formik.errors.email}
                              radioData={['a']}
                            /> */}
                              <input
                                className="sip-table-checkbox"
                                id={`SelectedPlan${index}`}
                                type="radio"
                                value={formik?.image_type_up}
                                name="plan_type"
                                checked={
                                  formik?.image_type_up === row?.video_type
                                }
                                onChange={() => {
                                  handleSelectPlan(row);
                                }}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.video_type}
                            </TableCell>
                            <TableCell align="right">{row.price}</TableCell>
                            <TableCell align="right">
                              {/* {row.price-userData?.amount} */}
                              {row?.price -
                                imagePrice?.data?.soldImages?.amount}
                            </TableCell>
                            <TableCell align="right">
                              {row.Dimensions}
                            </TableCell>
                            <TableCell align="right">
                              {row.file_format}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Add to Cart
              </CustomButton>
              <CustomButton
                onClick={() => {
                  handleOpen(false);
                }}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UpgradeImageOrVideoModal;
