import React from 'react'
import CompanAssignedList from './CompanAssignedList';
import CompanyUnassignedList from './CompanyUnassignedList';
import CompanyMasterListOverview from './CompanyMasterListOverview';

function CompanyTabBody({ activeTab }) {
  return (
    <>
     {/* <CustomBox padding={0} sx={{minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center"}}> */}
       {activeTab == 1 && <CompanyUnassignedList/>}
       {activeTab == 2 && <CompanAssignedList/>}
       {/* {activeTab == 3 && <CompanyMasterList />} */}
       {activeTab == 3 && <CompanyMasterListOverview />}

       {/* {activeTab == 4 && <MastersList />} */}
     {/* </CustomBox> */}
    </>
  );
}

export default CompanyTabBody;
