import { Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { breakStringIntoLines, getArrayOfObjJoinToStringForKey, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { keywordItemsSelector } from '../../redux/keyword';
import { deleteHiddenKeyword, getHiddenKeywordListing } from '../../Services/keywordsApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import AddHiddenKeyword from '../Modals/AddHiddenKeyword';
import ConfirmationModal from '../Modals/ConfirmationModal';
import EditHiddenKeyword from '../Modals/EditHiddenKeyword';
import MatTable from '../Tables/MatTable';

const limit = 10;

const column = [
  {
    label: 'ID',
    id: 'aid',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'All Keyword',
    id: 'all_keyword',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>
      {columnData?breakStringIntoLines(columnData,20):'N/A'}
      </span>)
  },
  {
    label: 'Visible Keyword',
    id: 'hiddenkeywords',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 250, width: "max-content"}}>{columnData?breakStringIntoLines(getArrayOfObjJoinToStringForKey(columnData, 'visible_keyword') ,20)||'-':'N/A'}</span>)
  },
  {
    label: 'Status',
    id: 'status',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content", textAlign: "center"}}>{columnData.toString() == '1' ? 'Active' : 'Inactive'}</span>)
  },
];

function HiddenKeywordsList() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { hiddenKeywordList } = useSelector(keywordItemsSelector);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const navigate = useNavigate()
  const location = useLocation();

  const getListing = async (params={}) => {
    const payload = {
        page: String(1), 
        limit: limit,
        order_status: String(1),
        ...allParams,
        ...params
    }
    dispatch(getHiddenKeywordListing(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])

  useEffect(() => {
    if(hiddenKeywordList?.items?.rows?.length >= 0){
      setTableListing(hiddenKeywordList?.items?.rows)
    }
  }, [hiddenKeywordList.items]);


  const actionBody = (data) => {
    return (
      <>
          <div style={{display: "flex"}}>
          <CustomButton toolTipTittle={'Edit Hidden Keyword'} 
            onClick={()=>{
              handleEdit(true, data)
            }}
            className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Edit/></CustomButton>
            </div>
      </>
    );
  };

  const actionHead = (data) => {
    return (<div style={{display: "flex"}}>
       hi
    </div>);
  };


  const handleAdd = (value) => {
    setOpen(value);
  }

  const handleEdit = (value, data={}) => {
    setEditOpen(value);
    setSelectedData(data)
  }

  const handleConfirmDelete = async(value) =>{
    if(value && deleteData){
      const resp = await deleteHiddenKeyword({hidden_keyword_id: deleteData});
      if(resp){
        getListing({page: '1'})
        setPage(1)
        setDeleteData(null)
      }
    }else{
      setDeleteData(null)
    }
  }


  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  }


  return (
    <>
      <MatTable
        loading={hiddenKeywordList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data={})=>{
          getListing({
            page: String(1), 
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if(Object.keys(data).length > 0){
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          }else{
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={hiddenKeywordList?.items?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm)=>{
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage)=>{
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolbarEnable={true}
        toolBarButton={()=>{
          return <>
            <CustomButton onClick={()=>{handleAdd(true)}} color={'buttonPrimary'}  sx={(theme)=>({ padding: '10px 10px', marginRight: 1, 
                [theme.breakpoints.down("md")]: {
                  flex: 1,
                }
              })}>Add Hidden Keyword</CustomButton>
          </>
        }}
        pagination={true}
        filtersUrlData={allParams || {}}
      />
      {open && <AddHiddenKeyword open={open} handleOpen={handleAdd} setPage={setPage} getListing={getListing} /> }
      {editOpen && <EditHiddenKeyword open={editOpen} handleOpen={handleEdit} setPage={setPage} getListing={getListing} selectedData={selectedData} /> }
      {confirmDelete && <ConfirmationModal open={confirmDelete} handleConfirm={handleConfirmDelete} setOpen={handleCofirmModalOpen}/>}
    </>
  );
}

export default HiddenKeywordsList;
