import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, MenuItem } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { createCommonSettingCategoryTableData, formatDate, handleFileReader } from '../../lib/helper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import { updateImage, updateImageNew } from '../../Services/imageApis';
import { commonItemsSelector } from '../../redux/common';
import { useSelector } from 'react-redux';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

export default function EditImageDetailModalNew({ open, handleOpen, data, getListing, type }) {
  const [loading, setLoading] = React.useState(false);
  const {appSetting} = useSelector(commonItemsSelector)
  const [categoryTableData, setCategoryTableData] = React.useState([]);


  const handleUpload = async(values, { resetForm })=>{
    try{
      setLoading(true)
        let resp = await updateImageNew(values)
        if(resp){
          let payloadForLogs = {
            action: action?.update,
            module: modulesNames?.images,
            description: logsMessagesList?.image?.imageUpdate,
          };
          await adminAddLogs(payloadForLogs);
          toast.success(resp?.message)
          getListing();
          handleOpen(false);
        }
        setLoading(false);
        resetForm();
    }catch(e){
      setLoading(false)
      console.log(e, 'error')
    }
  }

  const formik = useFormik({
    initialValues: {
      image_name: data.Name || "",
      image_id:data?.sno || "",
      rank: data.rank || "",
      shootid:data?.shootid || "",
      rank5: data.rank5 || "",
      pricing: data.pricing || "",
      nonexclusive: data?.nonexclusive===1?1:0,
      suspendate:data?.suspendate || "",
      modelreleaseid:data?.modelreleaseid || ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      image_name: Yup.string()
        .required('Image name is required.'),
      image_id: Yup.string()
        .required('Image id is required.'),
      rank: Yup.string()
        .required('Image rank is required.')
        .matches(/^\d+$/, 'Image rank should be number only.'),
      rank5: Yup.string()
        .required('Rank is required.')
        .matches(/^\d+$/, 'Rank should be number only.'),
      pricing: Yup.string()
        .required('Pricing is required.')
        .matches(/^\d+$/, 'Pricing should be number only.'),
    }),
    onSubmit: handleUpload,
  });

  React.useEffect(()=>{
    if(type == "starimage"){
      setCategoryTableData(createCommonSettingCategoryTableData(appSetting?.items?.features?.stars[0]?.key_value, 'stars'));
    }
  },[appSetting?.items?.features?.stars])

  return (
    <>
      <Dialog
        maxWidth={'lg'}
        open={open}
        onClose={() => {

        }}
      >
        <DialogTitle variant='h3'>Edit Image Details</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} sm={4} md={3}>
                <CustomImage
                  style={{ width: '100%',  borderRadius: 8 }}
                  src={data.image_url}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Grid container spacing={2} mt={0}>
                    <Grid item xs={12} sm={6} md={4}>
                      <CustomInput
                        variant="outlined"
                        fullWidth
                        name="image_name"
                        label="Image name"
                        placeholder="Image name"
                        value={formik.values.image_name}
                        disabled
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          classes: {
                            notchedOutline: 'rounded',
                          },
                        }}
                        error={
                          formik.touched.image_name &&
                          Boolean(formik.errors.image_name)
                        }
                        helperText={
                          formik.touched.image_name && formik.errors.image_name
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <CustomInput
                        variant="outlined"
                        fullWidth
                        name="rank"
                        label="Rank"
                        placeholder="Rank"
                        value={formik.values.rank}
                        onChange={(e) => {
                          formik.setFieldValue('rank', e.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          classes: {
                            notchedOutline: 'rounded',
                          },
                        }}
                        error={
                          formik.touched.rank &&
                          Boolean(formik.errors.rank)
                        }
                        helperText={
                          formik.touched.rank && formik.errors.rank
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <CustomInput
                        variant="outlined"
                        fullWidth
                        name="rank5"
                        label="Rank5"
                        placeholder="Rank5"
                        value={formik.values.rank5}
                        onChange={(e) => {
                          formik.setFieldValue('rank5', e.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          classes: {
                            notchedOutline: 'rounded',
                          },
                        }}
                        error={
                          formik.touched.rank5 &&
                          Boolean(formik.errors.rank5)
                        }
                        helperText={
                          formik.touched.rank5 && formik.errors.rank5
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <CustomInput
                        variant="outlined"
                        fullWidth
                        name="shootid"
                        label="Shoot ID"
                        placeholder="Shoot ID"
                        value={formik.values.shootid}
                        onChange={(e) => {
                          formik.setFieldValue('shootid', e.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          classes: {
                            notchedOutline: 'rounded',
                          },
                        }}
                        error={
                          formik.touched.shootid &&
                          Boolean(formik.errors.shootid)
                        }
                        helperText={
                          formik.touched.shootid&& formik.errors.shootid
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <CustomInput
                        variant="outlined"
                        fullWidth
                        name="pricing"
                        label="Pricing"
                        placeholder="Pricing"
                        value={formik.values.pricing  }
                        onChange={(e) => {
                          formik.setFieldValue('pricing', e.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          classes: {
                            notchedOutline: 'rounded',
                          },
                        }}
                        error={
                          formik.touched.pricing &&
                          Boolean(formik.errors.pricing)
                        }
                        helperText={
                          formik.touched.pricing && formik.errors.pricing
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      name="nonexclusive"
                      label="Non-Exclusive"
                      id="nonexclusive"
                      placeholder="Non-Exclusive"
                      SelectProps={{
                        native: true,
                      }}
                      select
                      value={formik.values.nonexclusive}
                      
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      onChange={(e) => {
                        formik.setFieldValue('nonexclusive', e.target.value);
                      }}
                      fullWidth
                      error={formik.touched.nonexclusive && Boolean(formik.errors.nonexclusive)}
                      helperText={formik.touched.nonexclusive && formik.errors.nonexclusive}
                    >
                      <option key={'0'} value={0}>
                        0
                      </option>
                      <option key={'1'} value={1}>
                        1
                      </option>
                    </CustomInput>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                      <CustomInput
                        variant="outlined"
                        fullWidth
                        name="modelreleaseid"
                        label="Model Release ID"
                        placeholder="Model Release ID"
                        value={formik.values.modelreleaseid}
                        onChange={(e) => {
                          formik.setFieldValue('modelreleaseid', e.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          classes: {
                            notchedOutline: 'rounded',
                          },
                        }}
                        error={
                          formik.touched.modelreleaseid &&
                          Boolean(formik.errors.modelreleaseid)
                        }
                        helperText={
                          formik.touched.modelreleaseid && formik.errors.modelreleaseid
                        }
                      />
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div style={{display: "flex", justifyContent: "end", marginTop: 15}}>
              <CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Save</CustomButton>
              <CustomButton onClick={() => handleOpen(false)} disabled={loading}>Close</CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}


EditImageDetailModalNew.defaultProps = {
  open: false,
  handleOpen: ()=>{}, 
  data: null, 
  getListing: ()=>{}, 
  type:'allimage'
}