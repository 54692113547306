import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { DATA_LIMIT } from '../../data/constants';
import { addSubscriptionPlan, subscriptionListings } from '../../Services/SubscriptionApis';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

const limit = DATA_LIMIT;

function AddSubscription({ open, handleOpen, setPage, handleLast }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    console.log(values);
    let resp = await addSubscriptionPlan(values)
    if (resp) {
      let payloadForLogs = {
        action: action?.add,
        module: modulesNames?.subscriptions,
        description: values?.pack_type=='1'?logsMessagesList?.subscription?.susbscriptionAddStandard:logsMessagesList?.subscription?.susbscriptionAddSpecial,
      };
      await adminAddLogs(payloadForLogs);
      resetForm({
        plan_name: "",
        maximum_downloads: "",
        plan_validity: "",
        plan_price: "",
        plan_type: "SMALL",
        plan_description: "",
        status: "",
        coupon_name: "",
        coupon_amount: "",
        discount_percentage: "",
        shot: "",
        pack_type: "1",
        per_image: "",
        sort: ""
      })
      setPage(1)
      dispatch(subscriptionListings({
        page: String(1),
        limit: limit
      }))
      handleLast();
      handleOpen(false)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      plan_name: "",
      maximum_downloads: "",
      plan_validity: "",
      plan_price: "",
      plan_type: "SMALL",
      plan_description: "",
      status: "1",
      coupon_name: "",
      coupon_amount: "",
      discount_percentage: "0",
      shot: "1",
      pack_type: "1",
      per_image: "",
      sort: ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      plan_name: Yup.string().required('Plan name is required.'),
      maximum_downloads: Yup.string().required('Max download is required.').matches(/^[0-9]+$/, "Must be only digits"),
      plan_validity: Yup.string().required('Plan validity is required.').matches(/^[0-9]+$/, "Must be only digits"),
      plan_price: Yup.string().required('Plan price is required.').matches(/^[0-9]+$/, "Must be only digits"),
      plan_type: Yup.string().required('Plan type is required.'),
      plan_description: Yup.string().required('Plan description is required.'),
      coupon_name: Yup.string(),
      coupon_amount: Yup.string().matches(/^[0-9]+$/, "Must be only digits").when("plan_price", (plan_price, schema) => {
        return schema.test({
          test: (coupon_amount) => {
            if (!coupon_amount) return true;
            return +coupon_amount < +plan_price;
          },
          message: "Coupon Amount should not greater then Plan Price",
        });
      }),
      discount_percentage: Yup.string().matches(/^100(\.0{0,2})? *%?$|^\d{1,2}(\.\d{1,2})? *%?$/, "Must be digit").max(100, "should be Less than 100"),
      pack_type: Yup.string().required('Pack Type is required.'),
      per_image: Yup.string().required('Per Image is required.').matches(/^[0-9]+$/, "Must be only digits").min(0)
        .when("plan_price", (plan_price, schema) => {
          return schema.test({
            test: (per_image) => {
              if (!per_image) return true;
              return +per_image < +plan_price;
            },
            message: "Per Image Amount should not greater then Plan Price",
          });
        }),
      sort: Yup.string().required('sort is required').matches(/^[0-9]+$/, "Must be only digits"),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Add Subscription
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="plan_name"
                  label="Plan Name"
                  placeholder="Plan Name"
                  value={formik.values.plan_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.plan_name && Boolean(formik.errors.plan_name)}
                  helperText={formik.touched.plan_name && formik.errors.plan_name}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="plan_type"
                  label="Plan Type"
                  placeholder="Plan Type"
                  value={formik.values.plan_type}
                  select
                  SelectProps={{
                    native: true
                  }}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.plan_type && Boolean(formik.errors.plan_type)}
                  helperText={formik.touched.plan_type && formik.errors.plan_type}
                >
                  <option key={'WEB'} value={"WEB"}>WEB</option>
                  <option key={'LARGE'} value={"LARGE"}>LARGE</option>
                  <option key={'MEDIUM'} value={"MEDIUM"}>MEDIUM</option>
                  <option key={'SMALL'} value={"SMALL"}>SMALL</option>
                </CustomInput>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="plan_validity"
                  label="Plan Validity"
                  placeholder="Plan Validity"
                  value={formik.values.plan_validity}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.plan_validity && Boolean(formik.errors.plan_validity)}
                  helperText={formik.touched.plan_validity && formik.errors.plan_validity}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="plan_description"
                  label="Plan Description"
                  placeholder="Plan Description"
                  value={formik.values.plan_description}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.plan_description && Boolean(formik.errors.plan_description)}
                  helperText={formik.touched.plan_description && formik.errors.plan_description}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="plan_price"
                  label="Plan Price"
                  placeholder="Plan Price"
                  value={formik.values.plan_price}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.plan_price && Boolean(formik.errors.plan_price)}
                  helperText={formik.touched.plan_price && formik.errors.plan_price}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="per_image"
                  label="Per Image"
                  placeholder="Per Image"
                  value={formik.values.per_image}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.per_image && Boolean(formik.errors.per_image)}
                  helperText={formik.touched.per_image && formik.errors.per_image}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="maximum_downloads"
                  label="Maximum Downloads"
                  placeholder="Maximum Downloads"
                  value={formik.values.maximum_downloads}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.maximum_downloads && Boolean(formik.errors.maximum_downloads)}
                  helperText={formik.touched.maximum_downloads && formik.errors.maximum_downloads}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="status"
                  label="Status"
                  placeholder="Status"
                  value={formik.values.status}
                  select
                  SelectProps={{
                    native: true
                  }}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.status && Boolean(formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                >
                  <option key={'0'} value={"0"}>Inactive</option>
                  <option key={'1'} value={"1"}>Active</option>
                </CustomInput>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="coupon_name"
                  label="Coupon Name"
                  placeholder="Coupon Name"
                  value={formik.values.coupon_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.coupon_name && Boolean(formik.errors.coupon_name)}
                  helperText={formik.touched.coupon_name && formik.errors.coupon_name}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="sort"
                  label="Sort"
                  placeholder="Sort"
                  value={formik.values.sort}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.sort && Boolean(formik.errors.sort)}
                  helperText={formik.touched.sort && formik.errors.sort}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="coupon_amount"
                  label="Coupon Amount"
                  placeholder="Coupon Amount"
                  value={formik.values.coupon_amount}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.coupon_amount && Boolean(formik.errors.coupon_amount)}
                  helperText={formik.touched.coupon_amount && formik.errors.coupon_amount}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="discount_percentage"
                  label="Discount Percentage"
                  placeholder="Discount Percentage"
                  value={formik.values.discount_percentage}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.discount_percentage && Boolean(formik.errors.discount_percentage)}
                  helperText={formik.touched.discount_percentage && formik.errors.discount_percentage}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="shot"
                  label="Shot"
                  placeholder="Shot"
                  value={formik.values.shot}
                  select
                  SelectProps={{
                    native: true
                  }}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.shot && Boolean(formik.errors.shot)}
                  helperText={formik.touched.shot && formik.errors.shot}
                >
                  <option key={'0'} value={"0"}>0</option>
                  <option key={'1'} value={"1"}>1</option>
                </CustomInput>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="pack_type"
                  label="Pack Type"
                  placeholder="Pack Type"
                  value={formik.values.pack_type}
                  select
                  SelectProps={{
                    native: true
                  }}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.pack_type && Boolean(formik.errors.pack_type)}
                  helperText={formik.touched.pack_type && formik.errors.pack_type}
                >
                  <option key={'1'} value={"1"}>Standard</option>
                  <option key={'2'} value={"2"}>Special</option>
                </CustomInput>
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Add
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    plan_name: "",
                    maximum_downloads: "",
                    plan_validity: "",
                    plan_price: "",
                    plan_type: "",
                    plan_description: "",
                    status: "",
                    coupon_name: "",
                    coupon_amount: "",
                    discount_percentage: "",
                    shot: "",
                    pack_type: '',
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddSubscription;
