import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';
import { DATA_LIMIT } from '../../data/constants';
import { formatDate, getObjectSubset } from '../../lib/helper';
import {
  SingleUserCartList,
  addToCartFromAdmin,
  deleteFromCartlist,
  userCartsApi,
} from '../../Services/userApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import ConfirmationModal from '../Modals/ConfirmationModal';
import MatTable from '../Tables/MatTable';
import { useNavigate, useParams } from 'react-router-dom';
import { userItemsSelector } from '../../redux/user';
import AddToCartWishListModal from '../Modals/AddToCartWishListModal';
import { getNewParameters, getParameters } from '../../lib/helper';
import { crypt } from '../../lib/helper';
import { toast } from 'react-toastify';
import { SearchInCart } from '../../data/userData';
import AllFilters from '../Order/AllFilters';
import { adminAddLogs } from '../../Services/commonService';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'Content',
    id: 'image_url',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', width: '100%', textAlign: 'left' }}
      >
        {' '}
        <img
          style={{ maxWidth: 80, maxHeight: 200, borderRadius: 8 }}
          src={`${columnData}`}
        />
      </span>
    ),
  },
  {
    label: 'ID',
    id: 'shoping_cart_id',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          cursor: 'pointer',
        }}
      >
        {columnData}
      </span>
    ),
  },
  {
    label: 'Name',
    id: 'image_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Type',
    id: 'image_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Price(INR)',
    id: 'price',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Rights',
    id: 'rights',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Dimension',
    id: 'dimension',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Created at',
    id: 'created_at',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {formatDate(columnData) || 'N/A'}
      </span>
    ),
  },
];

function UserCartData({ cartListAllData, cartValue, rightSection }) {
  const [tableListing, setTableListing] = useState([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [addToModalOpen, setAddToModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const { userId } = useParams();
  const [from, setFrom] = useState(0);
  const navigate = useNavigate();
  const [allParams, setAllParams] = useState(getParameters());
  const [initialData, setInitialData] = useState([]);
  const { singleUserCartDetail } = useSelector(userItemsSelector);

  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'Search By',
      id: 'search_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: SearchInCart,
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          // newState[index].value = e.target.value;
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);
  useEffect(() => {
    // getUserCartList();
    getCartListing();
  }, []);

  const getCartListing = async (params = {}, setPageState = () => {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...params,
    };
    dispatch(userCartsApi(userId, payload));
    if (setPageState) {
      setPageState(Number(payload.page));
    }
  };

  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }
    getCartListing();
  }, [allParams]);

  const getUserCartList = () => {
    let listPayload = {
      page: String(1),
      limit: limit - 5,
    };
    dispatch(userCartsApi(userId, listPayload));
  };

  useEffect(() => {
    if (singleUserCartDetail?.items?.wishlist?.rows?.length >= 0) {
      let data = singleUserCartDetail?.items?.wishlist?.rows?.map((item) => {
        return {
          image_url: `${ApiConfig.BASE_IMAGE_URL}${item?.shoot_id}/${item?.rank}-${item?.image_name}.jpg`,
          shoping_cart_id: item.shoping_cart_id,
          image_name: item.image_name,
          image_type: item.image_type,
          price: item.price,
          rights: item.rights,
          dimension: item.dimension,
          created_at: item.created_at,
          maximum_downloads: item.maximum_downloads,
          discount_percentage: item.discount_percentage,
        };
      });
      setTableListing(data);
    }
  }, [singleUserCartDetail?.items]);

  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  };

  const handleAddToCart = async (item, index, length, values, state, price) => {
    if (item?.quality) {
      let resp;
      if (values?.is_star == '1') {
        resp = await addToCartFromAdmin({
          user_id: userId,
          image_id: item.sno,
          image_type: item.quality,
          type:
            item?.type === 'image' ? '1' : item?.type === 'video' ? '2' : '',
          country: values?.is_star == '1' ? values.country : '',
          duration: values.duration,
          industry: values.industry,
          state: values.state?.length
            ? values.state?.map((i) => `${i.label}`).join(',')
            : '',
          is_star: values.is_star,
          license: values.license,
          price: price,
        });
        let payloadForLogs = {
          action: action?.AddToCart,
          module: modulesNames?.users,
          description: logsMessagesList?.users?.userCart,
        };
        adminAddLogs(payloadForLogs);
      } else {
        resp = await addToCartFromAdmin({
          user_id: userId,
          image_id: item.sno,
          image_type: item.quality,
          type:
            item?.type === 'image' ? '1' : item?.type === 'video' ? '2' : '',
        });
        let payloadForLogs = {
          action: action?.AddToCart,
          module: modulesNames?.users,
          description: logsMessagesList?.users?.userCart,
        };
        adminAddLogs(payloadForLogs);
      }
      if (resp) {
        dispatch(userCartsApi(userId, { page: 1, limit: 5 }));
        if (length == 1) {
          handleAddToModalOpen(false, 0);
        }
        return true;
      }
      return false;
    } else {
      toast.error(`Please select image quality for image ${item.image_name}`);
      return false;
    }
  };

  const handleAddToWishlist = async (item, index, length, states, values) => {
    if (item.sno) {
      let resp;
      if (values?.is_star == '1') {
        resp = await addToWishlistFromAdmin({
          user_id: userId,
          image_id: item.sno,
          type:
            item?.type === 'image' ? '1' : item?.type === 'video' ? '2' : '',
          country: values?.is_star == '1' ? values.country : '',
          duration: values.duration,
          industry: values.industry,
          state: values.states?.length
            ? values.states?.map((i) => `${i.label}`).join(',')
            : '',
          is_star: values.is_star,
          license: values.license,
          price: price,
        });
      } else {
        resp = await addToWishlistFromAdmin({
          user_id: userId,
          image_id: item.sno,
          image_type: item.quality,
          type:
            item?.type === 'image' ? '1' : item?.type === 'video' ? '2' : '',
        });
      }
      if (resp) {
        dispatch(userWishlistApi(userId, { page: 1, limit: 5 }));
        if (length == 1) {
          handleAddToModalOpen(false, 0);
        }
        return true;
      }
      return false;
    }
  };

  const handleAddToModalOpen = (value, from) => {
    setAddToModalOpen(value);
    setFrom(from);
    if (value == false) {
      setInitialData([]);
    }
  };

  const handleDelete = async (value) => {
    try {
      if (value && deleteData) {
        const resp = await deleteFromCartlist({
          user_id: userId,
          shoping_cart_id: deleteData,
        });
        let payloadForLogs = {
          action: action?.DeleteFromCart,
          module: modulesNames?.users,
          description: logsMessagesList?.users?.userDeleteFromCart,
        };
        adminAddLogs(payloadForLogs);
        if (resp) { 
          dispatch(
            userCartsApi(userId, {
              page: 1,
              limit: 5,
            }),
          );
          setPage(1);
        }
      } else {
        setDeleteData(null);
      }
    } catch (e) {}
  };

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            onClick={async () => {
              handleCofirmModalOpen(true);
              setDeleteData(data.shoping_cart_id);
            }}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonInfo.main',
            }}
          >
            Delete
          </CustomButton>
        </div>
      </>
    );
  };

  const handleAddNewItem = () => {
    handleAddToModalOpen(true, 0);
  };

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getOrderListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };
  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[0].value = '';
      newState[1].value = '';
      return newState;
    });
  };

  const handleAdditionButton = () => {
    if (userId) {
      if (singleUserCartDetail.items?.wishlist?.rows?.length) {
        navigate({
          pathname: `/checkout`,

          search: `?userId=${crypt('user_Id', userId)}`,
        });
      } else {
        toast.error('Please add images/videos to cart first.');
      }
    } else {
      toast.warning('Fetching data please wait for sometime.');
    }
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: (theme) => theme.spacing(3),
          py: (theme) => theme.spacing(2),
        }}
      >
        {rightSection ? rightSection() : null}
      </Box>
      <MatTable
        showCheckbox={false}
        columns={column}
        data={tableListing}
        loading={singleUserCartDetail?.loading}
        action={actionBody}
        page={page}
        perPage={limit}
        total={singleUserCartDetail?.items?.wishlist?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getCartListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getCartListing({
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        pagination={true}
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
            hasAddNew={true}
            handleAddNewModal={handleAddNewItem}
            addText={'Add to Cart'}
            handleAddtionalButton={handleAdditionButton}
            addionalButton={true}
            addtionalText="Checkout"
          />
        )}
        disableSearch
      />
      {confirmDelete && (
        <ConfirmationModal
          open={confirmDelete}
          handleConfirm={handleDelete}
          setOpen={handleCofirmModalOpen}
        />
      )}
      {addToModalOpen && (
        <AddToCartWishListModal
          open={addToModalOpen}
          handleOpen={handleAddToModalOpen}
          from={from}
          heading={from == 1 ? 'Add To WishList' : 'Add To Cart'}
          initialData={initialData}
          handleAddFunctionality={
            from == 1 ? handleAddToWishlist : handleAddToCart
          }
          userId={userId}
        />
      )}
    </>
  );
}

export default UserCartData;
