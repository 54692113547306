import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react';
import { sendPromotionalMail } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import Divder from '../CommonComp/Divder';
import TextEditor from '../CommonComp/TextEditor/TextEditor';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

function EmailPage({email}) {
  const [loading, setLoading] = useState(false)
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let resp = await sendPromotionalMail(values);
    if (resp) {
      let payloadForLogs = {
        action: action?.emailSent,
        module: modulesNames?.settings,
        description: logsMessagesList?.setting?.promotionalMailSent,
      };
      await adminAddLogs(payloadForLogs);
      resetForm({
        mail_to: '',
        mail_cc: '',
        subject: "",
        content: '', 
      });
      setLoading(false);
    }
    // if(resp){
    //   navigate(-1)
    // }
    setLoading(false);
  };
  const formik = useFormik({
    initialValues: {
      mail_to: '',
      mail_cc: '',
      subject: "",
      content: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      mail_to:Yup.string()

      .required('Emails are required')
  
      .test('valid-emails', 'Invalid email format', (value) => {
  
        if (!value) return true; // Allow empty value
  
        const emails = value.split(',');
  
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  
        return emails.every((email) => emailRegex.test(email.trim()));
  
      }),
      subject: Yup.string().required('Please enter subject'),
      content: Yup.string().required('Please enter content'),
    }),
    onSubmit: handleConfirm,
  });

  const handleChange = (content)=>{
    if(content == '<p><br></p>'){
      formik.setFieldValue('content', '')
    }else{
      formik.setFieldValue('content', content)
    }
  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <CustomBox padding={3}>
          <Grid container spacing={2}>
           
            <Grid item xs={12} md={6}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="mail_to"
                label="Mail to"
                placeholder="Mail to"
                value={formik.values.mail_to}
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.mail_to &&Boolean(formik.errors.mail_to)}
                helperText={formik.touched.mail_to && formik.errors.mail_to}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="mail_cc"
                label="Mail cc"
                placeholder="Mail cc"
                value={formik.values.mail_cc}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="subject"
                label="Subject"
                placeholder="Subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.subject &&Boolean(formik.errors.subject)}
                helperText={formik.touched.subject && formik.errors.subject}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h5' style={{marginBottom: 8, paddingLeft: 3}}>Mail Body</Typography>
              <TextEditor content={formik.values.content} handleChange={handleChange} 
                error={formik.touched.content && Boolean(formik.errors.content)}
                helperText={formik.touched.content && formik.errors.content}
              />
            </Grid>
            <Grid item xs={12} style={{textAlign: "end"}}>
              <CustomButton type="submit" loading={loading} color={'buttonPrimary'} sx={{ padding: '8px 20px' }}>
                Send
              </CustomButton>
            </Grid>
          </Grid>
        </CustomBox>
        <Divder spacing={1} />
        
      </form>
    </div>
  );
}

export default EmailPage;
