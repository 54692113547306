import React, { useEffect, useRef, useState } from 'react';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import { LeftArrow } from '../../Asset/svg';
import { useTheme } from '@mui/material';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import UserDetailPage from '../../Components/User/UserDetailPage';
import { useDispatch } from 'react-redux';
import { clearSingleUserDetails } from '../../redux/user';
import { decrypt } from '../../lib/helper';
import ApiConfig from '../../config/ApiConfig';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Grid, Typography } from '@mui/material'
import CustomImage from '../../Components/CommonComp/CustomImage/CustomImage';
import CustomAsyncSelect from '../../Components/CommonComp/CustomInput/CustomAsyncSelect';
import ConfirmationModal from '../../Components/Modals/ConfirmationModal';
import { getListOfImageKeywords, getVisibleKeywordListingNoRedux } from '../../Services/keywordsApis';
import CheckboxField from '../../Components/CommonComp/CustomInput/CheckboxField';
import { linkKeywordToImageNew } from '../../Services/imageApis';
import { adminAddLogs, getImageInfo } from '../../Services/commonService';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';

function ImageKeywordDetail() {
  const theme = useTheme();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { contributorId } = useParams();
  const { imageName } = useParams();
  const { imageId } = useParams();
  const [imageDetail, setImageDetail] = useState(null)
  const image_id = decrypt(ApiConfig.REACT_APP_SALT + 'image_name', imageName)
  const [keywordForTheChoosenImage, setKeywordForTheChoosenImage] = useState([]);
  const [groupData, setGroupData] = useState(false);
  const [maintainKeywordCheckbox, setManintainKeywordCheckbox] = useState([]);
  const [confirmKeyword, setConfirmKeyword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newKeyword, setNewKeyword] = useState([]);
  const imageRef=useRef(null);

  useEffect(() => {
    getAllKeywordsForImage();
    handleDetail(imageId);
  }, [imageId])


  const getAllKeywordsForImage = async () => {
    let data = await getListOfImageKeywords(imageId);
    if (data?.status == 200) {
      setKeywordForTheChoosenImage(data?.data);
      let newArr = data?.data?.map((a) => ({ label: a, checked: true }));
      setManintainKeywordCheckbox(newArr);
    }
  }

  const handleDetail = async (imageName) => {
    if (imageName) {
      let resp = await getImageInfo({ name: imageName, type: "1" })
      if (resp) {
        setImageDetail(resp);
      } else {
        setImageDetail(null)
      }
    }
  }

  const handleData = (data, index) => {
    //let newArr=[...maintainKeywordCheckbox];
    //  let newArr=[...newKeyword];
    let newArr = [];
    if (data) {
      data?.filter(a => {
        let mergedArr = [...maintainKeywordCheckbox];
        let index = mergedArr?.findIndex(item => item?.label === a?.label);
        if (index < 0) {
          formik.setFieldValue('keywords', data?.map(a => a?.label)?.join(","));
          newArr?.push({ label: a?.label, checked: true })
        }
      });
      setNewKeyword(newArr);
      setGroupData(data);
    } else {
      formik.setFieldValue('keywords', formik?.values?.keywords ? formik?.values?.keywords : '');
      let mergedArr = [...maintainKeywordCheckbox];
      let index = mergedArr?.findIndex(item => item?.label === a?.label);
      if (index < 0) {
        data?.filter(a => newArr?.push({ label: a?.label, checked: true }));
        setNewKeyword(newArr);
      }
      setGroupData(data);
    }

    // setManintainKeywordCheckbox(newArr);

  }

  const formik = useFormik({
    initialValues: {
      keywords: "",
    },
    enableReinitialize: true,
    validationSchema: Yup
      .object({
        keywords: Yup.string().required('Visible keyword id is required.'),
      }),
    // onSubmit: handleConfirm,
  });

  const getVisibleKeywordListing = async (inputValue) => {
    if (inputValue && inputValue.length > 2) {
      let data = await getVisibleKeywordListingNoRedux({
        search: inputValue,
        page: "1",
        limit: '100'
      })
      if (data) {
        let updatedData = data?.map((item, index) => {
          return {
            value: item.vid,
            label: item.visible_keyword
          }
        })
        return updatedData || []
      }
      return []
    }
  }

  const handleCheckBox = (e, value, index, type) => {
    if (type === 'exist') {
      let arrayKey = [...maintainKeywordCheckbox];
      arrayKey[index]['checked'] = e.target.checked;
      setManintainKeywordCheckbox(arrayKey);
    }
    else if (type === 'new') {
      let arrayKey = [...newKeyword];
      arrayKey[index]['checked'] = e.target.checked;
      setNewKeyword(arrayKey);
    }
  }

  const handleSaveButton = (e) => {
    e.preventDefault();
    setConfirmKeyword(true);

  }
  const handleConfirmImageKeyword = async (value) => {
    setLoading(true)
    if (value) {
      let newKeywordArray = [...maintainKeywordCheckbox, ...newKeyword]
      let object = { image_name: imageId, keywords: newKeywordArray?.filter((a => a?.checked))?.map(a => a?.label)?.join(",") }
      let resp = await linkKeywordToImageNew(object);
      if (resp) {
        let payloadForLogs = {
          action: action?.add_keyword,
          module: modulesNames?.images,
          description: logsMessagesList?.image?.imageKeywordUpdated,
        };
        await adminAddLogs(payloadForLogs);
        getAllKeywordsForImage();
        setGroupData(false);
        setNewKeyword([]);
      }

      //  if (resp) {
      //    resetForm({  //      keywords: "",
      //      image_name: "",
      //    })
      // }
      setLoading(false);
    }
  }
  const handleCofirmModalOpen = (value) => {
    setConfirmKeyword(false);
    setLoading(false);
  }

  return (
    <Box
      component="main"
      spacing={2}
      sx={(theme) => {
        return {
          flexGrow: 1,

        };
      }}
    >

      <CustomBox>
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            cursor: 'pointer'
          }} onClick={() => {
            navigate(-1)
            dispatch(clearSingleUserDetails())
          }}>
            <LeftArrow fill={theme.palette.textButton.main} />
            <Typography variant='h5' sx={{
              marginLeft: theme.spacing(1),
              color: theme.palette.textButton.main
            }}>Back</Typography>
          </Box>
          <Typography variant='h5' sx={{ marginLeft: (theme) => theme.spacing(1) }}>Keyword  Details</Typography>
          <div style={{ width: 100 }}></div>

        </Box>
      </CustomBox>
      <Divder spacing={1} />

      <div>
        <CustomBox padding={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CustomImage
                src={`${ApiConfig.BASE_IMAGE_URL}${imageDetail?.shootid}/${imageDetail?.rank}-${imageDetail?.Name}.jpg`}
                style={{
                  maxWidth: 400,
                  marginTop: 5,
                  borderRadius: 10,
                  width: '100%',
                }}
              />
              <div>
                <Typography variant='h5' component={"span"}>Name:</Typography> <Typography variant='h5' component={"span"}>{imageDetail?.Name}</Typography>
              </div>
              <div>
                <Typography variant='h5' component={"span"}>Rank:</Typography> <Typography variant='h5' component={"span"}>{imageDetail?.rank}</Typography>
              </div>
              <div>
                <Typography variant='h5' component={"span"}>Contributer:</Typography> <Typography variant='h5' component={"span"}>{imageDetail?.contributor ? imageDetail?.contributor : '-'}</Typography>
              </div>
            </Grid>
            <Grid item xs={8}>
              <Box pl={3}>
                <Box sx={{ maxWidth: 400 }}>
                  <CustomAsyncSelect
                    promiseOptions={getVisibleKeywordListing}
                    closeMenuOnSelect={false}
                    controlStyle={{
                      padding: 10
                    }}
                    // isMulti={false}
                    width={'100%'}
                    dropDownZIndex={2000}
                    cacheOptions={true}
                    value={groupData}
                    handleChange={(e) => {
                      handleData(e);
                      imageRef?.current?.blur();
                    }}
                    ref={imageRef}
                    error={formik.touched.keywords && Boolean(formik.errors.keywords)}
                    helperText={formik.touched.keywords && formik.errors.keywords}
                  />
                </Box>
                <>
                  <Typography variant='h5' mt={2} my={2}>Image Keywords</Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', my: 2 }} >
                    {maintainKeywordCheckbox?.map((img, index) => {
                      return <Box key={index.toString()} sx={{}}>
                        <CheckboxField
                          key={index}
                          labelPlacement={'end'}
                          subLableText={img?.label}
                          value={img?.checked}
                          handleChange={(e) => { handleCheckBox(e, img?.label, index, 'exist') }}
                        />
                        {/* <span >{img.label}</span> */}
                      </Box>
                    })}
                  </Box>
                </>

                {newKeyword?.length > 0 && <>
                  <Typography variant='h5' mt={5}>New  Keywords</Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', my: 2 }} >
                    {newKeyword?.map((img, index) => {
                      return <Box key={index.toString()} sx={{}}>
                        <CheckboxField
                          key={index}
                          labelPlacement={'end'}
                          subLableText={img?.label}
                          value={img?.checked}
                          handleChange={(e) => { handleCheckBox(e, img?.label, index, 'new') }}
                        />
                        {/* <span >{img.label}</span> */}
                      </Box>
                    })}
                  </Box>
                </>}

                <div style={{ textAlign: 'end' }}>
                  <CustomButton onClick={handleSaveButton} loading={loading} sx={{ bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}>Save Keywords</CustomButton>
                </div>
              </Box>
            </Grid>

          </Grid>

        </CustomBox>
        {<ConfirmationModal open={confirmKeyword} handleConfirm={handleConfirmImageKeyword} setOpen={handleCofirmModalOpen} />}

      </div>

      {/* <EditContributorInfo /> */}
      {/* <Divder spacing={1.2} />
      <ContributorBankDetail />
      <Divder spacing={1.2} />
      <PaymentHistory  />
      <Divder spacing={1.2} />
      <PaymentMade/> */}
    </Box>
  );
}

export default ImageKeywordDetail;

