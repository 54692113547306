import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import {
  contributorUploadImagesListing,
  sendImagesEMailtocontributor,
} from '../../Services/ContributorApis';
import { toast } from 'react-toastify';
import {
  action,
  logsMessagesList,
  modulesNames,
} from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

const columns = [
  { id: 'approved', label: 'Approved Images', minWidth: 170 },
  { id: 'rejected', label: 'Rejected Images', minWidth: 170 },
  { id: 'pending', label: 'Pending Images', minWidth: 170 },
];

export default function SendEmailModal({
  open,
  setOpen,
  folder_name,
  contributor_id,
  folderLimit,
}) {
  const [rows, setRows] = useState([]);
  const page = 0;
  const rowsPerPage = 100;
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    if (open) {
      fetchImages();
    }
  }, [open]);

  function createData(approved, rejected, pending) {
    return { approved, rejected, pending };
  }

  const fetchImages = async () => {
    try {
      const response = await contributorUploadImagesListing({
        page: page + 1,
        limit: folderLimit,
        folder_name,
      });
      if (response?.data) {
        const approvedImages = [];
        const rejectedImages = [];
        const pendingImages = [];

        response?.data?.data?.rows?.forEach((image) => {
          if (image.status === 1) {
            approvedImages?.push(image.image_name);
          } else if (image.status === 2) {
            rejectedImages?.push(image?.image_name);
          } else if (image?.status === 0) {
            pendingImages?.push(image?.image_name);
          }
        });

        const newRows = [];
        const maxLength = Math?.max(
          approvedImages?.length,
          rejectedImages?.length,
          pendingImages?.length,
        );

        if (
          approvedImages?.length === 0 &&
          rejectedImages?.length === 0 &&
          pendingImages?.length === 0
        ) {
          newRows?.push(createData('No Images', 'No Images', 'No Images'));
        } else {
          for (let i = 0; i < maxLength; i++) {
            newRows?.push(
              createData(
                approvedImages[i] || (i === 0 ? 'No Approved Images' : ''),
                rejectedImages[i] || (i === 0 ? 'No Rejected Images' : ''),
                pendingImages[i] || (i === 0 ? 'No Pending Images' : ''),
              ),
            );
          }
        }

        setRows(newRows);
      } else {
        toast.error('No images found.');
      }
    } catch (error) {
      toast.error('Failed to fetch images.');
      console.error(error);
    }
  };

  const handleSend = async () => {
    setDisabledButton(true);
    try {
      const payload = {
        folder_name: folder_name,
        contributor_id: contributor_id,
      };
      const response = await sendImagesEMailtocontributor(payload);
      if (response) {
        let payloadForLogs = {
          action: action?.emailSent,
          module: modulesNames?.contributors,
          description:
            logsMessagesList?.contributor?.contributorMailSentForImages,
        };
        await adminAddLogs(payloadForLogs);
      }
      setDisabledButton(false);
      if (response?.status === true) {
        toast.success('Email sent successfully!');
      } else if (response?.status === false) {
        toast.error('Failed to send email');
      }
    } catch (error) {
      toast.error('An error occurred while sending the email.');
      setDisabledButton(false);
      console.error(error);
    } finally {
      setOpen(false);
      setDisabledButton(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        '& .MuiDialog-paper': {
          width: '1000px',
          maxWidth: '1000px',
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle>Send Email to Contributor</DialogTitle>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440, overflow: 'auto' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={rowIndex}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </DialogContent>
      <DialogActions>
        <CustomButton
          disabled={disabledButton}
          onClick={handleSend}
          sx={{
            bgcolor: 'buttonPrimary.main',
            color: 'buttonPrimary.contrastText',
          }}
        >
          Send Email
        </CustomButton>
        <CustomButton onClick={() => setOpen(false)}>Cancel</CustomButton>
      </DialogActions>
    </Dialog>
  );
}

SendEmailModal.defaultProps = {
  open: false,
  setOpen: () => {},
  folder_name: '',
  contributor_id: null,
  folderLimit: null,
};
