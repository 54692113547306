import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import { Delete, Edit } from '@material-ui/icons';
import ConfirmationModal from '../Modals/ConfirmationModal';
import { modelItemsSelector } from '../../redux/model';
import { ModelListings, deleteModel } from '../../Services/modelApi';
import AddEditModel from '../Modals/AddEditModel';
import { Typography } from '@mui/material';
import UploadModelCsvModal from '../Modals/UploadModelCsvModal';
import AllFilters from '../Order/AllFilters';
import { SearchByModel } from '../../data/modelData';

const limit = 10;

const column = [
  {
    label: 'SNO',
    id: 'sno',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>
    )
  },
  {
    label: 'Model ID',
    id: 'modelid',
    format: (columnData, rowIndex, formatCallback, func, errors, row) => (
      <Link className='textDecoration-none link-text' to={`${columnData}/${row?.Name}`}>
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
        >
          {columnData}
        </Typography>
      </Link>
    )
  },

  {
    label: 'Name',
    id: 'Name',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Mobile',
    id: 'mobile',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 500, width: "max-content" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Release ID',
    id: 'releaseid',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 500, width: "max-content" }}>{columnData || 'N/A'}</span>)
  },
];

function ModelList() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, modelList } = useSelector(modelItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [selectedData, setSelectedData] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openModelUpload, setOpenModelUpload] = useState(false);
  const [modelDeleteData, setModelDeleteData] = useState();


  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'Search By',
      id: 'search_by',
      type: 'select',
      linkedWith: null,
      value: allParams?.search_by || '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[1].value = "";
          return newState;
        });
      },
      style: {},
      dataArr: SearchByModel,
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: allParams?.search || '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          // newState[index].value = e.target.value;
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },

  ]);

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };
  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[0].value = '';
      newState[1].value = '';
      // newState[5].isVisible = true;
      return newState;
    });
  };
  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      order_status: String(1),
      ...allParams,
      ...params
    }
    dispatch(ModelListings(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  //making data format for table input
  useEffect(() => {
    if (modelList?.items?.data) {
      setTableListing(modelList?.items?.data)
    }

  }, [modelList?.items]);

  const handleModelUpload = (value) => {
    setOpenModelUpload(value);
  }

  const handleAdd = (value = false) => {
    setAddModel(value);
  }

  const handleEdit = (value = false) => {
    setEditModel(value)
  }

  const handleConfirmDeleteIndustry = async (value) => {
    if (value && modelDeleteData) {
      const resp = await deleteModel({ model_id: modelDeleteData });
      if (resp) {
        getListing({ page: '1' })
        setPage(1)
      }
      setConfirmDelete(false);
    } else {
      setSelectedData(null)
    }
  }

  const handleCofirmModalOpen = (value) => {
    setSelectedData(value);
    setConfirmDelete(value)
  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <CustomButton className="noMinWidth buttonDense" onClick={() => {
            setSelectedData(data)
            handleEdit(true)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Edit /></CustomButton>
          <CustomButton className="noMinWidth buttonDense" onClick={() => {
            handleCofirmModalOpen(true)
            setConfirmDelete(true);
            setModelDeleteData(data?.sno)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Delete /></CustomButton>
        </div>
      </>
    );
  };


  const handleAddNewItem = () => {
    handleAdd(true)
  }

  const handleAddCSV = () => {
    handleModelUpload(true)
  }

  return (
    <>
      <MatTable
        loading={modelList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={modelList?.items?.totalCount || 0}
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
            hasAddNew={true}
            handleAddNewModal={handleAddNewItem}
            hasUploadCSV={true}
            handleAddCSV={handleAddCSV}

          />
        )}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        disableSearch
      />
      {addModel && <AddEditModel open={addModel} handleOpen={handleAdd} setPage={setPage} handleLast={getListing} type={'add'} />}
      {editModel && <AddEditModel open={editModel} handleOpen={handleEdit} setPage={setPage} handleLast={getListing} type={'edit'} data={selectedData} />}
      {confirmDelete && (
        <ConfirmationModal
          open={confirmDelete}
          handleConfirm={handleConfirmDeleteIndustry}
          setOpen={handleCofirmModalOpen}
        />
      )}
      {openModelUpload && <UploadModelCsvModal open={openModelUpload} handleOpen={handleModelUpload} getListing={getListing} />}

    </>
  );
}

export default ModelList;
