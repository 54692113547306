import { Search } from '@material-ui/icons';
import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';
import { orderDetalData, Select_Sez } from '../../data/OrderData';
import {
  orderListingItemsSelector,
  setOrderDetails,
} from '../../redux/orderListing';
import { confirmOrder, orderDetailsApi } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import CustomCheckbox from '../CommonComp/CustomInput/CheckboxField';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import Divder from '../CommonComp/Divder';
import MatTable from '../Tables/MatTable';
import {
  crypt,
  currencyConversion,
  decrypt,
  formatDate,
  getDataFromAppSetting,
  humanize,
  validDate,
} from '../../lib/helper';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { commonItemsSelector } from '../../redux/common';
import { adminAddLogs, appSettingApi, companyByNameNoRedux, getCountry, getState } from '../../Services/commonService';
import { loginItemsSelector } from '../../redux/login';
import CustomLoading from '../CommonComp/CustomLoading/CustomLoading';
import OrderDetailsInput from './OrderDetailsInput';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';

const column = [
  {
    label: '',
    id: 'image',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <>
        <CustomImage
          style={{ maxWidth: 150, borderRadius: 8 }}
          src={`${ApiConfig.BASE_IMAGE_URL}${columnData?.shootid}/${columnData?.rank
            }-${row?.type === '1' ? columnData?.Name : columnData?.video_name
            }.jpg`}
        />
      </>
    ),
  },
  {
    label: 'Item ID',
    id: 'image_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Type',
    id: 'image_type',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {row.image_type_up && row.image_type_up !== null ? row.image_type_up + " to " + columnData : columnData || 'N/A'}

      </span>
    ),
  },
  {
    label: 'Rights',
    id: 'right',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <span
        style={{
          display: 'inline-block',
          width: 'max-content',
          maxWidth: 300,
          wordBreak: 'break-all',
        }}
      >
        {row?.is_star === '1' ? (
          <>
            <div>{row?.right || 'Non-Exclusive'}</div>
            {/* <div style={{ marginTop: "5px" }}>
              License: {row?.license}
            </div> */}
            <div style={{ marginTop: '5px' }}>Industry: {row?.industry}</div>
            <div style={{ marginTop: '5px' }}>
              {/* Territory: {String(row?.state).length ? row?.state: row?.country } */}
              Territory:{' '}
              {row?.state != null && row?.state != ''
                ? String(row?.state)
                : row?.country}
            </div>
            <div style={{ marginTop: '5px' }}>
              Duration: {row?.duration_for_star}
            </div>
          </>
        ) : (
          <>
            <div>{row?.right || 'Non-Exclusive'}</div>
            {row?.duration ? <div>{row?.duration}</div> : null}
          </>
        )}
      </span>
    ),
  },
  {
    label: 'Dimensions (Pixels.)',
    id: 'dimension',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Amount(INR)',
    id: 'price',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          wordBreak: 'break-all',
        }}
      >
        {columnData && currencyConversion(columnData) || 'N/A'}
      </span>
    ),
  },
];
const columnIvs = [
  {
    label: '',
    id: 'image',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <>
        <div
          style={{
            color: '#fff',
            padding: 10,
            borderRadius: 7,
            ...(row?.quality?.toLowerCase()?.includes('large')
              ? { backgroundColor: 'green' }
              : row?.quality?.toLowerCase()?.includes('web')
                ? { backgroundColor: '#ba36c1' }
                : row?.quality?.toLowerCase()?.includes('small')
                  ? { backgroundColor: 'rgb(58 152 235)' }
                  : { backgroundColor: '#404d93' }),
          }}
        >
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            {row?.quality}
          </Typography>
        </div>
      </>
    ),
  },
  {
    label: 'Plan Name',
    id: 'image_name',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {humanize(row?.quality)}Size-{columnData}
      </span>
    ),
  },
  {
    label: 'Max Download',
    id: 'dimension',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {row?.image_name}
      </span>
    ),
  },
  {
    label: 'Validity of Packages',
    id: 'image_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData} Days
      </span>
    ),
  },
  {
    label: 'Packages Values',
    id: 'price',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
];

const TotalStripe = ({
  totalAmount,
  discount,
  orderDiscount,
  planInfo,
  voucherDiscount,
  orderType,
  orderServiceTax,
  orderNetPayableAmountData,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="h6"
          sx={{ marginLeft: (theme) => theme.spacing(1) }}
        >
          Total Amount(INR):
        </Typography>
        <Typography
          variant="mediumBold"
          sx={(theme) => ({
            marginLeft: theme.spacing(1),
            color: theme.palette.info.main,
          })}
        >
          {totalAmount && currencyConversion(totalAmount)}
        </Typography>
      </Box>
      {discount && orderType?.toLowerCase() == 'nplan' ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h6"
            sx={{ marginLeft: (theme) => theme.spacing(1) }}
          >
            Discount(
            {(
              100 *
              ((Number(totalAmount)?.toFixed(2) -
                (Number(totalAmount)?.toFixed(2) -
                  Number(orderDiscount)?.toFixed(2))) /
                Number(totalAmount)?.toFixed(2))
            ).toFixed(2)}
            %)INR:
          </Typography>
          <Typography
            variant="mediumBold"
            sx={(theme) => ({
              marginLeft: theme.spacing(1),
              color: theme.palette.info.main,
            })}
          >
            {/* {Number(orderDiscount)?.toFixed(2)} */}
            {orderDiscount && currencyConversion(orderDiscount)}
          </Typography>
        </Box>
      ) : null}
      {planInfo && orderType?.toLowerCase() == 'iv' ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h6"
            sx={{ marginLeft: (theme) => theme.spacing(1) }}
          >
            Coupon ({planInfo?.coupon_name}):
          </Typography>
          <Typography
            variant="mediumBold"
            sx={(theme) => ({
              marginLeft: theme.spacing(1),
              color: theme.palette.info.main,
            })}
          >
            {/* {Number(voucherDiscount)?.toFixed(2)} */}
            {voucherDiscount && currencyConversion(voucherDiscount)}
          </Typography>
        </Box>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={() => { }}
      >
        <Typography
          variant="h6"
          sx={{ marginLeft: (theme) => theme.spacing(1) }}
        >
          Service Tax @18%(INR):
        </Typography>
        <Typography
          variant="mediumBold"
          sx={(theme) => ({
            marginLeft: theme.spacing(1),
            color: theme.palette.info.main,
          })}
        >
          {/* {Number(orderServiceTax)?.toFixed(2)} */}
          {orderServiceTax && currencyConversion(orderServiceTax)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={() => { }}
      >
        <Typography
          variant="h6"
          sx={{ marginLeft: (theme) => theme.spacing(1) }}
        >
          Net Payable Amount(INR):
        </Typography>
        <Typography
          variant="h6"
          sx={(theme) => ({
            marginLeft: theme.spacing(1),
            color: theme.palette.info.main,
          })}
        >
          {/* {Number(orderNetPayableAmountData)?.toFixed(2)} */}
          {orderNetPayableAmountData && currencyConversion(orderNetPayableAmountData)}
        </Typography>
      </Box>

      {/* <Typography */}
    </Box>
  );
};

function OrderDetailsPage({ orderId }) {
  const { stateData, countryData, appSetting } = useSelector(commonItemsSelector);
  const { loading, singleOrderDetail } = useSelector(orderListingItemsSelector);
  const { user } = useSelector(loginItemsSelector);
  const [orderData, setOrderData] = useState([]);
  const [totalAmount, setTotalAmount] = useState('');
  const [discount, setDiscount] = useState('0');
  const [voucherAmt, setVoucherAmt] = useState('0');
  const [planInfo, setPlanInfo] = useState(null);
  const [serviceTax, setserviceTax] = useState('18');
  const [orderType, setOrderType] = useState(null);


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [stateList, setStateList] = useState([]);
  const [groupData, setGroupData] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [orderCreateLoading, setOrderCreateLoading] = useState(false);
  const [countryShortName, setCountryShortName] = useState('in');
  const [appSettingDurationData, setAppSettingDurationData] = useState([]);

  const {
    orderServiceTax,
    orderDiscount,
    voucherDiscount,
    orderNetPayableAmountData,
  } = useMemo(() => {
    let totalServiceTax = 0;
    let totalDiscount = 0;
    let voucherDiscount = 0;
    let netPayAmt = 0;

    if (Number.isInteger(Number(totalAmount))) {
      if (discount && orderType?.toLowerCase() == 'nplan') {
        totalDiscount = Number(totalAmount) * (Number(discount) / 100);
      }
      if (voucherAmt && orderType?.toLowerCase() == 'iv') {
        voucherDiscount = Number(voucherAmt);
      }
      totalServiceTax =
        Number(totalAmount - totalDiscount - voucherDiscount) *
        (Number(serviceTax) / 100);
      netPayAmt =
        totalServiceTax + Number(totalAmount - totalDiscount - voucherDiscount);
    }
    return {
      orderServiceTax: String(totalServiceTax),
      orderDiscount: String(totalDiscount),
      voucherDiscount: String(voucherDiscount),
      orderNetPayableAmountData: String(netPayAmt),
    };
  }, [totalAmount, discount, voucherAmt, serviceTax]);

  const handleConfirm = async (values, { resetForm }) => {
    try {
      setOrderCreateLoading(true);
      let payload = {
        order_id: orderId,
        order_status: values.order_status,
        payment_status: values.payment_status,
        paid_on: formatDate(values.paid_on, 'iso'),
        invoice_date: formatDate(new Date(), 'iso'),
        accepted_or_rejected_on: formatDate(new Date(), 'iso'),
        initial_price:
          singleOrderDetail?.items?.order_status == 0
            ? totalAmount
            : singleOrderDetail?.items?.invoice?.initial_price,
        discount:
          singleOrderDetail?.items?.order_status == 0
            ? orderType?.toLowerCase() == 'iv'
              ? voucherDiscount
              : orderDiscount || 0
            : singleOrderDetail?.items?.invoice?.discount,
        tax:
          singleOrderDetail?.items?.order_status == 0
            ? orderServiceTax
            : singleOrderDetail?.items?.invoice?.tax,
        final_price:
          singleOrderDetail?.items?.order_status == 0
            ? orderNetPayableAmountData
            : singleOrderDetail?.items?.invoice?.final_price,
        payment_type: values.payment_type,
        client: values.client,
        company_name: values.company_name,
        company_address: values.company_address,
        address: values.address,
        name: values.name,
        tel: values.tel,
        mob: values.mob,
        email: values.email,
        state: values.state,
        credit_period:
          values.credit_period == 'Select' ? '' : values.credit_period,
        approval_mode:
          values.approved_mode == 'Select' ? '' : values.approved_mode,
        special_comment: values.special_comments,
        gift_policy:values?.gift_policy,
        additional_email: values.additional_email,
        closed_by: values.closed_by == 'Select' ? '' : values.closed_by,
        purchase_order: values.purchase_order,
        comment: values.comments,
        gift_policy:values?.gift_policy ,
        voucher:values?.voucher,
        voucher_status: values.voucher_status,
        sez_status: values.sez_status||'0',

      };
      if (payload.order_status != 0) {
        let resp = await confirmOrder(payload);
        if (resp) {
          dispatch(setOrderDetails({}));
          let payloadForLogs = {
            action: action?.update,
            module: modulesNames?.orders,
            description: logsMessagesList?.order?.orderUpdate,
          };
          await adminAddLogs(payloadForLogs);
          // navigate(-1)
          navigate('/order?tab=3', { replace: true });
          // window.close()
        }
      } else {
        toast.error('Please change order status.');
      }
      setOrderCreateLoading(false);
    } catch (e) {
      setOrderCreateLoading(false);
    }
  };
  let validationShape = Yup.object().shape({
    order_id: Yup.string().required('Order id is requried.'),
    paid_on: Yup.string().required('Paid on is requried.'),
    client: Yup.string().required('Client is requried.'),
    address: Yup.string().required('Address is requried.'),
  });

  if (singleOrderDetail?.items?.order_type?.toLowerCase() == 'iv') {
    validationShape = Yup.object().shape({
      order_id: Yup.string().required('Order id is requried.'),
      paid_on: Yup.string().required('Paid on is requried.'),
      address: Yup.string().required('Address is requried.'),
    });
  }

  const validationSchema = validationShape;
  const formik = useFormik({
    initialValues: {
      order_status: '',
      order_id: orderId || '',
      payment_type: '',
      client: '',
      company_name: '',
      company_address: '',
      address: '',
      name: '',
      tel: '',
      mob: '',
      // agency: "",
      email: '',
      // paid_by: "",
      // subscription_converted_by: "",
      state: '',
      country: '',
      // company_group: "",
      // invoice_discount: "",
      // discount_type: "",
      // user_type: "",
      credit_period: 'Select',
      approved_mode: 'Select',
      special_comments: '',
      // order_by: "",
      ordered_on: '',
      additional_email: '',
      closed_by: 'Select',
      payment_status: '',
      paid_on: '',
      accepted_or_rejected_on: '',
      purchase_order: '',
      comments: '',
      zip_code: '',
      voucher:'',
      voucher_status:'',
      // download_link: ""
      sez_status:'',
    },
    validationSchema: validationSchema,
    onSubmit: handleConfirm,
  });

  const appSettingDurationMap = () => {
    if (appSetting && appSetting?.items) {
      const durationPriceDataFromAppSetting = getDataFromAppSetting(
        appSetting?.items?.features?.stars,
        "key_name",
        "duration"
      );
      const durationData = JSON.parse(
        durationPriceDataFromAppSetting[0]?.key_value || "{}"
      );
      const outputArray = [];
      for (const label in durationData) {
        const value = durationData[label];
        outputArray.push({ label, value });
      }
      return outputArray
    }
  }



  useEffect(() => {
    getOrderDetails();
    getAppData();
    getCountryDetails();
    //appSettingDuration();

  }, []);

  const getAppData = async () => {
    dispatch(appSettingApi());
  }

  const getOrderDetails = async () => {
    dispatch(orderDetailsApi(orderId));
  };

  const calculateTotalAmount = (orderDetail) => {
    if (orderDetail?.length) {
      let totalAmountCalc = 0;
      orderDetail?.map((item) => {
        totalAmountCalc = Number(totalAmountCalc) + Number(item.price);
      });
      setTotalAmount(String(totalAmountCalc));
    } else {
      setTotalAmount('N/A');
    }
  };

  const handleData = (data, index) => {
    if (data) {
      setGroupData(data);
      formik.setFieldValue('company_name', data?.value);
      formik.setFieldValue('company_address', data?.company_address);
    } else {
      formik.setFieldValue('company_name', '');
      formik.setFieldValue('company_address', '');
      setGroupData(null);
    }
  };

  const getAsyncListing = async (inputValue) => {
    if (inputValue && inputValue.length > 2) {
      let data = await companyByNameNoRedux({ name: inputValue });
      if (data) {
        let updatedData = data?.map((item, index) => {
          return {
            value: item.company_name,
            label: item.company_name,
            ...item,
          };
        });
        return updatedData || [];
      }
      return [];
    }
  };

  useEffect(() => {
    if (countryData && Array.isArray(countryData?.items)) {
      let data = countryData?.items?.map((item) => {
        return {
          label: item?.country_name,
          value: item?.country_id,
        };
      });
      setCountryList(data);
      // getStateData(singleUserDetail);
    }
  }, [countryData, orderDetalData]);


  useEffect(() => {
    try {
      let items = Object.keys(singleOrderDetail.items).length;
      if (items) {
        let arrayOrderData = [];
        arrayOrderData = singleOrderDetail?.items?.order_details?.map((v) => {
          let obj = ({
            ...v,
            order_type: singleOrderDetail?.items?.order_type || '',
          })
          let newArr = appSettingDurationMap();
          if (newArr?.length) {
            let duration_for_star = newArr?.find((dur) => {
              return dur?.value == v?.duration_for_star || dur?.label == v?.duration_for_star
            })
            if (duration_for_star) {
              obj['duration_for_star'] = duration_for_star?.label;
            }

          }
          if (v?.state) {
            let stateList = v?.state?.split(",");
            if (stateList?.length > 5)
              obj['state'] = 'Pan India'

          }
          return obj;
        }
        )
        setOrderData(arrayOrderData);
        setDiscount(singleOrderDetail?.items?.user?.discount);
        if (singleOrderDetail?.items?.previous_order_id) {
          if (singleOrderDetail?.items?.plan_info) {
            setVoucherAmt(
              String(singleOrderDetail?.items?.plan_info?.coupon_amount),
            );
            setPlanInfo(singleOrderDetail?.items?.plan_info);
          }
        }
        if (singleOrderDetail?.items?.user?.countries.length) {
          setCountryShortName(
            singleOrderDetail?.items?.user?.countries[0]?.country_short_name?.toLowerCase(),
          );
        }
        const calculateDateByCreditPeriod = () => {
          if (
            singleOrderDetail?.items?.grouping_manage?.period
              ?.toLowerCase()
              ?.includes('select')
          ) {
            return formatDate(new Date(), 'yyyy-mm-ddThh:mm');
          } else if (singleOrderDetail?.items?.grouping_manage?.period) {
            return formatDate(
              new Date().setDate(
                new Date().getDate() +
                Number(singleOrderDetail?.items?.grouping_manage?.period),
              ),
              'yyyy-mm-ddThh:mm',
            );
          } else if (singleOrderDetail?.items?.special_comment?.period) {
            return formatDate(
              new Date().setDate(
                new Date().getDate() +
                Number(singleOrderDetail?.items?.special_comment?.period),
              ),
              'yyyy-mm-ddThh:mm',
            );
          }
        };
        setOrderType(singleOrderDetail?.items?.order_type);
        formik.setValues({
          order_status: singleOrderDetail?.items?.order_status,
          order_id: orderId || '',
          payment_type: singleOrderDetail?.items?.payment_type || '',
          client: singleOrderDetail?.items?.client || '',
          company_name: singleOrderDetail?.items?.company || '',
          company_address: singleOrderDetail?.items?.company_address || '',
          address: singleOrderDetail?.items?.user?.address || '',
          name:
            singleOrderDetail?.items?.user?.first_name +
            ' ' +
            singleOrderDetail?.items?.user?.last_name || '',
          tel: singleOrderDetail?.items?.user?.phone || '',
          mob: singleOrderDetail?.items?.user?.mobile || '',
          email: singleOrderDetail?.items?.user?.email || '',
          // paid_by: "",
          // subscription_converted_by: "",
          state: singleOrderDetail?.items?.user?.states?.length
            ? singleOrderDetail?.items?.user?.states[0]?.state_name
            : '',
          country: singleOrderDetail?.items?.user?.countries?.length
            ? singleOrderDetail?.items?.user?.countries[0]?.country_name
            : '',
          credit_period: singleOrderDetail?.items?.grouping_manage?.period
            ?.toLowerCase()
            ?.includes('select')
            ? 'Select'
            : singleOrderDetail?.items?.grouping_manage?.period ||
            singleOrderDetail?.items?.special_comment?.period ||
            'Select',
          approved_mode:
            singleOrderDetail?.items?.grouping_manage?.approval_mode
              ?.toLowerCase()
              ?.includes('select')
              ? 'Select'
              : singleOrderDetail?.items?.grouping_manage?.approval_mode ||
              singleOrderDetail?.items?.special_comment?.approval_mode ||
              'Select',
          special_comments:
            singleOrderDetail?.items?.special_comment?.special_comment || '',
          ordered_on: singleOrderDetail?.items?.ordered_on || '',
          additional_email:
            singleOrderDetail?.items?.grouping_manage?.additional_email || '',
          closed_by: singleOrderDetail?.items?.grouping_manage?.closed_by
            ?.toLowerCase()
            ?.includes('select')
            ? 'Select'
            : singleOrderDetail?.items?.grouping_manage?.closed_by ||
            singleOrderDetail?.items?.closed_by?.closed_by ||
            'Select',
          payment_status: singleOrderDetail?.items?.payment_status,
          gift_policy:singleOrderDetail?.items?.gift_policy,
          paid_on:
            formatDate(
              singleOrderDetail?.items?.payment_on,
              'yyyy-mm-ddThh:mm',
            ) ||
            calculateDateByCreditPeriod() ||
            formatDate(new Date(), 'yyyy-mm-ddThh:mm'),
          accepted_or_rejected_on:
            formatDate(
              singleOrderDetail?.items?.accepted_or_rejected_on,
              'yyyy-mm-ddThh:mm',
            ) || '',
          purchase_order: singleOrderDetail?.items?.purchase_order || '',
          comments: singleOrderDetail?.items?.comment || '',
          zip_code: singleOrderDetail?.items?.user?.zip_code || '',
          voucher:singleOrderDetail?.items?.voucher,
          voucher_status:singleOrderDetail?.items?.voucher_status || '',
          sez_status: singleOrderDetail?.items?.user?.sez_status || '0',
        });
        calculateTotalAmount(singleOrderDetail.items?.order_details || []);
      }
      getStateData(singleOrderDetail?.items?.user || {});
    } catch (e) { }
  }, [singleOrderDetail]);

  const getCountryDetails = async () => {
    dispatch(getCountry());
  };

  const handleChangeCountry = async (e) => {
    formik.setFieldValue('country', e.target.value);
    formik.setFieldValue('state', '');
    let country_id = 0;
    countryData?.items?.filter((val) => {
      if (val.country_name === e.target.value) {
        country_id = val.country_id;
        setCountryShortName(val?.country_short_name?.toLowerCase());
        formik.setFieldValue('mobile', '');
      }
    });
    dispatch(getState(country_id));
  };

  const getStateData = async (user) => {
    if (user?.countries?.length) {
      dispatch(getState(user?.countries[0]?.country_id));
    }
  };
  useEffect(() => {
    if (stateData && Array.isArray(stateData?.items)) {
      let data = stateData?.items?.map((item) => {
        return {
          label: item?.state_name,
          value: item?.state_id,
        };
      });
      setStateList(data);
    }
  }, [stateData]);

  if (!Object.keys(singleOrderDetail?.items).length) {
    return (
      <CustomBox padding={3}>
        <CustomLoading loading={singleOrderDetail?.loading} />
      </CustomBox>
    );
  }


  return (
    <div>
      <CustomBox padding={3}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Box>
              <strong>Order ID:</strong> {formik.values.order_id}
            </Box>
            <Box>
              <strong>Mode of Payment:</strong> {formik.values.payment_type}
            </Box>
            <Box>
              <strong>Name:</strong> {formik.values.name}
            </Box>
            <Box>
              <strong>Client:</strong> {formik.values.client}
            </Box>
            <Box>
              <strong>Company Name:</strong> {formik.values.company_name}
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box>
              <strong>Ordered On:</strong>{' '}
              {formatDate(formik.values.ordered_on, 'dd-mm-yyyy hh:mm')}
            </Box>
            <Box>
              <strong>Email:</strong> {formik.values.email}
            </Box>
            <Box>
              <strong>Telephone No.:</strong> {formik.values.tel}
            </Box>
            <Box>
              <strong>Mobile:</strong> {formik.values.mob}
            </Box>
          </Grid>
        </Grid>
      </CustomBox>
      <Divder spacing={1} />
      <form onSubmit={formik.handleSubmit}>
        <CustomBox padding={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                select
                name={'order_status'}
                label="Order Status"
                placeholder="Order Status"
                value={formik.values.order_status}
                onChange={formik.handleChange}
                error={
                  formik?.touched?.order_status &&
                  Boolean(formik.errors.order_status)
                }
                helperText={
                  formik?.touched?.order_status && formik.errors.order_status
                }
              >
                {(formik.values.order_status == 2
                  ? orderDetalData?.orderStatus?.slice(2)
                  : orderDetalData?.orderStatus?.slice(0, 2)
                )?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </OrderDetailsInput>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                name="client"
                label="Client"
                placeholder="Client"
                value={formik.values.client}
                onChange={formik.handleChange}
                error={formik?.touched?.client && Boolean(formik.errors.client)}
                helperText={formik?.touched?.client && formik.errors.client}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <OrderDetailsInput
                name="address"
                label="Address"
                placeholder="Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={
                  formik?.touched?.address && Boolean(formik.errors.address)
                }
                helperText={formik?.touched?.address && formik.errors.address}
                multiline
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                label="Zip code"
                placeholder="Zip code"
                name="zip_code"
                value={formik.values.zip_code}
                onChange={(e) => {
                  // if(!isNaN(e.target.value)){
                  formik.setFieldValue('zip_code', e.target.value);
                  // }
                }}
                error={
                  formik.touched.zip_code && Boolean(formik.errors.zip_code)
                }
                helperText={formik.touched.zip_code && formik.errors.zip_code}
                inputProps={{ maxLength: 15 }}
              />
            </Grid>
          </Grid>
        </CustomBox>
        {orderData.length > 0 && (
          <>
            <Divder spacing={1} />
            <CustomBox padding={0}>
              <MatTable
                loading={singleOrderDetail?.loading}
                showCheckbox={false}
                columns={
                  singleOrderDetail.items?.order_type == 'IV'
                    ? columnIvs
                    : column || []
                }
                data={orderData || []}
                pagination={false}
                toolbarEnable={false}
              />
            </CustomBox>
          </>
        )}
        <Divder spacing={1} />
        <CustomBox padding={3}>
          {singleOrderDetail?.items?.order_status == 0 ? (
            <TotalStripe
              totalAmount={totalAmount}
              discount={discount}
              orderDiscount={orderDiscount}
              planInfo={planInfo}
              voucherDiscount={voucherDiscount}
              orderType={orderType}
              orderServiceTax={orderServiceTax}
              orderNetPayableAmountData={orderNetPayableAmountData}
            />
          ) : (
            <TotalStripe
              totalAmount={singleOrderDetail?.items?.invoice?.initial_price}
              discount={singleOrderDetail?.items?.invoice?.discount}
              orderDiscount={singleOrderDetail?.items?.invoice?.discount}
              planInfo={planInfo}
              voucherDiscount={voucherDiscount}
              orderType={orderType}
              orderServiceTax={singleOrderDetail?.items?.invoice?.tax}
              orderNetPayableAmountData={
                singleOrderDetail?.items?.invoice?.final_price
              }
            />
          )}
        </CustomBox>
        <Divder spacing={1} />
        {/* last Form */}
        <CustomBox padding={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <OrderDetailsInput
                name="country"
                label="Country"
                id="country"
                placeholder="Country"
                SelectProps={{ native: true, }}
                select
                value={formik.values.country}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => handleChangeCountry(e)}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              >

                <option key={'Select Country'} value={''}>
                  Select Country
                </option>

                {countryList &&
                  countryList?.map((country) => (
                    <option key={country.value} value={country.label}>
                      {country.label}
                    </option>
                  ))}

              </OrderDetailsInput>

            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                name="state"
                select
                SelectProps={{
                  native: true,
                }}
                label="Select State"
                placeholder="Select State"
                value={formik.values.state}
                onChange={formik.handleChange}
              >
                <option key={'Select State'} value={''}>
                  Select State
                </option>
                {stateList &&
                  stateList?.map((state) => {
                    return (
                      <option key={state.value} value={state.label}>
                        {state.label}
                      </option>
                    );
                  })}
              </OrderDetailsInput>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              {/* <OrderDetailsInput
                select
                name="credit_period"
                label="Credit Period"
                placeholder="Credit Period"
                value={formik.values.credit_period}
                onChange={(e) => {
                  let extended_date = formatDate(
                    new Date().setDate(
                      new Date().getDate() + Number(e.target.value),
                    ),
                    'yyyy-mm-ddThh:mm',
                  );
                  formik.setValues((values) => ({
                    ...values,
                    credit_period: e.target.value,
                    paid_on: extended_date,
                    accepted_or_rejected_on: extended_date,
                  }));
                }}
              >
                {orderDetalData?.createPeriod?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}{' '}
                    {!option.label.toLowerCase()?.includes('select')
                      ? option.label > 0
                        ? 'days'
                        : 'day'
                      : null}
                  </MenuItem>
                ))}
              </OrderDetailsInput> */}
              <OrderDetailsInput
                name="credit_period"
                label="Credit Period"
                placeholder="Credit period"
                SelectProps={{ native: true, }}
                select
                value={formik.values.credit_period}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                // onChange={formik.handleChange}
                onChange={(e) => {
                  let extended_date = formatDate(
                    new Date().setDate(
                      new Date().getDate() + Number(e.target.value),
                    ),
                    'yyyy-mm-ddThh:mm',
                  );
                  formik.setValues((values) => ({
                    ...values,
                    credit_period: e.target.value,
                    paid_on: extended_date,
                    accepted_or_rejected_on: extended_date,
                  }));
                }}
                error={formik.touched?.credit_period && Boolean(formik.errors.credit_period)}
                helperText={
                  formik.touched.credit_period &&
                  formik.errors.credit_period}
              >
                <option key={'Select Credit Period'} value={''}>
                  Select Credit Period
                </option>
                {
                  orderDetalData?.createPeriod?.map((role) => (
                    <option key={role.value} value={role.label}>
                      {role.label} {' '}
                    {!role.label.toLowerCase()?.includes('select')
                      ? role.label > 0
                        ? 'days'
                        : 'day'
                      : null}
                    </option>
                  ))}
              </OrderDetailsInput>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              {/* <OrderDetailsInput
                select
                name="approved_mode"
                label="Approved Mode"
                placeholder="Approved Mode"
                value={formik.values.approved_mode}
                onChange={formik.handleChange}
              >
                {orderDetalData?.approvalMode?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </OrderDetailsInput> */}

              <OrderDetailsInput
                name="approved_mode"
                label="Approved Mode"
                placeholder="Approved Mode"
                SelectProps={{ native: true, }}
                select
                value={formik.values.approved_mode}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                error={formik.touched.approved_mode && Boolean(formik.errors.approved_mode)}
                helperText={
                  formik.touched.approved_mode &&
                  formik.errors.approved_mode}
              >
                <option key={'Select Approval Mode'} value={''}>
                  Select Approval Mode
                </option>
                {
                  orderDetalData?.approvalMode?.map((role) => (
                    <option key={role.value} value={role.label}>
                      {role.label}
                    </option>
                  ))}
              </OrderDetailsInput>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                name="purchase_order"
                label="Purchase Order"
                placeholder="Purchase Order"
                value={formik.values.purchase_order}
                onChange={formik.handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                name="additional_email"
                label="Additional Email"
                placeholder="Additional Email"
                value={formik.values.additional_email}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                select
                name="closed_by"
                label="Closed by"
                placeholder="Closed by"
                value={formik.values.closed_by}
                // disabled
                onChange={formik.handleChange}
              >
                {orderDetalData?.closedBy?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </OrderDetailsInput>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                select
                name={'payment_status'}
                label="Payment Status"
                placeholder="Payment Status"
                value={formik.values.payment_status}
                onChange={formik.handleChange}
              >
                {orderDetalData?.paymentStatus?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </OrderDetailsInput>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <OrderDetailsInput
                name="paid_on"
                type="datetime-local"
                label="Payment Due Date"
                placeholder="Payment Due Date"
                value={formatDate(formik.values.paid_on, 'yyyy-mm-ddThh:mm')} //2017-06-13T13:00
                onChange={(e) => {
                  if (validDate(e.target.value, formik.values.ordered_on)) {
                    formik.setValues((values) => ({
                      ...values,
                      paid_on: e.target.value,
                      accepted_or_rejected_on: e.target.value,
                    }));
                  } else {
                    toast.error(
                      'Payment date should be greater than ordered date',
                    );
                  }
                  // }
                }}
                error={formik.touched.paid_on && Boolean(formik.errors.paid_on)}
                helperText={formik.touched.paid_on && formik.errors.paid_on}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomCheckbox
                name="gift_policy"
                label={'Gift Policy'}
                labelPlacement={'end'}
                subLableText={'Active'}
                value={formik.values.gift_policy == 1 ? true : false}
                handleChange={(e) => { formik.setFieldValue('gift_policy', e.target.checked == true ? '1' : '0',); }}
                error={formik.touched.gift_policy && Boolean(formik.errors.gift_policy)}
                helperText={formik.touched.gift_policy && formik.errors.gift_policy}
              />

            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <OrderDetailsInput
                name="voucher_status"
                label="Voucher Status"
                placeholder="Voucher Status"
                SelectProps={{ native: true, }}
                select
                value={formik.values.voucher_status}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                error={formik.touched.voucher_status && Boolean(formik.errors.voucher_status)}
                helperText={
                  formik.touched.voucher_status &&
                  formik.errors.voucher_status}
              >
                {/* <option key={'Voucher Status'} value={''}>
                  Select Voucher Status
                </option> */}
                <option value={'1'}>Paid</option>
                <option value={'0'}>UnPaid</option>

              </OrderDetailsInput>
              
            </Grid>
            <Grid item xs={12} md={3}>
              <OrderDetailsInput
                name="voucher"
                label="Voucher Comments"
                placeholder="Voucher Comments"
                value={formik.values.voucher}
                onChange={formik.handleChange}
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <OrderDetailsInput
                name="comments"
                label="Comments"
                placeholder="Comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <OrderDetailsInput
                name="special_comments"
                label="Special Comments"
                placeholder="Special Comments"
                value={formik.values.special_comments}
                onChange={formik.handleChange}
                multiline
                rows={3}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <OrderDetailsInput
                name="sez_status"
                label="Select Sez"
                id="Select Sez"
                placeholder="Select Sez"
                SelectProps={{ native: true }}
                select
                value={formik.values.sez_status}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                error={formik.touched.sez_status && Boolean(formik.errors.sez_status)}
                helperText={formik.touched.sez_status && formik.errors.sez_status}
              >
                {Select_Sez.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
              </OrderDetailsInput>
            </Grid>

          
            
            {singleOrderDetail?.items?.order_status == 0 ? (
              <Grid
                item
                xs={12}
                sx={{ justifyContent: 'flex-end', display: 'flex' }}
              >
                <CustomButton
                  type="submit"
                  loading={orderCreateLoading || singleOrderDetail?.loading}
                  sx={{ padding: '12px 15px' }}
                  color={'buttonPrimary'}
                >
                  Confirm
                </CustomButton>
              </Grid>
            ) : null}
          </Grid>
        </CustomBox>
      </form>
    </div>
  );
}

export default OrderDetailsPage;
