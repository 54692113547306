import {  Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  breakStringIntoLines,
  currencyConversion,
  dateDiffInDaysOfDates,
  downloadBlobFile,
  formatDate,
  getNewParameters,
  getObjectSubset,
  getParameters,
  validDate,
} from '../../lib/helper';
import {
  companyListings,
  countryListings,
  countryListingsUnpaid,
  getDailySalesReport,
  getReportListing,
  paidStatusChange,
  stateListings,
  stateListingsUnpaid,
} from '../../Services/reportsApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import * as Yup from 'yup';
import { reportListingItemsSelector } from '../../redux/reports';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MatTable from '../Tables/MatTable';
import { setResetDailyReportData } from '../../redux/reports';
import {
  followUpsItemSelector,
  setResetOutstandingPaymenttData,
} from '../../redux/followUps';
import {
  getOutStandingPaymentData,
  getPaymentReportFile,
  manageReportLising,
} from '../../Services/CRMFollowups';
import OutstandingFilters from './OutstandingFilters';
import ConfirmationModal from '../Modals/ConfirmationModal';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

const column = [
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (
      <Typography
        variant="span"
        style={{ whiteSpace: 'nowrap' }}
      >
        {columnData}
      </Typography>
    ),
  },
  {
    label: 'Invoice ID',
    id: 'invoice_id',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'Due Days',
    id: 'order',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {dateDiffInDaysOfDates(columnData?.payment_on) || 0}
      </span>
    ),
  },
  {
    label: 'Order Date',
    id: 'order_date',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(row?.order?.ordered_on, 'dd-mm-yyyy') || '-'}
      </span>
    ),
  },
  {
    label: 'Paid On',
    id: 'paid_on',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(row?.order?.paid_on, 'dd-mm-yyyy') || '-'}
      </span>
    ),
  },
  {
    label: 'Company Name',
    id: 'company_name',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {row?.grouping_manage?.company_group || '-'}
      </span>
    ),
  },
  // {
  //   label: 'Amount (INR.)',
  //   id: 'withoutgst_price',
  //   format: (columnData) => (
  //     <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
  //       {columnData && currencyConversion(columnData) || 'N/A'}
  //     </span>
  //   ),
  // },
  {
    label: 'Invoice Amt.(INR)',
    id: 'invoice_amt',
    // format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
    //   <span
    //     style={{
    //       display: 'inline-block',
    //       wordBreak: 'break-all',
    //       maxWidth: '250px',
    //       width: 'max-content',
    //     }}
    //   >
    //     <div>
    //       {columnData && currencyConversion(columnData) || 'N/A'}
    //     </div>
    //   </span>
    // ),

    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <span
        style={{
          display: 'inline-block',
          wordBreak: 'break-all',
          maxWidth: '250px',
          width: 'max-content',
        }}
      >
        <div>

          <strong>Final Amount:</strong> {' '}
          {currencyConversion(row?.final_price)}
        </div>
        <div>
          <strong style={{color:'green'}}>Partial Paid:</strong> {currencyConversion(row?.order?.partial_paid)}
        </div>
        <div>
          
          <strong style={{color:'#993300'}}>Remaining Amount:</strong> { currencyConversion(row?.final_price-row?.order?.partial_paid) || 'N/A'}

        </div>
        
      </span>
    ),
    
  },
  // {
  //   label: 'Part Pay(INR.)',
  //   id: 'order',
  //   format: (columnData) => (
  //     <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
  //       {columnData?.partial_paid && currencyConversion(columnData?.partial_paid) || '0'}
  //     </span>
  //   ),
  // },
  {
    label: 'Order by',
    id: 'ordered_by',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content" }}>{columnData || 'N/A'}</span>)

  },
  {
    label: 'Payment Type',
    id: 'payment_type',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
      {row?.order?.payment_type || 'N/A'}
    </span>)
  },
  {
    label: 'Order Type',
    id: 'order_type',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
      {row?.order?.order_type || 'N/A'}
    </span>)
  },
  {
    label: 'Client Name',
    id: 'client',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}
      </span>
    ),
  },
  {
    label: 'State',
    id: 'state',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}

      </span>
    ),
  },
  {
    label: 'Country',
    id: 'country',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '0'}
      </span>
    ),
  },
];

function OutstandingPayment() {
  const [fileResp, setFileResp] = useState({
    status: false,
    data: null,
  });
  const [loading, setLoading] = useState(false);
  const [tableListing, setTableListing] = useState([]);
  const { allIReports, mainLoading } = useSelector(reportListingItemsSelector);
  const { outstandingPaymentData } = useSelector(followUpsItemSelector);
  const [confirmUnpaid, setConfirmUnpaid] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [countryId, setCountryId] = useState(null);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [limit, setLimit] = useState(20);
  const [companyList, setCompanyList] = useState([]);
  const [countryList, setCountryList] = useState([{ label: 'Select', value: '' }]);
  const [shortCompanyNamesList, setShortCompanyNamesList] = useState([

  ]);
  const [companyWiseStateList, setCompanyWiseStateList] = useState([]);

  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'From Date',
      id: 'from',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          if (e.target.value && newState[1].value) {
            handleCompanyCalling(e.target.value, newState[1].value, newState[2].value, newState[4].value, newState[5].value, newState[6].value);
            // 1. from  2.to  3.payment status 4.country 5. state 6. due days
            newState[7].isVisible = true;
          }
          if (e.target.value && newState[1].value && newState[2].value) {
            CountryListCalling(e.target.value, newState[1].value, newState[2].value);

          }
          if (e.target.value && newState[1].value && newState[5]?.value && newState[2]?.value) {
            stateListCallingPaid(e.target.value, newState[1].value, newState[5]?.value, newState[2]?.value)
          }
          return newState;
        });

      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'to',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          if (newState[0].value > e.target.value) {
            toast.error("To date should less than from date");
          }
          else {
            newState[index].value = e.target.value;
            if (e.target.value && newState[0].value) {
              handleCompanyCalling(newState[0].value, e.target.value, newState[2].value, newState[4].value, newState[5].value, newState[6].value);
              newState[7].isVisible = true;
              // 1. from  2.to  3.payment status 4.country 5. state 6. due days
            }
            if (e.target.value && newState[0].value && newState[2].value) {
              CountryListCalling(newState[0].value, e.target.value, newState[2].value);
            }

            if (e.target.value && newState[0].value && newState[5]?.value && newState[2]?.value) {
              stateListCallingPaid(newState[0].value, e.target.value, newState[5]?.value, newState[2]?.value)
            }
          }
          return newState;
        });

      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'Payment Status',
      id: 'payment_status',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          if (newState[0]?.value && newState[1]?.value) {
            newState[7].isVisible = true;

          }
          if (e.target.value) {

            newState[0].isVisible = true;
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            newState[8].isVisible = true;
            newState[4].isVisible = true;
            newState[6].isVisible = true;
            newState[4].dataArr = [{ label: 'Select', value: '' }]
            newState[4].value = '';
            if (newState[0]?.value && newState[1]?.value) {
              if (newState[0].value && newState[1].value && newState[5]?.value && e.target.value) {
                stateListCallingPaid(newState[0].value, newState[1].value, newState[5]?.value, e.target.value)
              }
              if (e.target.value && newState[0].value && e.target.value) {
                CountryListCalling(newState[0].value, newState[1].value, e.target.value);
              }
              handleCompanyCalling(newState[0].value, newState[1].value, e.target.value, newState[4].value, newState[5].value, newState[6].value)
            }
            if (e.target.value == 1) {
              newState[3].isVisible = true;
              newState[5].isVisible = false;
            }
            else {
              newState[3].isVisible = false;
              newState[4].isVisible = true;
              newState[5].isVisible = false;
              newState[6].isVisible = true;
              newState[8].isVisible = true;

            }
          }
          else {
            newState[0].isVisible = true;
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            newState[3].isVisible = false;
            newState[4].isVisible = false;
            newState[5].isVisible = false;
            newState[6].isVisible = true;
            newState[4].dataArr = [{ label: 'Select', value: '' }]
            newState[4].value = '';
            handleCompanyCalling(newState[0].value, newState[1].value, e.target.value, newState[4].value, newState[5].value, newState[6].value)

          }
          return newState;
        });
      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
        { label: 'Paid', value: '1' },
        { label: 'Unpaid', value: '0' },
      ],
      props: {},
      isVisible: true,
    },
    {
      label: 'Payment Type',
      id: 'payment_type',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          if (newState[0]?.value && newState[1]?.value) {
            newState[7].isVisible = true;
            newState[4].isVisible = true;
          }
          if (e.target.value) {
            newState[0].isVisible = true;
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            newState[3].isVisible = true;
            newState[5].isVisible = false;
            newState[8].isVisible = true;
          } else {
            newState[0].isVisible = true;
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            newState[3].isVisible = true;
            newState[5].isVisible = false;
            newState[8].isVisible = true;
          }
          return newState;
        });
      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
        { label: 'Cheque/demand draft', value: 'Cheque/demand draft' },
        { label: 'NEFT/RTGS/WT', value: 'NEFT/RTGS/WT' },
        { label: 'RazorPay', value: 'RazorPay' },
        { label: 'American Express', value: 'American Express' },
        { label: 'Pay10', value: 'Pay10' },
        { label: 'Zaakpay', value: 'Zaakpay' },
        { label: 'Paypal', value: 'Paypal' }

      ],
      props: {},
      isVisible: false,
    },
    {
      label: 'Select Country',
      id: 'country',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          if (newState[0]?.value && newState[1]?.value) {
            newState[7].isVisible = true;
          }
          if (e.target.value) {
            newState[5].value = '';
            newState[0].isVisible = true;
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            newState[8].isVisible = true;
            if (newState[0].value && newState[1].value && e.target.value && newState[2].value) {
              stateListCallingPaid(newState[0].value, newState[1].value, e.target.value, newState[2].value);
            }
            if (newState[0]?.value && newState[1]?.value) {
              handleCompanyCalling(newState[0].value, newState[1].value, newState[2].value, e.target.value, newState[5].value, newState[6].value)
            }
          }
          else {

            newState[0].isVisible = true;
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            newState[3].isVisible = false;
            newState[4].isVisible = true;
            newState[5].isVisible = false;
            newState[6].isVisible = true;
            newState[8].isVisible = true;
            newState[5].value = '';
          }
          return newState;
        });

      },
      style: {},
      dataArr: [{ label: 'Select', value: '' }],
      props: {},
      isVisible: false,
    },
    {
      label: 'Select State',
      id: 'state',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          if (newState[0]?.value && newState[1]?.value) {
            newState[7].isVisible = true;
          }
          if (e.target.value) {
            newState[index].value = e.target.value;
            newState[0].isVisible = true;
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            newState[8].isVisible = true;
            newState[6].isVisible = true;
            if (newState[0]?.value && newState[1]?.value) {
              handleCompanyCalling(newState[0].value, newState[1].value, newState[2].value, newState[4].value, e.target.value, newState[6].value)
            }
          }
          else {
            newState[index].value = e.target.value;
            newState[0].isVisible = true;
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            newState[3].isVisible = false;
            newState[4].isVisible = true;
            newState[5].isVisible = true;
            newState[6].isVisible = true;
          }
          return newState;
        });

      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
      ],
      props: {},
      isVisible: false,
    },
    {
      label: 'Due Days',
      id: 'dueDays',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          if (newState[0]?.value && newState[1]?.value) {
            newState[7].isVisible = true;
          }
          if (e.target.value) {
            newState[0].isVisible = true;
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            newState[3].isVisible = false;
            newState[4].isVisible = true;
            newState[5].isVisible = true;
            newState[6].isVisible = true;
            newState[8].isVisible = true;
            if (newState[0]?.value && newState[1]?.value) {
              handleCompanyCalling(newState[0].value, newState[1].value, newState[2].value, newState[4].value, newState[5].value, e.target.value,)
            }
          } else {
            newState[index].value = '';
            newState[0].isVisible = true;
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            newState[3].isVisible = false;
            newState[4].isVisible = true;
            newState[5].isVisible = true;
            newState[6].isVisible = true;
            newState[8].isVisible = true;
          }
          return newState;
        });
        handleCompanyList(e.target.value, 4);
      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
        { label: '>30 days', value: '30' },
        { label: '>60 days', value: '60' },
        { label: '>90 days', value: '90' },
        { label: '>180 days', value: '180' }

      ],
      props: {},
      isVisible: true,
    },
    {
      label: 'Company Name',
      id: 'company_name',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          if (e.target.value) {
            newState[0].isVisible = true;
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            newState[3].isVisible = false;
            newState[4].isVisible = true;
            newState[5].isVisible = true;
            newState[6].isVisible = true;
            newState[7].isVisible = true;
            newState[8].isVisible = true;
          } else {
            newState[0].isVisible = true;
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            newState[3].isVisible = false;
            newState[4].isVisible = true;
            newState[5].isVisible = true;
            newState[6].isVisible = true;
            newState[7].isVisible = true;
            newState[8].isVisible = true;
          }
          return newState;
        });

      },
      style: {},
      dataArr: [{ label: 'Select', value: '' }],
      props: {},
      isVisible: false,
    },
    {
      label: 'Order Type',
      id: 'order_type',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          if (e.target.value) {
            newState[index].value = e.target.value;
          }
          else {
            newState[index].value = '';
          }

          return newState;
        });
      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
        { label: 'IV', value: 'IV' },
        { label: 'NPlan', value: 'NPlan' },
      ],
      props: {},
      isVisible: true,
    },

  ]);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getOrderListing();
  }, [allParams])

  const stateListCallingPaid = async (from, to, country, payment_status) => {
    let payload = { type: '1' };
    if (payment_status) {
      payload['payment_status'] = payment_status;
    }
    if (from) payload['from'] = formatDate(from, 'yyyy-mm-dd') + " 00:00:00";
    if (to) payload['to'] = formatDate(to, 'yyyy-mm-dd') + " 23:59:59";
    if (country) payload['country_id'] = country;

    let resp = await stateListingsUnpaid(payload);
    if (resp?.status == 200) {
      if (resp?.data?.length > 0) {
        let final_arr = resp?.data?.map((cont) => {
          return { label: cont?.state_name, value: cont.state_name }
        })
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[5].isVisible = true;
          newState[5].dataArr = [
            { label: 'Select', value: '' },
            ...final_arr,
          ];
          return newState;
        });
      }
    }
  }

  const CountryListCalling = async (from, to, payment_status) => {
    let payload = { type: "1" };
    if (payment_status) {
      payload['payment_status'] = payment_status;
    }
    if (from) payload['from'] = formatDate(from, 'yyyy-mm-dd') + " 00:00:00"
    if (to) payload['to'] = formatDate(to, 'yyyy-mm-dd') + " 23:59:00"
    let resp = await countryListingsUnpaid(payload);
    if (resp?.status == 200) {
      //cont?.country_name
      let final_arr = resp?.data?.map((cont) => {
        return { label: cont?.country_name, value: cont.country_id, id: cont.country_id }
      })
      setFiltersArray((prevState) => {
        let newState = [...prevState];
        newState[4].dataArr = [
          { label: 'Select', value: '' },
          ...final_arr,
        ];
        return newState;
      });
    }
  }

  const handleStateListCalling = async (value, index) => {
    //country_id 
    if (value && index) {
      let payload = { country_id: value };
      let resp = await stateListings(payload);
      if (resp?.status == 200) {
        if (resp?.data?.length > 0) {
          let final_arr = resp?.data?.map((cont) => {
            return { label: cont?.state_name, value: cont.state_name }
          })
          setFiltersArray((prevState) => {
            let newState = [...prevState];
            newState[5].dataArr = [
              { label: 'Select', value: '' },
              ...final_arr,
            ];
            return newState;
          });
        }
      }
    }
  }

  const handleCompanyCalling = async (from, to, payment_status, country, state, due_days) => {
    let payload = {};
    if (from && to) {
      payload['to'] = formatDate(to, 'yyyy-mm-dd') + " 23:59:59"
      payload['from'] = formatDate(from, 'yyyy-mm-dd') + " 00:00:00"
      if (payment_status) payload['payment_status'] = payment_status;
      if (country) payload['country_id'] = country;
      if (state) payload['state'] = state;
      if (due_days) payload['due_days'] = due_days;
      let resp = await companyListings(payload);
      if (resp?.status == 200) {
        if (resp?.data?.length > 0) {
          let final_arr = resp?.data?.map((comp) => {
            return { label: comp?.company_name, value: comp?.company_name }
          });
          setFiltersArray((prevState) => {
            let newState = [...prevState];
            newState[7].dataArr = [
              { label: 'Select', value: '' },
              ...final_arr,
            ];
            return newState;
          });
        }
      }
      else {
        toast.error('To and From Date Required');
      }
    }
  }

  const handleCompanyList = async (value, index) => {
    if (value && index) {
      let payload = { from: filtersArray[0]?.value, to: filtersArray[1].value, dueDays: value };
      if (filtersArray[0]?.value && filtersArray[1].value) {
        let resp = await companyListings(payload);
        if (resp?.status == 200) {
          if (resp?.data?.length > 0) {
            let final_arr = resp?.data?.map((comp) => {
              return { label: comp?.company_name, value: comp?.company_name }
            });
            setFiltersArray((prevState) => {
              let newState = [...prevState];
              newState[7].dataArr = [
                { label: 'Select', value: '' },
                ...final_arr,
              ];
              return newState;
            });
          }
        }
      }
      else {
        toast.error('To and From Date Required');
      }

    }

  }

  const handleFilter = (data, allDatavalues, dataWithValue) => {

    let obj = { tab: allParams?.tab, ...dataWithValue, page: String(1) };
    setAllParams({
      ...obj
    });
    setPage(Number(1));
  };

  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    dispatch(setResetOutstandingPaymenttData([]));
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[2].isVisible = true;
      newState[3].isVisible = false;
      newState[4].isVisible = false;
      newState[5].isVisible = false;
      newState[6].isVisible = true;
      newState[7].isVisible = false;
      newState[0].value = '';
      newState[1].value = '';
      newState[2].value = '';
      newState[3].value = '';
      newState[4].value = '';
      newState[5].value = '';
      newState[6].value = '';
      newState[7].value = '';
      return newState;
    });
  };


  const handleDownloadCSV = async (arr, allDatavalues, dataWithValue) => {
    if (filtersArray[0]?.value && filtersArray[1]?.value) {
      let newPayload = { ...dataWithValue };
      if (newPayload?.to) {
        newPayload['to'] = formatDate(newPayload?.to, 'yyyy-mm-dd') + " 23:59:59"
      }
      if (newPayload?.from) {
        newPayload['from'] = formatDate(newPayload?.from, 'yyyy-mm-dd') + " 00:00:00"
      }

      let resp = await getPaymentReportFile(newPayload);
      if (resp.status) {
        setFileResp(resp);
        // formik?.resetForm()
        downloadBlobFile(
          resp.data,
          `OutstandingPaymentReport${newPayload?.from}-/-${newPayload.to}`,
        );
      }
    }
    else {
      toast.error('To and From Date Required');
    }
  }

  useEffect(() => {
    if (outstandingPaymentData?.items?.orders?.length >= 0) {
      setTableListing(outstandingPaymentData?.items?.orders);
    } else {
      setTableListing([])
    }
  }, [outstandingPaymentData.items]);

  useEffect(() => {
    dispatch(setResetDailyReportData());
  }, []);

  useEffect(() => {
    dispatch(setResetOutstandingPaymenttData());
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);

    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }

    }
  }, [allParams]);

  const getOrderListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...allParams,
      ...params,
    };

    let newPayload = { ...payload }
    if (newPayload?.payment_status == 1) {
      newPayload['payment_type'] = payload?.payment_type
    }

    if (newPayload?.to) {
      newPayload['to'] = formatDate(newPayload?.to, 'yyyy-mm-dd') + " 23:59:59"
    }
    if (newPayload?.from) {
      newPayload['from'] = formatDate(newPayload?.from, 'yyyy-mm-dd') + " 00:00:00"
    }
    if (newPayload?.from && newPayload?.to) {
      dispatch(getOutStandingPaymentData(newPayload));
      setPage(Number(payload.page));
    }
  };

  const handleConfirm = async () => {
    setLoading(true);
    let resp = await getDailySalesReport(formik.values);
    if (resp.status) {
      setFileResp(resp);
      formik?.resetForm();
      downloadBlobFile(
        resp.data,
        `DailySalesReport${formik?.values.from}-/-${values.to}`,
      );
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      from: '',
      to: '',
      payment_status: 'ALL',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      from: Yup.string().required('From Date is required.'),
      to: Yup.string().required('To Date is required.'),
      payment_status: Yup.string().required('Payment Type is required.'),
    }),
    onSubmit: checking,
  });

  async function checking() {
    setLoading(true);
    let newPayload = {
      ...formik.values,
      payment_status:
        formik?.values?.payment_status === 'ALL'
          ? ''
          : formik?.values?.payment_status === 'Paid'
            ? '1'
            : formik?.values?.payment_status === 'Unpaid'
              ? '0'
              : '',
    };
    let resp = await getPaymentReportFile(newPayload);
    if (resp.status) {
      setFileResp(resp);
      // formik?.resetForm()
      downloadBlobFile(
        resp.data,
        `OutstandingPaymentReport${formik?.values.from}-/-${formik?.values.to}`,
      );
    }
    setLoading(false);
  }

  const handleConfirmUnpaid = async (value) => {
    if (value && deleteData) {
      const resp = await paidStatusChange({ order_id: deleteData });
      if (resp) {
        let payloadForLogs = {
          action: action?.markAsUnpaid,
          module: modulesNames?.crm,
          description: logsMessagesList?.crm?.outstandingPaymentMarkAsUnpaid,
        };
        await adminAddLogs(payloadForLogs);
        let payload = { ...allParams, page: '1' }
        getOrderListing(payload)
        setPage(1)
        setDeleteData(null)
      }
    } else {
      setDeleteData(null)
    }

  }

  const handleCofirmModalOpen = (value) => {
    setConfirmUnpaid(value);
  }

  const actionBody = (data) => {
    return (
      <>
        {data?.order?.payment_status === '1' &&
          <div style={{ display: 'flex' }}>
            <CustomButton
              toolTipTittle={'Edit Visible Keyword'}
              onClick={() => {
                handleCofirmModalOpen(true)
                setDeleteData(data?.order?.order_id)

              }}
              className="buttonDense noMinWidth"
              sx={{
                bgcolor: 'buttonPrimary.main',
                color: 'buttonPrimary.contrastText',
              }}
            >
              Mark Unpaid
            </CustomButton>

          </div>
        }
      </>

    );
  };
  return (
    <>
      <Grid container spacing={2} p={1.5} sx={{ placeItems: 'center' }}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Total Records : {outstandingPaymentData?.items?.totalCount}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Total Amount (INR) :{' '}
            {outstandingPaymentData.items?.totalEarning && currencyConversion(outstandingPaymentData.items?.totalEarning) || '0'}
          </Typography>
        </Grid>

      </Grid>
      <>
        <MatTable
          loading={outstandingPaymentData?.loading}
          showCheckbox={false}
          columns={column}
          data={tableListing}
          onApplyFilter={(data = {}) => {
            getListing({
              page: String(1),
              start_date: '',
              end_date: '',
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
            if (Object.keys(data).length > 0) {
              setAllParams({
                ...allParams,
                page: String(1),
                ...getObjectSubset(data, 'start_date', 'end_date'),
              });
            } else {
              setAllParams({
                page: String(1),
              });
            }
          }}
          page={page}
          perPage={limit}
          showPageLimit={true}
          pageLimitArray={[20, 50, 100, 200]}
          handleChangePageLimit={(e) => {
            setLimit(e.target.value);
            setAllParams({
              ...allParams,
              page: String('1'),
              limit: Number(e.target.value),
            });
          }}
          disableSearch
          rangeDateFilter
          total={outstandingPaymentData?.items?.totalCount || 0}
          setPage={setPage}
          action={actionBody}
          handleSearch={(searchTerm) => {
            getListing({
              page: String(1),
              search: searchTerm,
            });
            setAllParams({
              ...allParams,
              page: String(1),
              search: searchTerm,
            });
          }}
          handlePageChange={(newPage) => {
            getOrderListing({
              page: String(newPage),
            });
            setAllParams({
              ...allParams,
              page: String(newPage),
            });
          }}

          toolbarEnable={true}
          pagination={true}
          filtersUrlData={allParams || {}}

          customFilter={() => (
            <OutstandingFilters
              filtersArray={filtersArray}
              handleClick={handleFilter}
              handleClear={handleClear}
              handleDownloadCSV={handleDownloadCSV}
            />
          )}
        />
      </>
      {confirmUnpaid && <ConfirmationModal open={confirmUnpaid} handleConfirm={handleConfirmUnpaid} setOpen={handleCofirmModalOpen} />}


    </>
  );
}

export default OutstandingPayment;
