import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';
import { formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SuspendImageModal from '../Modals/SuspendImageModal';
import MatTable from '../Tables/MatTable';
import { getListOfImageKeywords } from '../../Services/keywordsApis';
import { insertIntoElasticSearch, insertIntoElasticSearchAll, videosListings, videosListingsNew } from '../../Services/videosApis';
import { videosItemsSelector } from '../../redux/videos';
import ConfirmationModal from '../Modals/ConfirmationModal';
import EditVideosDetailModal from '../Modals/EditVideosDetailModal';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

const limit = 100;

const column = [
  {
    label: 'Video',
    id: 'image_url',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}
      >
        <img style={{ maxWidth: 200, maxHeight: 200 }} src={`${columnData}`} />
      </span>
    ),
  },
  {
    label: 'Video ID',
    id: 'Name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap', }}  >
        {columnData}
      </span>
    ),
  },
  {
    label: 'Rank',
    id: 'rank',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Shoot ID',
    id: 'shootid',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Pricing(INR)',
    id: 'pricing',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Suspended Date',
    id: 'suspendate',
    format: (columnData) => (
      <>
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          {formatDate(columnData, 'dd/mm/yyyy')}
        </span>

      </>
    ),
  },
];

function AllVideosNew() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const [imageEditModal, setImageEditModal] = useState(false);
  const [imageSuspendModal, setImageSuspendModal] = useState(false);
  const [imageKeyword, setImageKeyword] = useState(false);
  const [editImageData, setEditImageData] = useState({});
  const { loading, allVideosNew } = useSelector(videosItemsSelector);
  const dispatch = useDispatch();
  const [currentImageName, setCurrentImageName] = useState('');
  const [keywordForTheChoosenImage, setKeywordForTheChoosenImage] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmMove, setConfirmMove] = useState(false);
  const navigate = useNavigate()
  const location = useLocation();
  const [selectedData, setSelectedData] = useState(null);


  const handleModal = (value = false) => {

    setImageEditModal(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };


  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  };

  const handleCofirmModalOpenElastic = (value) => {
    setConfirmMove(value);

  };
  //handleConfirmElasticAll

  const handleConfirmElastic = async (value) => {
    if (value && selectedData) {
      const resp = await insertIntoElasticSearch({ video_id: JSON.stringify(selectedData?.video_id) });
      if (resp) {
        let payloadForLogs = {
          action: action?.addedToElastic,
          module: modulesNames?.videos,
          description: logsMessagesList?.video?.videoAddedToElastic,
        };
        await adminAddLogs(payloadForLogs);
        getListing();
        setSelectedData(null);
      }
    } else {
      setSelectedData(null);
    }
  };

  const handleConfirmElasticAll = async (value) => {
    if (value) {
      const resp = await insertIntoElasticSearchAll({ video_id: 'all' });
      if (resp) {
        let payloadForLogs = {
          action: action?.addedToElastic,
          module: modulesNames?.videos,
          description: logsMessagesList?.video?.videoAddedToElastic,
        };
        await adminAddLogs(payloadForLogs); 
        getListing();

      }
    }
  };

  const handleAdd = (value) => {
    setOpen(value);
  };



  const handleSuspendModal = (value = false) => {
    setImageSuspendModal(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };
  const handleImageKeywordModal = (value = false) => {
    setImageKeyword(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      insertion_status: 'new',
      // order_status: String(1),
      ...allParams,
      ...params,

    };
    dispatch(videosListingsNew(payload));
    setPage(Number(payload.page));
    setEditImageData({});
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  const handleAllKeywordsForimage = async (data) => {

    let response = await getListOfImageKeywords(data?.Name);
    if (response?.status == 200) {
      setKeywordForTheChoosenImage(response?.data);
      handleImageKeywordModal(true)
    }

  }

  const handleAllFunctions = (data) => {
    setEditImageData(data);
    handleAllKeywordsForimage(data);
  }

  //making data format for table input
  useEffect(() => {
    if (allVideosNew?.items?.users?.length >= 0) {
      let data = allVideosNew?.items?.users?.map((item) => {
        return {
          image_url: `${ApiConfig.BASE_IMAGE_URL}${item?.shootid}/${item?.rank}-${item?.video_name}.jpg`,
          Name: item?.video_name,
          contributor: item,
          video_id: item?.video_id,
          visible_ids: item?.visible_ids,
          rank: item.rank, //=>edit
          shootid: item.shootid,
          pricing: item.pricing, //=>edit
          suspendate: item.suspendate,
          contributor: item.contributor,
          available: item.available,
          type: item.countirbute_type, //=>edit dropdown value [I, S]
          createddate: item.createddate,
          imgstatus: item.imgstatus,
          suspend: item.imgstatus,
          rank1: item.rank1, //=>edit
          keywords: item.Name, //=>edit
          is_star: item?.is_star,
          nonexclusive: item?.nonexclusive
        };
      });
      setTableListing(data);
    }
  }, [allVideosNew.items]);

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <Link className='textDecoration-none' to={`/videos/videoKeywords/${data?.Name}`} >
            <CustomButton className="buttonDense" onClick={() => {
            }} sx={{ bgcolor: 'buttonInfo.main' }}>Keywords</CustomButton>
          </Link>
          <CustomButton className="buttonDense" onClick={() => {
            handleCofirmModalOpen(true)
            setSelectedData(data)

          }}>Live</CustomButton>
          <CustomButton
            className="buttonDense"
            sx={{
              fontSize: '12px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
            onClick={() => {
              handleModal(true);
              setEditImageData(data);
            }}
          >
            Edit
          </CustomButton>
        </div>
      </>
    );
  };

  return (
    <>

      <MatTable
        loading={allVideosNew?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={500}
        total={allVideosNew?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        toolBarButton={() => {
          return (
            <>
              {allVideosNew?.items?.totalCount > 0 &&
                <CustomButton
                  onClick={() => {
                    handleCofirmModalOpenElastic(true)
                  }}
                  color={'buttonPrimary'}
                  sx={(theme) => ({
                    padding: '10px 10px', marginRight: 1,
                    [theme.breakpoints.down("md")]: {
                      flex: 1,
                    }
                  })}
                >
                  Make it Live
                </CustomButton>
              }
            </>
          );
        }}
        pagination={true}
        filtersUrlData={allParams || {}}
      />
      <EditVideosDetailModal
        open={imageEditModal}
        handleOpen={handleModal}
        data={editImageData}
        getListing={getListing}
      />
      <SuspendImageModal
        open={imageSuspendModal}
        handleOpen={handleSuspendModal}
        data={editImageData}
        getListing={getListing}
      />
      {confirmDelete && (
        <ConfirmationModal
          open={confirmDelete}
          handleConfirm={handleConfirmElastic}
          setOpen={handleCofirmModalOpen}
        />
      )}

      {confirmMove && (
        <ConfirmationModal
          open={confirmMove}
          handleConfirm={handleConfirmElasticAll}
          setOpen={handleCofirmModalOpenElastic}
        />
      )}
    </>
  );
}

export default AllVideosNew;
