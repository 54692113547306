import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { adminAddLogs, updateSettingApi } from '../../Services/commonService';
import { getInitialDataFormik, humanize } from '../../lib/helper';
import AppSettingsFields from '../AppSettings/AppSettingsFields';
import {
  action,
  logsMessagesList,
  modulesNames,
} from '../../data/logsMessages';

export default function EditAppSettingDataModal({
  open,
  handleOpen,
  data,
  extraData,
  parentKey,
  subParentKey,
  jsonParsing,
  getListing,
  headerTitle,
  getInitialDataFormikVlidationSchema,
}) {
  const [loading, setLoading] = React.useState(false);

  const handleDataSubmit = async (values, { resetForm }) => {
    try {
      if (parentKey) {
        setLoading(true);
        let keys = Object.keys(values);
        keys.forEach(async (item) => {
          if (jsonParsing) {
            let updatedValue = { ...extraData?.data };
            updatedValue[item] = values[item];
            let payload = {
              parent_key: parentKey,
              key_name: subParentKey,
              key_value: JSON.stringify(updatedValue),
            };
            let resp = await updateSettingApi(payload);
            if (resp) {
              let descriptionPayload =
                parentKey == 'exclusive'
                  ? logsMessagesList?.setting?.exclusivePriceUpdate
                  : parentKey == 'contact_us_emails'
                  ? logsMessagesList?.setting?.emailSetUpUpdated
                  : parentKey == 'stars' && subParentKey == 'category'
                  ? logsMessagesList?.setting?.starCategoryUpdate
                  : parentKey == 'stars' && subParentKey == 'duration'
                  ? logsMessagesList?.setting?.starDurationUpdate
                  : parentKey == 'stars' && subParentKey == 'statewise'
                  ? logsMessagesList?.setting?.starStatewiseUpdate
                  : parentKey == 'stars' && subParentKey == 'countrywise'
                  ? logsMessagesList?.setting?.starCountrywiseUpdate
                  : '';

              let payloadForLogs = {
                action: action?.update,
                module: modulesNames?.settings,
                description: descriptionPayload,
              };

              await adminAddLogs(payloadForLogs);
              await getListing();
              handleOpen(false);
              resetForm();
            }
          } else {
            let payload = {
              parent_key: parentKey,
              key_name: item,
              key_value: values[item],
            };
            let resp = await updateSettingApi(payload);
            if (resp) {
              // let payloadForLogs = {
              //   action: action?.update,
              //   module: modulesNames?.settings,
              //   description: logsMessagesList?.setting?.emailSetUpUpdated,
              // };
              // await adminAddLogs(payloadForLogs);
              let descriptionPayload =
                parentKey == 'exclusive'
                  ? logsMessagesList?.setting?.exclusivePriceUpdate
                  : parentKey == 'contact_us_emails'
                  ? logsMessagesList?.setting?.emailSetUpUpdated
                  : parentKey == 'stars' && subParentKey == 'category'
                  ? logsMessagesList?.setting?.starCategoryUpdate
                  : parentKey == 'stars' && subParentKey == 'duration'
                  ? logsMessagesList?.setting?.starDurationUpdate
                  : parentKey == 'stars' && subParentKey == 'statewise'
                  ? logsMessagesList?.setting?.starStatewiseUpdate
                  : parentKey == 'stars' && subParentKey == 'countrywise'
                  ? logsMessagesList?.setting?.starCountrywiseUpdate
                  : '';

              let payloadForLogs = {
                action: action?.update,
                module: modulesNames?.settings,
                description: descriptionPayload,
              };
              await adminAddLogs(payloadForLogs);
              await getListing();
              handleOpen(false);
              resetForm();
            }
          }
          setLoading(false);
        });
      }
    } catch (e) {
      console.log(e, 'error');
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: getInitialDataFormik(data, 'key_name', 'key_value'),
    enableReinitialize: true,
    validationSchema: Yup.object({
      ...getInitialDataFormikVlidationSchema(data, 'key_name'),
    }),
    onSubmit: handleDataSubmit,
  });

  return (
    <>
      <Dialog
        maxWidth={'xs'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
        fullWidth
      >
        <DialogTitle variant="h3">{headerTitle}</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              {data?.map((item, index) => {
                return (
                  <Grid item xs={12} key={item.key_name}>
                    <AppSettingsFields
                      name={`${item?.key_name}`}
                      label={`${humanize(item?.key_name)}`}
                      placeholder={`Enter ${humanize(item?.key_name)}`}
                      value={formik.values[item.key_name]}
                      onChange={(e) => {
                        formik.setFieldValue(item.key_name, e.target.value);
                      }}
                      error={
                        formik.touched[item.key_name] &&
                        Boolean(formik.errors[item.key_name])
                      }
                      helperText={
                        formik.touched[item.key_name] &&
                        formik.errors[item.key_name]
                      }
                    />
                  </Grid>
                );
              })}
              <Grid item xs={12} textAlign={'right'}>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <CustomButton
                    color={'buttonPrimary'}
                    type="submit"
                    loading={loading}
                  >
                    Save
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      handleOpen(false);
                      formik.resetForm();
                    }}
                    disabled={loading}
                  >
                    Close
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

EditAppSettingDataModal.defaultProps = {
  open: false,
  handleOpen: () => {},
  data: [],
  extraData: [],
  parentKey: '',
  subParentKey: '',
  jsonParsing: false,
  getListing: () => {},
  headerTitle: 'Edit AppSetting',
  getInitialDataFormikVlidationSchema: () => {},
};
