import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  fetchInPromiseFile,
  formatDate,
  getNewParameters,
  getObjectSubset,
  getParameters,
} from '../../lib/helper';
import { contributorItemsSelector } from '../../redux/contributor';
import {
  contributorFolderListings,
  downloadImageContributor,
} from '../../Services/ContributorApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CryptoJS from 'crypto-js';
import MatTable from '../Tables/MatTable';
import ApiConfig from '../../config/ApiConfig';
import { toast } from 'react-toastify';
import FolderLimitModal from '../Modals/FolderLimitModal';
import AllFilters from '../Order/AllFilters';
import { SearchFolderBy } from '../../data/userData';
import { getItemFromStorage } from '../../storage/Storage';
import SendEmailModal from '../Modals/SendEmailModal';
import EmailIcon from '@mui/icons-material/Email';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import {
  action,
  logsMessagesList,
  modulesNames,
} from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';
const limit = 10;

const column = [
  {
    label: 'Folder Name',
    id: 'folder_name',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => {
      if (row?.folder_name) {
        return columnData ? (
          <Link
            className="textDecoration-none link-text"
            to={`${row?.folder_name}`}
            state={row?.folder_name}
          >
            {columnData}
          </Link>
        ) : (
          <Typography variant="span" style={{ whiteSpace: 'nowrap' }}>
            {columnData}
          </Typography>
        );
      }
      return null;
    },
  },
  {
    label: 'Image Count',
    id: 'folderImages',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <Typography variant="span" style={{ whiteSpace: 'nowrap' }}>
        {columnData}
      </Typography>
    ),
  },
  {
    label: 'Folder Limit',
    id: 'images_limit',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <Typography variant="span" style={{ whiteSpace: 'nowrap' }}>
        {columnData === null ? 20 : columnData}
      </Typography>
    ),
  },
  {
    label: 'Created on',
    id: 'created_at',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <Typography variant="span" style={{ whiteSpace: 'nowrap' }}>
        {/* {columnData} */}
        {columnData === null ? ' ' : formatDate(columnData, 'dd-mm-yyyy')}
      </Typography>
    ),
  },
  // format: (columnData) => (
  //   <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
  //     {columnData || 'N/A'}
  //   </span>
  // ),
];

function ContributorFolderList() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [updateData, setUpdateData] = useState(null);
  const [value, setValue] = useState({});
  const [allParams, setAllParams] = useState(getParameters());
  const { contributorFolderList } = useSelector(contributorItemsSelector);
  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const contributorId = location.state;
  const [contributorData, setContributorData] = useState(contributorId);
  const [openModal, setOpenModal] = useState(false);
  const [folderLimit, setFolderLimit] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState('');
  const handleCofirmModalOpen = (value) => {
    setConfirmUpdate(value);
  };

  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'Search By',
      id: 'search_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: SearchFolderBy,
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          // newState[index].value = e.target.value;
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);
  useEffect(() => {
    let params = getParameters();
    setAllParams({
      contributor_id: contributorData,
      ...params,
    });
  }, [location?.search]);
  useEffect(() => {
    // let data = getNewParameters(allParams)
    // if (data?.search !== location.search) {
    //     navigate(data.newUrl, { replace: false })
    //     if (data?.parameters?.page) {
    //         setPage(Number(data?.parameters?.page))
    //     }
    // }
    getListing();
  }, [allParams]);
  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getListing(
      {
        page: String(1),
        contributor_id: contributorData,
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      contributor_id: contributorData,
      ...dataWithValue,
    });
  };
  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;

      newState[0].value = '';
      newState[1].value = '';
      return newState;
    });
  };
  const handleDownloadImage = async (data) => {
    try {
      const encryptedImageName = CryptoJS.AES.encrypt(
        String(data?.image_name),
        process.env.REACT_APP_DOWNLOAD_AES_SECRET_KEY,
      ).toString();
      const encryptedContributorId = CryptoJS.AES.encrypt(
        String(data?.contributor_id),
        process.env.REACT_APP_DOWNLOAD_AES_SECRET_KEY,
      ).toString();
      const encryptedFolderName = CryptoJS.AES.encrypt(
        String(data?.folder_name),
        process.env.REACT_APP_DOWNLOAD_AES_SECRET_KEY,
      ).toString();
      const encryptedPayload = {
        image_name: encryptedImageName,
        folder_name: encryptedFolderName,
        contributor_id: encryptedContributorId,
      };
      let resp = await toast.promise(
        fetchInPromiseFile(
          `${ApiConfig.downloadFolderImages}?contributor_id=${data?.contributor_id}&folder_name=${data?.folder_name}`,
          {
            headers: {
              Authorization: getItemFromStorage('token'),
            },
          },
        ),
        // await toast.promise(fetchFile(`${ApiConfig.downloadFolderImages}?contributor_id=${encryptedPayload?.contributor_id}&folder_name=${encryptedPayload?.folder_name}`),
        {
          pending: 'Downloading your file....',
          success:
            'Please wait while your Image/s is/are downloading. It may take a few moments.',
          error: 'Failed to download file.',
        },
      );
      let payloadForLogs = {
        action: action?.download,
        module: modulesNames?.contributors,
        description: logsMessagesList?.contributor?.contributorFolderDownload,
      };
      await adminAddLogs(payloadForLogs);
    } catch (e) {
      console.log(e);
    }
  };
  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      contributor_id: contributorData,
      // ...getObjectSubset({
      //     start_date: formatDate(new Date().setDate(new Date().getDate() - 30), "yyyy-mm-dd") + ' 00:00:00',
      //     end_date: formatDate(new Date(), "yyyy-mm-dd") + ' 23:59:59'
      // }, 'start_date', 'end_date'),
      ...allParams,
      ...params,
    };
    dispatch(contributorFolderListings(payload));
    setPage(Number(payload.page));
  };
  useEffect(() => {
    getListing();
  }, []);
  //making data format for table input
  useEffect(() => {
    if (contributorFolderList?.items?.resultdata?.length >= 0) {
      let data = contributorFolderList?.items?.resultdata?.map((item) => {
        return {
          // comment: item?.comment,
          images_limit: item?.images_limit,
          contributor_id: item?.contributor_id,
          created_at: item?.created_at,
          // name: item?.contributor?.name,
          // status: item?.status,
          folder_name: item?.folder_name,
          folderImages: item?.folderImages,
          // contributor_upload_image_id: item?.contributor_upload_image_id
        };
      });
      setTableListing(data);
    }
  }, [contributorFolderList?.items]);

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonInfo.main',
            }}
            toolTipTittle={'Send Email'}
            onClick={() => {
              setSelectedFolder(data?.folder_name);
              setOpenModal(true);
              setFolderLimit(data?.images_limit);
            }}
          >
            <EmailIcon />
          </CustomButton>
          <CustomButton
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonInfo.main',
            }}
            toolTipTittle={'Update Folder Limit'}
            onClick={() => {
              setUpdateData(data);
              handleCofirmModalOpen(true);
            }}
          >
            <FormatListNumberedRtlIcon />
          </CustomButton>
          <CustomButton
            sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px' }}
            toolTipTittle={'Download Folder'}
            onClick={() => handleDownloadImage(data)}
          >
            {' '}
            <FileDownloadIcon />
          </CustomButton>
        </div>
      </>
    );
  };

  const handleFolderDownload = async (data) => {
    if (data) {
      const resp = await downloadImageContributor(data);
      if (resp) {
        toast.success('Image downloaded.');
      }
    } else {
      toast.error('Limit cannot be greater than 50.');
    }
  };
  return (
    <CustomBox
      padding={0}
      sx={{
        minHeight: 200,
        display: 'flex',
        marginTop: '20px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <MatTable
        loading={contributorFolderList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={contributorFolderList?.items?.count || 0}
        setPage={setPage}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        columnWiseData={[
          {
            id: 'contributor_id',
            value: getNewParameters(allParams)?.search,
          },
        ]}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        // filterEnable
        disableSearch
        rangeDateFilter
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
          />
        )}
      />
      {confirmUpdate && (
        <FolderLimitModal
          open={confirmUpdate}
          status={value}
          updateData={updateData}
          setUpdateData={setUpdateData}
          getListing={getListing}
          setPage={setPage}
          setOpen={handleCofirmModalOpen}
        />
      )}
      <SendEmailModal
        open={openModal}
        setOpen={setOpenModal}
        folder_name={selectedFolder}
        contributor_id={contributorData}
        folderLimit={folderLimit}
      />
    </CustomBox>
  );
}

export default ContributorFolderList;
