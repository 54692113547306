import { configureStore } from '@reduxjs/toolkit';

import loginReducer from './login';
import orderReducer from './orderListing';
import dashboardReducer from './dashboard';
import subscriptionReducer from './subscription';
import contactQueriesReducer from './contactQueries';
import contributorReducer from './contributor';
import keywordReducer from './keyword';
import userReducer from './user';
import companyReducer from './company';
import categoryReducer from './category';
import imageReducer from './image';
import followUpsReducer from './followUps';
import autoSuggReducer from './autoSugg';
import logsReducer from './logs';
import videosReducer from './videos';
import adminUsersReducer from './adminUsers';
import starIndustriesReducer from './starIndustries';
import irnReducer from './irn';
import commonReducer from './common';
import menuReducer from './userMenus';
import contributorReportReducer from './contributorReport';
import reportReducer from './reports';
import cmsReducer from './cms';
import modelReducer from './model';
import masterReducer from './master';
import adminLogsReducer from  './adminLogs';

export const store = configureStore({
  reducer: {
    loginUserData: loginReducer,
    menuList: menuReducer,
    reportList: reportReducer,
    contributorReportList: contributorReportReducer,
    dashboardCount: dashboardReducer,
    orderList: orderReducer,
    subscription: subscriptionReducer,
    irnList: irnReducer,
    contactQueries: contactQueriesReducer,
    user: userReducer,
    company: companyReducer,
    category: categoryReducer,
    starIndustries: starIndustriesReducer,
    model: modelReducer,
    master: masterReducer,
    contributor: contributorReducer,
    keyword: keywordReducer,
    imageList: imageReducer,
    logs: logsReducer,
    videos: videosReducer,
    adminUsers: adminUsersReducer,
    commonData: commonReducer,
    autoSuggestList:autoSuggReducer,
    cmsData:cmsReducer,
    followUpsDataList: followUpsReducer,
    cmsData:cmsReducer,
    adminLogs: adminLogsReducer,
  },
});
