import { Delete, Edit } from '@material-ui/icons';
import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchBy, SearchByAssigned } from '../../data/companyData';
import { DATA_LIMIT } from '../../data/constants';
import { UserIdentityData } from '../../data/userData';
import { breakStringIntoLines, createSubString, findObjectInArray, formatDate, getArrayOfObjJoinToStringForKey, getNewParameters, getObjectSubset, getParameters, removeExtraSpace } from '../../lib/helper';
import { companyItemsSelector } from '../../redux/company';
import { companyAssignedListings, companyAssignedListingsNoRedux, companyListings, companyUnassignedListings, deleteCompany } from '../../Services/companyApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import AddCompanyMaster from '../Modals/AddCompanyMaster';
import ConfirmationModal from '../Modals/ConfirmationModal';
import EditCompanyDetailModal from '../Modals/EditCompanyDetailModal';
import UpdateShortCompanyNameModal from '../Modals/UpdateShortCompanyNameModal';
import MatTable from '../Tables/MatTable';
import { Tab } from '@mui/material/Tab';
import AllFilters from '../Order/AllFilters';


const column = [
  {
    label: 'Action',
    id: 'user_id',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (<>
      <CustomButton toolTipTittle={'Edit short company name'} className='buttonDense noMinWidth' onClick={() => {
        option([columnData], () => { }, row)
      }} sx={{ bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Edit /></CustomButton>
    </>
    ),
  },
  {
    label: 'Company Name',
    id: 'company_name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-word' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Email',
    id: 'user_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Group Name',
    id: 'company_group_name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-word' }}
      >
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'Identity',
    id: 'identity',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-word' }}
      >
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'Company Short Name',
    id: 'short_company_name',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (<>
      {columnData}
    </>
    ),
  },
  {
    label: 'Associations',
    id: 'user_associations',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-word' }}
      >
        {/* {getArrayOfObjJoinToStringForKey(columnData, 'association') || '-'} */}
        {!!columnData.length ? <Tooltip title={columnData.length && getArrayOfObjJoinToStringForKey(columnData, 'association')}><span style={{ display: 'inline-block', marginBottom: 10 }}>
          {createSubString(getArrayOfObjJoinToStringForKey(columnData, 'association'), () => { }, 62)}
        </span></Tooltip> : '-'}
      </span>
    ),
  },
  {
    label: 'Company Address',
    id: 'address',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content', wordBreak: 'break-word' }}
      >
        {columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}
      </span>
    ),
  }
];

function CompanAssignedList() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const [limit, setLimit] = useState(20);
  const { loading, companyAssignedList } = useSelector(companyItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [clearSelectedCheckbox, setClearSelectedCheckbox] = useState(false);
  const [updateShortCompanyNameModal, setUpdateShortCompanyNameModal] = useState(false);
  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'Search By',
      id: 'search_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value;
          newState[1].value='';
          return newState;
        });
      },
      style: {},
      dataArr: SearchByAssigned,
      props: {},
      isVisible: true,
      row:3,
      rowFlexible:true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);
  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };
  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[0].value = '';
      newState[1].value = '';
      return newState;
    });
  };

  const handleAdd = (value = false) => {
    setAddCompanyModal(value)
  }
  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      filled: "1",
      limit: limit,
      // status: String(1),
      ...allParams,
      ...params,
    };
    dispatch(companyAssignedListings(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  //making data format for table input
  useEffect(() => {
    if (companyAssignedList?.items?.rows?.length >= 0) {
      setTableListing(companyAssignedList?.items?.rows);
    }
  }, [companyAssignedList.items]);

  const handleClearSelectedCheckbox = (value = false) => {
    setClearSelectedCheckbox(value || false)
  }

  const handleModal = (value = false) => {
    setUpdateShortCompanyNameModal(value);
    if (!value) {
      setTimeout(() => {
        setSelectedUserIds([]);
        setSelectedData(null)
      }, 500);
    }
  };

  const handleBulkUpdate = async (data, resetState, rowData) => {
    try {
      handleModal(true);
      setSelectedUserIds(data)
      let resp = rowData
      if (data.length && !rowData) {
        resp = await companyAssignedListingsNoRedux({
          page: String(1),
          filled: "1",
          limit: 1,
          search: data[0],
          search_by: 'user_id'
        })
        resp = resp?.rows[0]
      }
      setSelectedData(resp || null)
    } catch (e) { }
  }

  return (
    <>
      <MatTable
        loading={companyAssignedList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'search_by'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'search_by'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        page={page}
        perPage={limit}
        showPageLimit={true}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        total={companyAssignedList?.items?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        columnWiseData={[
          {
            id: 'user_id',
            value: handleBulkUpdate,
          }
        ]}

        checkboxEnable={true}
        filterEnable={false}
        disableSearch={true}
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
          />
        )}
        customCheckboxDataKey={'user_id'}
        onTableToolbarRightClick={handleBulkUpdate}
        clearSelectedCheckbox={clearSelectedCheckbox}
        handleClearSelectedCheckbox={handleClearSelectedCheckbox}// requred to clear filter and refresh state after api call 
        onTableToolbarRightSection={<CustomButton sx={(theme) => ({
          fontSize: '12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText',
          [theme.breakpoints.down("md")]: {
            flex: 1,
          },
        })}
        >Bulk Update</CustomButton>}
       
      
      />
      {updateShortCompanyNameModal && <UpdateShortCompanyNameModal
        open={updateShortCompanyNameModal}
        handleOpen={handleModal}
        dataArray={selectedUserIds}
        getListing={getListing}
        selectedData={selectedData}
        handleClearSelectedCheckbox={handleClearSelectedCheckbox}
      />}
    </>
  );
}

export default CompanAssignedList;
