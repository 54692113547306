import { Box, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import * as Yup from 'yup';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import ProposalFields from './ProposalFields';
import Divder from '../CommonComp/Divder';
import { imageListingsNoRedux } from '../../Services/imageApis';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import ImageSelectionComp from './ImageSelectionComp';
import ImageOrderSummary from './ImageOrderSummary';
import { toast } from 'react-toastify';
import { createProposalApi } from '../../Services/userApis';
import { useLocation, useNavigate } from 'react-router-dom';
import { maxWidth } from '@mui/system';
import IVSelectionComp from './IVSelectionComp';
import { subscriptionListingsNoRedux } from '../../Services/SubscriptionApis';
import IVOrderSummary from './IVOrderSummary';
import { useDispatch, useSelector } from 'react-redux';
import { commonItemsSelector, setCountryData } from '../../redux/common';
import { adminAddLogs, getCountry, getState } from '../../Services/commonService';
import { loginItemsSelector } from '../../redux/login';
import { crypt, encryptData, findObjectInArray, getDataFromAppSetting } from '../../lib/helper';
import { CreateProposalData } from '../../data/CreateProposalData';
import { starIndustriesItemsSelector } from '../../redux/starIndustries';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';

const taxPercent = 18;

function CreateProposalPage({ userId, email }) {
  const { countryData, stateData } = useSelector(commonItemsSelector);
  const { user } = useSelector(loginItemsSelector);
  const { appSetting } = useSelector(commonItemsSelector)
  // const { appSettingData } = useSelector(appSettingDataSelector);
  const { starIndustriesList } = useSelector(starIndustriesItemsSelector);
  const [imagesData, setImagesData] = useState([]);
  const [ivData, setIVData] = useState(null);
  const [ivListingCheck, setIvListingCheck] = useState('standard');
  const [finalPriceForFlexiblePlan, setFinalPriceForFlexiblePlan] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [exclusiveCheck, setExclusiveCheck] = useState(1);
  const [appSettingDurationData, setAppSettingDurationData] = useState([]);
  const [appSettingPriceData, setAppSettingPriceData] = useState([]);
  const [modifiedCountryData, setModifiedCountryData] = useState([]);
  const dispatch = useDispatch();
  const loaction = useLocation();
  const [countryList, setCountryList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [countryShortName, setCountryShortName] = useState('in');
  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState({});
  const [selectedCountry, setSelectedCountry] = useState({});
  const navigate = useNavigate()
  const [imagesDataError, setImagesDataError] = useState({
    error: false,
    helperText: "Please select image quality for all selected images('WEB','SMALL','MEDIUM','LARGE')"
  });
  const [ivDataError, setIVDataError] = useState({
    error: false,
    helperText: "Please select plan for proposal"
  });

  const handleConfirm = async (values, { resetForm }) => {
    let hostName = window.location.origin;
    let payload = {
      ...values,
    }

    if (values.proposal_type == 'NPlan') {
      let imageUpdateData = imagesData?.map((item) => {
        return {
          ...item,
          is_star:"0",
          type: item?.type?.toLowerCase() == 'video' ? "2" : "1"
        }
      })
      let isValid = handleImageConfirm(imageUpdateData)
      if (isValid) {
        payload = {
          ...values,
          images: imageUpdateData,
          total_images: imageUpdateData.length,
          right: (exclusiveCheck == 2) ? "Exclusive" : 'Non-Exclusive',
          // multiState: exclusiveCheck == 3 ? values.multiState?.length ? stateData?.items?.length === values.multiState?.length || values.multiState?.length > 6 ? "Pan India" : values.multiState?.map((i) => `${i.label}`).join(',') : "" : values.multiState,
        }
        let resp = await createProposalApi(payload)
        if (resp) {
          let payloadForLogs = {
            action: action?.add,
            module: modulesNames?.orders,
            description: logsMessagesList?.order?.proposalCreate,
          };
          await adminAddLogs(payloadForLogs);
          let encryptProposal = crypt('proposal_id', resp.data)
          // navigate(`../../userDetail/${userId}`, { replace: true })
          window.open(`${hostName}/proposal/success?proposal_id=${encryptProposal}`, "_blank")
          navigate(-1)
        }
      } else {
        toast.error('Either you forgot to add images for proposal or maybe skiped to select image quality', {
          autoClose: 3000
        })
      }
    } else if (values.proposal_type == 'IV') {
      let isValid = handleIVConfirm(ivData)
      if (isValid) {
        payload = {
          ...values,
          plan_id: ivData.plan_id,
          maximum_downloads: ivData.maximum_downloads,
          plan_validity: ivData.plan_validity,
          plan_type: ivData.plan_type,
          right: "Non-Exclusive"
        }

        let resp = await createProposalApi(payload)
        if (resp) {
          let payloadForLogs = {
            action: action?.add,
            module: modulesNames?.orders,
            description: logsMessagesList?.order?.proposalCreate,
          };
          await adminAddLogs(payloadForLogs);
          let encryptProposal = crypt('proposal_id', resp.data)
          // navigate(`../../userDetail/${userId}`, { replace: true })
          // navigate(-1)
          window.open(`${hostName}/proposal/success?proposal_id=${encryptProposal}`, "_blank")
          navigate(-1)
        }
      } else {
        toast.error('Maybe you forgot to select plan for proposal.', {
          autoClose: 3000
        })
      }
    }
  };

  let gstValidationSchema = {};
  if (Object.keys(selectedState).length) {
    gstValidationSchema = {
      gst_number: Yup.string()
        .trim()
        .matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
          "Please enter valid GST Number"
        )
        .test(
          "isValidGST",
          "Please enter correct GST Number",
          function (value) {
            let isValid = value?.startsWith(
              selectedState?.state_code?.toString().length > 1
                ? selectedState?.state_code
                : `0${selectedState?.state_code}`
            );
            return value === "" || value === null || value === undefined
              ? true
              : isValid;
          }
        ),
    };
  }

  const formik = useFormik({
    initialValues: {
      // email: '',
      user_name: user?.userDetails?.email || '',
      client_name: '',
      heading: '',
      amount: '0',
      discount: 0,
      discounted_amount: '',
      duration: "",
      service_tax: 0,
      final_amount: 0,
      credit_period: "0",
      gst_number: '',
      state: '',
      country: '',
      industry: '',
      territory: 0,
      proposal_type: 'NPlan',
      proposal_of: 'image',
      // exclusive:appSetting?.items?.features?.exclusive[0]?.key_name || ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      // email: Yup.string().email('Please enter valid email id.').required('Email is required.'),
      discount: Yup.number('It should be a number.')
        .min(0, "Value should not be lesser than 0"),
      credit_period: Yup.string().required('Credit period is required.'),
      state: Yup.string().required('This field is required'),
      heading: Yup.string().required('This field is required'),
      user_name: Yup.string().required('This field is required'),
      ...gstValidationSchema
    }),
    onSubmit: handleConfirm,
  });

  const handleImagesData = (data, index) => {
    // let initialData = [...imagesData]
    setImagesData(data)
    if (!data.length) {
      formik.setFieldValue("amount", 0)
    } else {
      handleImageConfirm(data)
    }
  }
  const handleIVData = (data, index) => {
    setIVData(data)
    if (!Object.keys(data).length) {
      formik.setFieldValue("amount", 0)
    } else {
      handleIVConfirm(data)
    }
  }
  const getStateListing = async (inputValue) => {
    if (stateList) {
      let updatedData = stateList?.map((state, index) => {
        return {
          value: state?.value,
          label: state.label,
        }
      })
      return updatedData || []
    }
  }
  const checkValidation = (data) => {
    let isValid = true;
    for (var i = 0; i < data?.length; i++) {
      let flag = Object?.keys(data[i])?.every((k) => data[i][k] != null && data[i][k] != undefined && data[i][k] != '')
      if (!flag) {
        isValid = false;
        break;
      }
    }
    return isValid
  }
  const calculateImagePrice = (data) => {
    const res = data.reduce(function (acc, obj) { return acc + Number(obj.price); }, 0);
    return res || 0
  }

  const handleUpdateImagesData = (data, index) => {
    let initialData = [...imagesData]
    initialData[index] = {
      ...initialData[index],
      ...data
    }
    setImagesData(initialData)

    handleImageConfirm(initialData)
  }
  const handleUpdateIVData = (data, index) => {
    // let initialData = [...imagesData]
    // initialData[index]={
    //   ...initialData[index],
    //   ...data
    // }
    // setImagesData(initialData)

    // handleImageConfirm(initialData)
  }

  const handleDelete = (index) => {
    let updatedData = [...imagesData]
    updatedData.splice(index, 1)
    setImagesData(updatedData)
    if (updatedData?.length != 0) {
      let data = updatedData?.filter((item) => {
        return item?.price
      })
      let price = calculateImagePrice([...data])
      formik.setFieldValue("amount", price)
    } else {
      formik.setFieldValue("amount", 0)
    }
  }

  const handleIVDelete = (index) => {
    setIVData(null)
    formik.setFieldValue("amount", 0)
  }

  const handleImageConfirm = (data) => {
    try {
      let isValid = false;
      if (data?.length) {
        isValid = checkValidation(data)
        setImagesDataError({
          ...imagesDataError,
          error: !isValid,
          helperText: "Please select image quality for all selected images('WEB','SMALL','MEDIUM','LARGE')"
        })
      } else {
        setImagesDataError({
          ...imagesDataError,
          error: !isValid,
          helperText: "Please select images for proposal"
        })
      }
      if (isValid) {
        let price = calculateImagePrice([...data])
        formik.setFieldValue("amount", price)
      } else {
        let newData = data?.filter((item) => {
          return item.price
        })
        let price = calculateImagePrice([...newData])
        formik.setFieldValue("amount", price)
      }
      return isValid
    } catch (e) { }
  }

  const handleIVConfirm = (data) => {
    let isValid = false;
    if (Object?.keys(data || {})?.length) {
      isValid = checkValidation([data])
      setIVDataError({
        ...ivDataError,
        error: !isValid,
        helperText: "Please select plan for proposal"
      })
    } else {
      setIVDataError({
        ...ivDataError,
        error: !isValid,
        helperText: "Please select plan for proposal"
      })
    }

    if (isValid) {
      formik.setFieldValue("amount", data?.plan_price)
    }
    return isValid
  }


  const getImageListing = async (inputValue) => {
    if (inputValue && inputValue.length > 1) {
      let data = await imageListingsNoRedux({ search: inputValue, type: formik?.values?.proposal_of, content_type: exclusiveCheck })
      // let data = await imageListingsNoRedux({ search: inputValue, type: 2 })
      let updatedData = data?.map((item, index) => {
        return {
          value: item?.Name ? item?.Name : item?.video_name,
          label: item.Name ? item.Name : item?.video_name,
          rank: item.rank,
          shootid: item.shootid,
          image_name: item?.Name ? item?.Name : item?.video_name,
          quality: null,
          price: null,
          // category: item?.category,
          type: item?.Name ? 'image' : 'video',
          content_type: exclusiveCheck,
          ...((exclusiveCheck == 2) && {
            duration: appSetting?.items?.features?.exclusive[0]?.key_name || null,
            quality: "LARGE",
            price: Number(appSetting?.items?.features?.exclusive[0]?.key_value),
            right: 'Exclusive'
          })
        }
      })
      return updatedData || []
    }
  }
  const getIVListing = async (inputValue) => {
    if (inputValue && inputValue.length > 1) {
      let data = await subscriptionListingsNoRedux({
        search: inputValue,
        type: ivListingCheck,
      })
      let updatedData = data?.map((item, index) => {
        return {
          value: item.plan_id,
          label: `${item.plan_name} (Pr: ${item.plan_price} - Max D: ${item.maximum_downloads} - Val: ${item.plan_validity})`,
          plan_id: item.plan_id,
          plan_name: item.plan_name,
          plan_type: item.plan_type,
          plan_price: item.plan_price,
          maximum_downloads: item.maximum_downloads,
          plan_validity: item.plan_validity,
        }
      })
      return updatedData || []
    }
  }
  useEffect(() => {
    if (countryData?.items?.length > 0) {
      // let indiaData = countryData?.items?.filter(
      //   (data) => data?.country_name === "India"
      // );
      let modifyData = [...countryData?.items];
      const indiaIndex = modifyData.findIndex(
        (obj) => obj.country_name === "India"
      );
      if (indiaIndex !== -1) {
        formik.setFieldValue("country", modifyData[indiaIndex]?.country_name);
        dispatch(getState(modifyData[indiaIndex]?.country_id));
        const movedArray = modifyData.splice(indiaIndex, 1);
        modifyData.unshift(...movedArray);
        modifyData.splice(1, 0, {
          country_id: 999,
          country_name: "Worldwide",
          country_short_name: "",
          status: "1",
          states_available: "0",
        },
          {
            country_id: 9999,
            country_name: "PAN India",
            country_short_name: "",
            status: "1",
            states_available: "0",
          },
        );
        let data = modifyData?.map((item) => {
          return {
            label: item?.country_name,
            value: item?.country_id,
          };
        });
        setCountryList(data);
        setCountryShortName(data[0]?.country_short_name?.toLowerCase())
        setCountryData(modifyData);
      }
    }
  }, [countryData]);
  useEffect(() => {
    let discount = Number(formik.values.discount)
    let amountInr = Number(formik.values.amount)
    let amountPay = Number(formik.values.discounted_amount)
    let service_tax = Number(formik.values.service_tax)

    if (discount >= 0 && amountInr >= 0) {
      // let updatedAmountPay = amountInr - ((amountInr * discount)/100)
      let updatedAmountPay = amountInr - discount
      formik.setFieldValue("discounted_amount", updatedAmountPay)
      if (amountPay >= 0 && service_tax >= 0) {
        let updatedTax = (amountPay * taxPercent) / 100
        formik.setFieldValue("service_tax", updatedTax)
        formik.setFieldValue("final_amount", updatedAmountPay + updatedTax)
        // setFinalPrice(updatedAmountPay + updatedTax)
      }
    }
  }, [formik.values.discount, formik.values.amount, formik.values.discounted_amount, formik.values.service_tax])
  useEffect(() => {
    if (stateData && Array.isArray(stateData?.items)) {
      let data = stateData?.items?.map((item) => {
        return {
          label: item?.state_name,
          value: item?.state_id,
        };
      });
      setStateList(data);
    }
  }, [stateData]);

  const getCountryDetails = async () => {
    dispatch(getCountry());
  };
  useEffect(() => {
    getCountryDetails();
    dispatch(getState(1));
  }, []);


  const handleStateChange = (val) => {
    let stateSelected = stateData?.items?.filter(
      (item) => item.state_name == val
    );
    if (stateSelected.length) {
      setSelectedState(stateSelected[0]);
    }
  };

  const handleCheckState = (val) => {
    setIvListingCheck(val)
  };

  const handleExclusiveCheckState = (val) => {
    setExclusiveCheck(val)
    if (val === "2" || val === "3")
      formik.setFieldValue("proposal_type", "NPlan");
  };

  useEffect(() => {
    formik.setFieldTouched("gst_number", true, true);
  }, [selectedState])

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} xl={9}>
          <form onSubmit={formik.handleSubmit} className='fontWeight600'>
            <CustomBox padding={3}>
              <Typography variant='h3' sx={{ mr: "auto", mb: 2 }}>
                Your Proposal Cart Detail
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Box display={'flex'} sx={{ justifyContent: { sm: 'start', xs: 'start' } }}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      select
                      SelectProps={{
                        native: true,
                        style: {
                          height: 40
                        }
                      }}
                      name="type"
                      label="Type"
                      placeholder="Type"
                      value={exclusiveCheck}
                      // value={formik.values.proposal_of}
                      // error={formik.touched.proposal_of && Boolean(formik.errors.proposal_of)}
                      // helperText={formik.touched.proposal_of && formik.errors.proposal_of}
                      onChange={(e) => {
                        // setImagesData([])
                        // setIVData(null)
                        // if (e.target.value === "2") {
                        //   handleExclusiveCheckState(2)
                        //   formik.setFieldValue("proposal_type", 'NPlan')
                        // }
                        // else if (e.target.value === "3") {
                        //   handleExclusiveCheckState(3)
                        //   formik.setFieldValue("proposal_type", 'NPlan')
                        // } else {

                        // }
                        // formik.handleChange(e);
                        setImagesDataError({
                          ...imagesDataError,
                          error: false,
                          helperText: "Please select Type of proposal"
                        })
                        setIVDataError({
                          ...ivDataError,
                          error: false,
                          helperText: "Please select plan for proposal"
                        })
                        handleExclusiveCheckState(e.target.value)
                        formik.handleChange(e)
                        // formik.setFieldValue("amount", 0)
                      }}
                      style={{ maxWidth: 150, marginRight: '10px' }}
                    >
                      <option key={'Normal'} value={1}>Normal</option>
                      <option key={'Exclusive'} value={2}>Exclusive</option>
                    </CustomInput>
                  </Box>
                </Grid>
                {exclusiveCheck == 1 ?
                  <Grid item xs={12} sm={6} md={8}>
                    <Box display={'flex'} sx={{ justifyContent: { sm: 'end', xs: 'start' } }}>
                      <CustomInput
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          classes: {
                            notchedOutline: 'rounded',
                          },
                        }}
                        select
                        SelectProps={{
                          native: true,
                          style: {
                            height: 40
                          }
                        }}
                        name="proposal_of"
                        label="Proposal Of"
                        placeholder="Proposal Of"
                        value={formik.values.proposal_of}
                        error={formik.touched.proposal_of && Boolean(formik.errors.proposal_of)}
                        helperText={formik.touched.proposal_of && formik.errors.proposal_of}
                        onChange={(e) => {
                          // setImagesData([])
                          // setIVData(null)
                          setImagesDataError({
                            ...imagesDataError,
                            error: false,
                            helperText: "Please select Type of proposal"
                          })
                          setIVDataError({
                            ...ivDataError,
                            error: false,
                            helperText: "Please select plan for proposal"
                          })
                          // formik.setFieldValue("amount", 0)
                          if (e.target.value === "Video")
                            formik.setFieldValue("proposal_of", 2)
                          else
                            formik.setFieldValue("proposal_of", 1)
                          formik.handleChange(e)
                        }}
                        style={{ maxWidth: 150, marginRight: '10px' }}
                      >
                        <option key={'Image'} value="Image">Image</option>
                        <option key={'Video'} value="Video">Video</option>
                      </CustomInput>
                      {exclusiveCheck == 1 && <CustomInput
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          classes: {
                            notchedOutline: 'rounded',
                          },
                        }}
                        select
                        SelectProps={{
                          native: true,
                          style: {
                            height: 40
                          }
                        }}
                        name="proposal_type"
                        label="Proposal Type"
                        placeholder="Proposal Type"
                        value={formik.values.proposal_type}
                        error={formik.touched.proposal_type && Boolean(formik.errors.proposal_type)}
                        helperText={formik.touched.proposal_type && formik.errors.proposal_type}
                        onChange={(e) => {
                          // setImagesData([])
                          // setIVData(null)
                          setImagesDataError({
                            ...imagesDataError,
                            error: false,
                            helperText: "Please select images for proposal"
                          })
                          setIVDataError({
                            ...ivDataError,
                            error: false,
                            helperText: "Please select plan for proposal"
                          })
                          // formik.setFieldValue("amount", 0)
                          formik.handleChange(e)
                        }}
                        style={{ maxWidth: 150 }}
                      >
                        <option key={'NPlan'} value="NPlan">NPlan</option>
                        <option key={'IV'} value="IV">IV</option>
                      </CustomInput>}
                    </Box>
                  </Grid>
                  : null}
              </Grid>
              <Divder spacing={1} />
              {formik.values.proposal_type == 'NPlan' && <><ImageSelectionComp data={imagesData} onConfirm={handleImageConfirm}
                updateData={handleUpdateImagesData} handleDelete={handleDelete} proposal_of={formik?.values?.proposal_of}
                handleData={handleImagesData} getImageListing={getImageListing} error={imagesDataError.error}
                helperText={imagesDataError.helperText} exclusiveCheck={exclusiveCheck} extraSection={(currentItem, index) => {
                  return <>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      select
                      SelectProps={{
                        native: true,
                        style: {
                          height: 40
                        }
                      }}
                      name="duration"
                      label="Duration"
                      placeholder="Duration"
                      onChange={(e) => {
                        handleUpdateImagesData({ duration: e.target.value, price: Number(findObjectInArray(appSetting?.items?.features?.exclusive, 'key_name', e.target.value)?.key_value) }, index)
                      }}
                      style={{ maxWidth: 150, marginRight: '10px' }}
                    >
                      {appSetting?.items?.features?.exclusive?.map((item, index) => {
                        return <option key={item.key_name} value={item.key_name}>{item.key_name}</option>

                      })}
                    </CustomInput>
                  </>
                }} />
                <Divder spacing={1} /></>}
              {formik.values.proposal_type == 'IV' && <><IVSelectionComp data={ivData} updateData={handleUpdateIVData} handleData={handleIVData}
                getIVListing={getIVListing} error={ivDataError.error}
                helperText={ivDataError.helperText} ivListingCheck={ivListingCheck} handleCheckState={handleCheckState} />
                <Divder spacing={1} /></>}
              <Typography variant='h5'>Detail</Typography>
              <Divder spacing={1} />
              <Grid container spacing={2}>
                {/* <Grid item xs={6}>
                  <ProposalFields
                    name="email"
                    label="Email"
                    placeholder="Email"
                    // disabled
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={
                      formik.touched.email &&
                      Boolean(formik.errors.email)
                    }
                    helperText={
                      formik.touched.email && formik.errors.email
                    }
                  />
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <ProposalFields
                    name="client_name"
                    label="Client Name"
                    placeholder="Client Name"
                    onChange={formik.handleChange}
                    value={formik.values.client_name}
                    error={
                      formik.touched.client_name &&
                      Boolean(formik.errors.client_name)
                    }
                    helperText={
                      formik.touched.client_name && formik.errors.client_name
                    }
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ProposalFields
                    name="heading"
                    label="Heading"
                    placeholder="Heading"
                    onChange={formik.handleChange}
                    value={formik.values.heading}
                    error={
                      formik.touched.heading &&
                      Boolean(formik.errors.heading)
                    }
                    helperText={
                      formik.touched.heading && formik.errors.heading
                    }
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <ProposalFields
                    name="state"
                    label="State"
                    placeholder="State"
                    SelectProps={{
                      native: true,
                    }}
                    select
                    value={formik.values.state}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      classes: {
                        notchedOutline: 'rounded',
                      },
                    }}
                    // onChange={(e) => {
                    //   formik.setFieldValue('state', e.target.value);
                    // }}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleStateChange(e.target.value);

                    }}
                    error={
                      formik.values.country == "India" ?
                        formik.touched.state &&
                        Boolean(formik.errors.state)
                        : null
                    }
                    helperText={
                      formik.values.country == "India" ?
                        formik.touched.state && formik.errors.state
                        : null
                    }
                  >
                    <option key={'Select State'} value={''}>
                      Select state
                    </option>
                    {stateList &&
                      stateList?.map((state) => {
                        return (
                          <option key={state.value} value={state.label}>
                            {state.label}
                          </option>
                        );
                      })}
                  </ProposalFields>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ProposalFields
                    name="amount"
                    label="Amount INR"
                    placeholder="Amount INR"
                    disabled
                    value={formik.values.amount}
                    error={
                      formik.touched.amount &&
                      Boolean(formik.errors.amount)
                    }
                    helperText={
                      formik.touched.amount && formik.errors.amount
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ProposalFields
                    name="discount"
                    label="Discount INR"
                    placeholder="Discount INR"
                    value={formik.values.discount}
                    onChange={(e) => {
                      if (e.target.value <= formik.values.amount) {
                        formik.setFieldValue('discount', e?.target?.value)
                      }
                    }}
                    onBlur={(e) => {
                      if (!e?.target?.value) {
                        formik.setFieldValue('discount', 0)
                      }
                    }}
                    error={
                      formik.touched.discount &&
                      Boolean(formik.errors.discount)
                    }
                    helperText={
                      formik.touched.discount && formik.errors.discount
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ProposalFields
                    name="discounted_amount"
                    label="Amount payable INR"
                    placeholder="Amount payable INR"
                    disabled
                    value={formik.values.discounted_amount}
                    error={
                      formik.touched.discounted_amount &&
                      Boolean(formik.errors.discounted_amount)
                    }
                    helperText={
                      formik.touched.discounted_amount && formik.errors.discounted_amount
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ProposalFields
                    name="service_tax"
                    label={`Tax (${taxPercent}) INR`}
                    placeholder="Tax"
                    disabled
                    value={formik.values.service_tax}
                    error={
                      formik.touched.service_tax &&
                      Boolean(formik.errors.service_tax)
                    }
                    helperText={
                      formik.touched.service_tax && formik.errors.service_tax
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ProposalFields
                    name="final_amount"
                    label="Net Payable INR"
                    placeholder="Net Payable INR"
                    disabled
                    value={formik.values.final_amount}
                    error={
                      formik.touched.final_amount &&
                      Boolean(formik.errors.final_amount)
                    }
                    helperText={
                      formik.touched.final_amount && formik.errors.final_amount
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* <ProposalFields
                    name="credit_period"
                    label="Credit Period"
                    placeholder="Credit Period"
                    value={formik.values.credit_period}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.credit_period &&
                      Boolean(formik.errors.credit_period)
                    }
                    helperText={
                      formik.touched.credit_period && formik.errors.credit_period
                    }
                  /> */}
                  <ProposalFields
                    name="credit_period"
                    label="Credit Period"
                    placeholder="Credit Period"
                    SelectProps={{
                      native: true,
                    }}
                    select
                    value={formik.values.credit_period}
                    onChange={formik.handleChange}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      classes: {
                        notchedOutline: 'rounded',
                      },
                    }}
                    error={
                      formik.touched.credit_period &&
                      Boolean(formik.errors.credit_period)
                    }
                    helperText={
                      formik.touched.credit_period && formik.errors.credit_period
                    }
                  >
                    {CreateProposalData?.createPeriod?.map((state) => {
                      return (
                        <option key={state.value} value={state.label}>
                          {state.label} {state.label == '0' ? 'day' : 'days'}
                        </option>
                      );
                    })}
                  </ProposalFields>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ProposalFields
                    name="gst_number"
                    label="GSTIN No."
                    placeholder="GSTIN No."
                    value={formik.values.gst_number}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      classes: {
                        notchedOutline: 'rounded',
                      },
                    }}
                    onChange={(e) => {
                      formik.setFieldTouched("gst_number", true, true);
                      formik.setFieldValue("gst_number", e.target.value.toUpperCase());
                    }}
                    inputProps={{
                      maxLength: 15
                    }}
                    error={
                      formik.touched.gst_number && Boolean(formik.errors.gst_number)
                    }
                    helperText={
                      formik.touched.gst_number && formik.errors.gst_number
                    }
                  />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <ProposalFields
                    name="images_id"
                    label="Images Id"
                    placeholder="Images Id"
                    value={formik.values.images_id}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.images_id &&
                      Boolean(formik.errors.images_id)
                    }
                    helperText={
                      formik.touched.images_id && formik.errors.images_id
                    }
                  />
                
                </Grid> */}
                <Grid item xs={12} textAlign="right">
                  <CustomButton type="submit" color={'buttonPrimary'}>
                    Create Proposal
                  </CustomButton>
                </Grid>
              </Grid>
            </CustomBox>
          </form>
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <CustomBox padding={3}>
            <Typography variant='h5' className='fontWeight600' textAlign={'center'}>Order Proposal Cart Amount</Typography>
            <Divder spacing={1} />
            <Typography variant='p' component={'p'} textAlign={'center'}>Order Proposal Cart Amount</Typography>
            <Divder spacing={1} />
            <Typography variant='h2' component={'p'} textAlign={'center'}>INR {formik.values.final_amount}</Typography>
            <Divder spacing={0.2} />
            <Typography variant='p' component={'p'} textAlign={'center'}>(Inclusive of tax)</Typography>
          </CustomBox>
          <Divder spacing={1.5} />
          <CustomBox padding={3}>
            <Typography variant='h3' className='fontWeight600'>Order Summary</Typography>
            <Divder spacing={1} />
            {formik.values.proposal_type == "NPlan" ? <ImageOrderSummary handleDelete={handleDelete} imagesData={imagesData} exclusiveCheck={exclusiveCheck} extraSection={(currentItem, index) => {
              return <>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  select
                  SelectProps={{
                    native: true,
                    style: {
                      height: 40
                    }
                  }}
                  name="duration"
                  label="Duration"
                  placeholder="Duration"
                  onChange={(e) => {
                    handleUpdateImagesData({ duration: e.target.value, price: Number(findObjectInArray(appSetting?.items?.features?.exclusive, 'key_name', e.target.value)?.key_value) }, index)
                  }}
                  style={{ maxWidth: 150, marginRight: '10px' }}
                >
                  {appSetting?.items?.features?.exclusive?.map((item, index) => {
                    return <option key={item.key_name} value={item.key_name}>{item.key_name}</option>

                  })}
                </CustomInput>
              </>

            }} /> :
              formik.values.proposal_type == 'IV' ? <IVOrderSummary handleDelete={handleIVDelete} ivData={ivData} /> : null}
          </CustomBox>
        </Grid>
      </Grid>
    </div>
  )
}

export default CreateProposalPage