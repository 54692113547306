import React, { useState } from 'react';
import Box from '@mui/material/Box';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import { Typography } from '@mui/material';
import UploadCsvModalVideo from '../../Components/Modals/UploadCsvModalVideo';
import AllVideosNew from '../../Components/Videos/AllVideosNew';

function AddVideo({ handleNavParams, handleCsvModal, uploadCsvModal }) {
  const [tabValue, setTabValue] = useState(1);
  
  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };

  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox padding={5}>
        {/* <CustomTabs tabList={ImageData?.tabs} tabClick={handleTabs} /> */}
        {/* <button onClick={()=>{
         handleNavParams({
          navTitle: "Book Order"
         })
       }}>Hello</button> */}
        {/* <AddImages/> */}
        <Typography variant='h3'>Please click on Upload CSV then select a (.csv) file to upload Videos.</Typography>
      </CustomBox>
      {/* <Divder spacing={1} />
      <ImageTabBody activeTab={tabValue} /> */}
      {/* <Divder spacing={1.2} /> */}
      <AllVideosNew />
      <UploadCsvModalVideo open={uploadCsvModal} handleOpen={handleCsvModal}/>
     
    </Box>
  );
}

export default AddVideo;
