import React, { useEffect, useState } from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { Box, Typography } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { commonItemsSelector } from '../../redux/common';
import { useDispatch, useSelector } from 'react-redux';
import { getJobDescription } from '../../Services/commonService';
import Divder from '../CommonComp/Divder';
import MatTable from '../Tables/MatTable';
import { DATA_LIMIT } from '../../data/constants';
import JobDescriptionModal from '../Modals/JobDescriptionModal';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'Job Description ID',
    id: 'job_description_id',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
        }}
        onClick={() => {
          if (columnData >= 0 && columnData != null) {
            customHistory.push(`image/soldImagesByGroupId/${columnData}`);
          }
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Job Description',
    id: 'name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Status',
    id: 'status',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData == "1" ? "Active" : "Inactive"}
      </span>
    ),
  },
];

function JobDescription() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedJobDesc, setSelectedJobDesc] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [allParams, setAllParams] = useState({});
  const { loading, jobData } = useSelector(commonItemsSelector);
  const dispatch = useDispatch();

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      admin: true,
      ...allParams,
      ...params,
    };
    dispatch(getJobDescription(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);

  //making data format for table input
  useEffect(() => {
    if (jobData?.items?.rows?.length >= 0) {
      setTableListing(jobData?.items?.rows || []);
    }
  }, [jobData.items]);

  const handleModal = (value = false) => {
    setOpenModal(value);
    if (!value) {
      setTimeout(() => {
        setSelectedJobDesc(null);
      }, 100);
    }
  };

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton className="buttonDense" sx={{ bgcolor: 'buttonInfo.main' }} onClick={() => {
            handleModal(true);
            setSelectedJobDesc(data);
          }}>Edit</CustomButton>
        </div>
      </>
    );
  };


  return (
    <>
      <CustomBox padding={0} sx={{ minHeight: 200 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: 'wrap', pt: 2, px: 2 }}>
          <Typography variant="h3" sx={{ mb: { md: 0, xs: 1 } }}>Job Description</Typography>
          <CustomButton color={'buttonPrimary'} onClick={() => {
            handleModal(true);
            setSelectedJobDesc(null);
          }}>
            Add
          </CustomButton>
        </Box>
        <Divder spacing={1} />
        <MatTable
          loading={loading}
          showCheckbox={false}
          columns={column}
          data={tableListing}
          action={actionBody}

          page={page}

          perPage={limit}

          total={jobData?.items?.count}

          setPage={setPage}
          handlePageChange={(newPage) => {
            getListing({
              page: String(newPage),
            });
            setAllParams({
              ...allParams,
              page: String(newPage),
            });
          }}
          pagination={true}
          disableSearch={true}

        />
        <JobDescriptionModal open={openModal} handleOpen={handleModal} data={selectedJobDesc} getListing={getListing} />
      </CustomBox>
    </>
  );
}
export default JobDescription;
