import React, { useRef, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { DATA_LIMIT } from '../../data/constants';
import { fileUploadImage, fileUploadImageBanners } from '../../Services/commonService';

const limit = DATA_LIMIT;

function UploadImageModal({ open, handleOpen, handleLast }) {
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState('')
  const [selectedImageData, setSelectedImageData] = useState('')
  const imageInputRef = useRef(null);
  
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageData(URL.createObjectURL(event.target.files[0]));
      let fileName = event.target.files[0].name.split('.');
      fileName = fileName[fileName?.length - 1]
      let newFile = new File([event.target.files[0]], `${'item'}.${fileName || 'jpeg'}`, { type: event.target.files[0].type });
      setSelectedImageData(newFile)
    }
  }

  const dispatch = useDispatch();




  const handleSubmit = async () => {
    try {
      if (selectedImageData) {
        setLoading(true)
        const formData = new FormData();
        formData.append("files", selectedImageData);
        formData.append("folder_name", 'bannersImages');

        let data = await fileUploadImageBanners(formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        if (data?.status == 200) {
          handleLast();
          handleOpen(false);
        }
        setLoading(false);
      }
    }
    catch (e) {
      console.log(e);
      setLoading(false)
    }
  }



  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        fullWidth
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Upload Image

        </DialogTitle>
        <DialogContent>
          <Box
          // noValidate
          // component="form"
          // onSubmit={formik.handleSubmit}
          >
            <Typography
              variant='span'
              // style={{ whiteSpace: 'nowrap' }}
              paddingBottom={5}
            >
             
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sm={12}>
                <CustomButton variant="contained" component="label" sx={{ padding: 1 }}>
                  {imageData ?
                    <img id='img1' src={imageData} style={{ maxWidth: 500 }} className="img-fluid rounded-2" />
                    : " Click Here "}
                  <input type="file" accept='image/*' ref={imageInputRef} hidden onChange={onImageChange} />
                </CustomButton>
              </Grid>

            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
                onClick={() => { handleSubmit();  }}
              >
                Upload
              </CustomButton>
              <CustomButton
                onClick={() => {

                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UploadImageModal;
