import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import { LeftArrow } from '../../Asset/svg';
import { Typography, useTheme } from '@mui/material';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearSingleUserDetails } from '../../redux/user';
import ContributorPaymentList from '../../Components/Contributor/ContributorPaymentList';
import ContributorFolderList from '../../Components/Contributor/ContributorFolderList';
import { contributorBankAndOtherInfoWithNoRedux } from '../../Services/ContributorApis';


function ContributorFolderDetail() {
    const theme = useTheme();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { contributorId } = useParams();
    const [ contributorBasicData, setContributorBasicData ] = useState();

    useEffect(()=>{
        ContributorBasicDataCall()
        contributorBankAndOtherInfoWithNoRedux()
      },[contributorId])
    
      const ContributorBasicDataCall=async()=>{
        if(contributorId){
        let data=await contributorBankAndOtherInfoWithNoRedux({contributor_id: contributorId});
        setContributorBasicData(data)
        if(data){
         setContributorBasicData(data?.contributorDetails)
        }
        }
      }


    return (
        <Box
            component="main"
            spacing={2}
            sx={(theme) => {
                return {
                    flexGrow: 1,

                };
            }}
        >

            <CustomBox>
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: 'pointer'
                    }} onClick={() => {
                        navigate(-1)
                        dispatch(clearSingleUserDetails())
                    }}>
                        <LeftArrow fill={theme.palette.textButton.main} />
                        <Typography variant='h5' sx={{
                            marginLeft: theme.spacing(1),
                            color: theme.palette.textButton.main
                        }}>Back</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Typography variant='h5' sx={{ marginLeft: theme.spacing(1), textAlign: 'center' }}>
                            Contributor Folder List {contributorBasicData?.name?`(${contributorBasicData?.name})`:""}
                        </Typography>
                    </Box>
                </Box>
            </CustomBox>
            <ContributorFolderList />
        </Box>
    );
}

export default ContributorFolderDetail;

