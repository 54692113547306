import { Typography } from "@mui/material";
import cryptoJs from "crypto-js";
import { getObjectFromStore } from "../storage/Storage";
import { ViewColumn } from "@material-ui/icons";
import { useLocation } from "react-router-dom";

// TO REDUCE TIME OFFSET FOR TIME DIFF.
function adjustForTimezone(date) {
  var timeOffsetInMS = date.getTimezoneOffset() * 60000;
  date.setTime(date.getTime() + timeOffsetInMS);
  return date
}

export function makeDateFromToday(days) {
  const today = new Date();  // Create a new Date object for today
  const pastDate = new Date(today);  // Subtract 5 days
  pastDate.setDate(today.getDate() - days);
  const formattedDate = pastDate.toISOString().split('T')[0];  // Format the date to YYYY-MM-DD
  return formattedDate
}

export const getDataFromAppSetting = (arr, key, valueKey) => {
  if (arr?.length > 0) {
    const data = arr.filter((data) => data[key] === valueKey);
    return data;
  } else {
    return "N/A";
  }
};

export function formatDate(d, format = 'dd/mm/yy', adjustOffset = false) {
  if (!d) {
    return ''
  }
  let monthsArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  let date = new Date(d);
  if (adjustOffset) {
    date = adjustForTimezone(new Date(d))
  }
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  var h = date.getHours();
  var m = date.getMinutes();
  var timeFormat = 'AM';
  var s = date.getSeconds();
  var ms = date.getMilliseconds();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }

  //Time 
  if (h <= 11 && m < 60) {
    timeFormat = "AM";
  } else if (h >= 12) {
    timeFormat = "PM";
  }
  if (format.includes('12')) {
    h = h % 12;
    h = h ? h : 12; // the hour '0' should be '12'
    m = m;
  }
  if (h < 10) {
    h = '0' + h;
  }
  if (m < 10) {
    m = '0' + m;
  }
  if (s < 10) {
    s = '0' + s;
  }
  if (ms < 10) {
    ms = '0' + ms;
  }

  let formatNeed = format?.toLowerCase();

  if (formatNeed == 'dd-mm-yy') {
    return (d = dd + '/' + mm + '/' + yyyy?.toString()?.slice(2, 4));
  } else if (formatNeed == 'dd/mm/yy') {
    return (d = dd + '/' + mm + '/' + yyyy?.toString()?.slice(2, 4));
  } else if (formatNeed == 'dd/mm/yyyy') {
    return (d = dd + '/' + mm + '/' + yyyy);
  } else if (formatNeed == 'dd/mm/yyyy') {
    return (d = dd + '/' + mm + '/' + yyyy);
  } else if (formatNeed == 'dd-mm-yyyy') {
    return (d = dd + '-' + mm + '-' + yyyy);
  } else if (formatNeed == 'yyyy-mm-dd hh:mm:ss.ms') {
    return (d = yyyy + '-' + mm + '-' + dd + ' ' + h + ':' + m + ':' + s + '.' + ms);
  } else if (formatNeed == 'dd-mm-yyyy hh:mm:ss.ms') {
    return (d = dd + '-' + mm + '-' + yyyy + ' ' + h + ':' + m + ':' + s + '.' + ms);
  } else if (formatNeed == 'dd-mm-yyyy hh:mm:ss') {
    return (d = dd + '-' + mm + '-' + yyyy + ' ' + h + ':' + m + ':' + s);
  } else if (formatNeed == 'yyyy-mm-dd hh:mm:ss') {
    return (d = yyyy + '-' + mm + '-' + dd + ' ' + h + ':' + m + ':' + s);
  } else if (formatNeed == 'dd-mm-yyyy hh:mm') {
    return (d = dd + '-' + mm + '-' + yyyy + ' ' + h + ':' + m);
  } else if (formatNeed == 'dd-mm-yyyy hh:mm 12h') {
    return (d = dd + '-' + mm + '-' + yyyy + ' ' + h + ':' + m + ' ' + timeFormat);
  } else if (formatNeed == 'yyyy-mm-dd') {
    return (d = yyyy + '-' + mm + '-' + dd);
  } else if (formatNeed == 'yyyy-mm-ddthh:mm') {
    return (d = yyyy + '-' + mm + '-' + dd + 'T' + h + ":" + m);
  } else if (formatNeed == 'mon dd yyyy') {
    return (d = monthsArr[mm - 1] + ' ' + dd + ' ' + yyyy);
  } else if (formatNeed == 'iso') {
    return date?.toISOString();
  } else if (formatNeed == 'utc') {
    return date?.toUTCString();
  }
}

export const roundOffValue = (data) => {
  try {
    return Math.round(Number(data));
  } catch (e) {
    return data;
  }
};

export const errorMessage = (response) => {
  try {
    return (
      response?.response?.data?.message ||
      response?.data?.response?.message ||
      response?.data?.message ||
      response?.message ||
      response?.statusText ||
      'Something went wrong.'
    );
  } catch (e) {
    return e?.message || 'Something went wrong.';
  }
};

export const deleteApiPayload = (value) => {
  try {
    return {
      data: value,
    };
  } catch (e) {
    return {
      data: {},
    };
  }
};

export const handleFileReader = async (event, setFile) => {
  let reader = await new FileReader();
  reader.readAsDataURL(event.target.files[0]);
  reader.onload = (e) => {
    setFile({
      data: reader.result.split(',').pop(),
      fileName: event.target.files[0].name,
    });
  };
};

export const getObjectSubset = (obj, ...keys) => Object.fromEntries(
  keys
    .filter(key => key in obj)
    .map(key => [key, obj[key]])
);

export const deleteObjectKeys = (obj, ...keys) => {
  keys.forEach(e => delete obj[e]);
  return obj || null
};

export const findObjectInArray = (arr, keyName, matchingKey) => arr.find(o => o[keyName] === matchingKey);

export const getInitialDataFormik = (arr, key, valueKey) => {
  let data = {}
  if (arr.length) {
    arr.forEach(element => {
      data[element[key]] = element[valueKey] || ""
    });
  }
  return data
}

export const getArrayOfObjJoinToStringForKey = (arr, key) => {
  return arr?.map(u => u[key])?.join(', ') || ''
}

export function humanize(str) {
  try {
    var i, frags = str?.toLowerCase()?.split('_');
    for (i = 0; i < frags?.length; i++) {
      frags[i] = frags[i]?.charAt(0)?.toUpperCase() + frags[i]?.slice(1);
    }
    return frags?.join(' ');
  } catch (e) { console.log(e, 'humanize') }
}

export function handleTheImageOpen(){
}

export function isNumeric(value) {
  return /^\d+$/.test(value);
}

export function removeExtraSpace(string, type = "") {
  let str = string
  if (type == 'all') {
    str = str.replace(/\s/g, '')
  } else if (type == ' , ') {
    str = str.replace(/\s*,\s*/g, ",")
  } else {
    str = str.replace(/\s+/g, ' ').trim()
  }
  return str
}
export function checkingPlanActive(allPlan, planData) {
  let findIfActive = allPlan?.find((plans) => (planData?.plan_id == plans?.plan_id && plans?.status == 1));
  if (findIfActive) return true;
  else return false;
}


// handleParse  and processCSV is used to read and process csv files   https://dev.to/theallegrarr/read-csv-files-in-react-apps-without-installing-any-package-hn7

export const handleParse = (file) => {
  return new Promise((resolve, reject) => {
    try {
      // Initialize a reader which allows user
      // to read any file or blob.
      const reader = new FileReader();
      // Event listener on reader when the file
      // loads, we parse it and set the data.
      reader.onload = function (e) {
        const text = e.target.result;
        resolve(text)
      }
      reader.readAsText(file);
    } catch (e) { }
  })

};

export const processCSV = (str, delim = ',', dataNeeded = "headers") => {
  const headers = str.slice(0, str.indexOf('\n')).split(delim).map((item) => {
    let itemData = item.replace("\r", "")
    return itemData
  });
  if (dataNeeded == 'headers') {

    return headers
  } else if (dataNeeded == 'csvAsArray') {
    const rows = str.slice(str.indexOf('\n') + 1).split('\n');
    const csvArray = rows.map(row => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {})
      return eachObject;
    })
    return csvArray
  }
  return []
}

export function validDate(date, compareDate = null, checkEqual = false) {
  // const currentDate = new Date();
  // const incomingDate = new Date(date)
  // let crr = formatDate(currentDate);
  // let incr = formatDate(incomingDate);
  // if(crr == incr) {
  //   return false
  // }else{
  //   let status = currentDate > incomingDate
  //   return status;
  // }
  let current_date = new Date();
  if (compareDate) {
    current_date = new Date(compareDate)
  }
  let incoming_date = new Date(date);
  if (current_date.getTime() < incoming_date.getTime()) {
    return true
  } else if (current_date.getTime() <= incoming_date.getTime() && checkEqual) {
    return true
  } else {
    return false
  }
}


export const crypt = (salt = "crypt", textStr) => {
  let text = String(textStr?.toString())
  const textToChars = (text) => text?.split("").map((c) => c?.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n)?.toString(16))?.substr(-2);
  const applySaltToChar = (code) => textToChars(salt)?.reduce((a, b) => a ^ b, code);
  return text
    ?.split("")
    ?.map(textToChars)
    ?.map(applySaltToChar)
    ?.map(byteHex)
    ?.join("") || "";
};

export const useQuery = (param) => {
  // Use get method to retrieve queryParam
  return new URLSearchParams(useLocation().search).get(param);
}
export const decrypt = (salt = "crypt", encoded) => {
  const textToChars = (text) => text?.split("")?.map((c) => c?.charCodeAt(0));
  const applySaltToChar = (code) => textToChars(salt)?.reduce((a, b) => a ^ b, code);
  return encoded?.toString()
    ?.match(/.{1,2}/g)
    ?.map((hex) => parseInt(hex, 16))
    ?.map(applySaltToChar)
    ?.map((charCode) => String?.fromCharCode(charCode))
    ?.join("") || "";
};

export function generateArrayOfYears(yearsNeed = 9) {
  var max = new Date().getFullYear()
  var min = max - yearsNeed
  var years = []

  for (var i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}

export function generateArrayOfMonths() {
  const month = [
    { value: '01', label: "January" },
    { value: '02', label: "February" },
    { value: '03', label: "March" },
    { value: '04', label: "April" },
    { value: '05', label: "May" },
    { value: '06', label: "June" },
    { value: '07', label: "July" },
    { value: '08', label: "August" },
    { value: '09', label: "September" },
    { value: '10', label: "October" },
    { value: '11', label: "November" },
    { value: '12', label: "December" }
  ];
  return month
}

export const encryptData = async (data, key) => {
  const encrypted = cryptoJs.AES.encrypt(String(data), key).toString();
  return encrypted
};
export const decryptData = async (data, key) => {
  // const decrypted = cryptoJs.AES.encrypt(data, key).toString();
  let bytes1 = cryptoJs.AES.decrypt(String(data), key);
  let decryptedData = bytes1.toString(cryptoJs.enc.Utf8);

  return decryptedData;
};

export const createSubString = (str = '', onClick = () => { }, subStrLength = 99, clickText) => {
  let newStr = str;
  try {
    if (str.length > subStrLength) {
      newStr = <>
        {str.substring(0, subStrLength)}... {!!clickText && <Typography
          variant='smallFont'
          component={"p"}
          style={{ whiteSpace: 'nowrap' }}
          className={'link-text'}
          onClick={() => { onClick() }}
        >
          {clickText}
        </Typography>}
      </>
    } else {
      newStr = str
    }
    return newStr
  } catch (e) {
    return str
  }
};

export const checkPaymentOnline = (data) => {
  let isOnline = false;
  if (data?.payment_type) {
    if (!(data?.payment_type?.toLowerCase()?.includes('bank transfer')
      || data?.payment_type?.toLowerCase()?.includes('cheque')
      // || data?.payment_type?.toLowerCase()?.includes('card')
      || data?.payment_type?.toLowerCase()?.includes('demand draft')
      || data?.payment_type?.toLowerCase()?.includes('draft')
      || data?.payment_type?.toLowerCase()?.includes('demand')
      || data?.payment_type?.toLowerCase()?.includes('neft')
      || data?.payment_type?.toLowerCase()?.includes('rtgs')
      || data?.payment_type?.toLowerCase()?.includes('wt'))) {
      isOnline = true
    } else {
      isOnline = false
    }
  }

  return isOnline
}


export async function fetchFile(url, header = {}, target = "") {
  return new Promise(async (resolve, reject) => {
    try {
      // const res = await fetch(url);
      // const file = await res.blob();
      // let tempUrl = URL.createObjectURL(file);
      const aTag = document.createElement("a");
      aTag.href = url;
      if (target) {
        aTag.target = target || '_blank';
      }
      aTag.id = 'downloadId';
      // aTag.download = true;
      // aTag.download = url.replace(/^.*[\\\/]/, "");
      document.body.appendChild(aTag);
      aTag.click();
      // URL.revokeObjectURL(tempUrl);
      aTag.remove();
      resolve();
    } catch {
      toast.error("Failed to download file.");
      reject();
    }
  });
}

export async function fetchInPromiseFile(url, header = {}, target = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(url, header);
      const file = await res.blob();
      let tempUrl = URL.createObjectURL(file);
      const aTag = document.createElement("a");
      aTag.href = tempUrl;
      if (target) {
        aTag.target = target || '_blank';
      }
      aTag.id = 'downloadId';
      aTag.download = true;
      aTag.download = url.replace(/^.*[\\\/]/, "");
      document.body.appendChild(aTag);
      aTag.click();
      URL.revokeObjectURL(tempUrl);
      aTag.remove();
      resolve();
    } catch {
      toast.error("Failed to download file.");
      reject();
    }
  });
}


export async function fetchInPromiseFileModel(url, header = {}, target = "",fileName='') {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(url, header);
      const file = await res.blob();
      let tempUrl = URL.createObjectURL(file);
      const aTag = document.createElement("a");
      aTag.href = tempUrl;
      if (target) {
        aTag.target = target || '_blank';
      }
      aTag.id = 'downloadId';
      aTag.download = true;
      //aTag.download = url.replace(/^.*[\\\/]/, "");
      aTag.download =fileName;
      document.body.appendChild(aTag);
      aTag.click();
      URL.revokeObjectURL(tempUrl);
      aTag.remove();
      resolve();
    } catch {
      toast.error("Failed to download file.");
      reject();
    }
  });
}

export function downloadBlobFile(data, name = 'File') {
  // create file link in browser's memory
  const href = URL.createObjectURL(data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.download = name; //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export function downloadBlobXLSFile(data, name = 'File') {
  // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.

  try {
    const outputFilename = `${name}.xlsx`;

    // If you want to download file automatically using link attribute.
    const url = URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    throw Error(error);
  }
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string?.slice(1);
}

export function paymentModeModification(string) {

  if (string === 'American Express') return 'AMX'
  else if (string === 'Cheque/demand draft') return 'Cheque'
  else if (string === 'NEFT/RTGS/WT') return 'NEFT'
  else return string;

}

export function currencyConversion(number) {
  // let NumberTypeCheck=typeof number=='string'?Number(number):number;
  let x = Math.round(number*100)/100;
  x = x.toString();
  var afterPoint = '';
  if (x.indexOf('.') > 0)
    afterPoint = x.substring(x.indexOf('.'), x.length);
  x = Math.floor(x);
  x = x.toString();
  var lastThree = x.substring(x.length - 3);
  var otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers != '')
    lastThree = ',' + lastThree;
  var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
  return res

}


export function getParameters() {
  let parameters = window.location.search.replace('?', '');

  let currentParameters = {};

  if (Object.keys(parameters).length) {

    parameters = parameters.split('&');

    for (let i = 0; i < parameters.length; i++) {
      let parameter = parameters[i].split('=');
      if (parameter[0] != 'tab') {
        currentParameters[parameter[0]] = decrypt(parameter[0], parameter[1].replaceAll('%20', ' ') || "");
      }
      else {
        currentParameters[parameter[0]] = parameter[1].replaceAll('%20', ' ');
      }
    }
  }
  return currentParameters;
}

export function getNewParameters(newParameters) {
  const parameters = getParameters();
  const parametersKeys = Object.keys(parameters)
  const keys = Object.keys(newParameters);

  for (let i = 0; i < parametersKeys.length; i++) {
    if (!keys.includes(parametersKeys[i]) && parametersKeys[i] != 'tab') {
      delete parameters[parametersKeys[i]]
    }
  }
  for (let i = 0; i < keys.length; i++) {
    const value = newParameters[keys[i]];
    parameters[keys[i]] = value

    if (!value) {
      delete parameters[keys[i]];
    }
  }
  let newUrl = updateUrl(parameters)

  return { parameters, ...newUrl };
}

export function updateUrl(parameters) {
  let search = '';
  let j = 0;
  let separator = '?';

  Object.keys(parameters).forEach((key) => {

    let value = parameters[key];

    if (value) {

      if (j !== 0) {
        separator = '&';
      }
      if (key != 'tab') {
        search += `${separator}${key}=${crypt(key, value || "")}`;
      } else {
        search += `${separator}${key}=${value}`;
      }
      j++;
    }
  });

  let newUrl = `${location.pathname}${search}`;

  // prevents pushing same url if function won't change url.
  if (location.href !== newUrl) {
    return { newUrl, search }
    // history.pushState(null, null, newUrl);
  }
}

export function isAccessiblePath(path) {
  let data = getObjectFromStore('userMenu');
  let userData = getObjectFromStore('user');
  if (userData?.is_superadmin === "1") return true;
  if (data?.length) {
    let itemIndex = data?.findIndex(pathName => pathName?.value === path);
    if (userData?.is_superadmin === "1") return true;
    else if (path === 'admin_users' && userData?.is_superadmin === '0') return false;
    return itemIndex >= 0 ? true : false;
  }
  return false;
}

export function breakStringIntoLines(str, maxLineLength) {
  if(str){
  const words = str.split(' ');
  let currentLine = '';
  let lines = [];

  for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (currentLine.length + word.length <= maxLineLength) {
          currentLine += (currentLine.length === 0 ? ' ' : ' ') + word;
      } else {
          lines.push(currentLine);
          currentLine = word;
      }
  }

  if (currentLine.length > 0) {
      lines.push(currentLine);
  }
  let newArr=lines?.map((item)=><>{item} <br/></>)
  if(newArr?.length>15){
    newArr=newArr?.slice(0,15);
    newArr?.push('...')
  }
  return newArr;
}

  // return lines.join('\n');
}

export function breakStringIntoLinesByComa(str, maxLineLength) {
  if(str){
  const words = str.split(',');
  let currentLine = '';
  let lines = [];

  for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (currentLine.length + word.length <= maxLineLength) {
          currentLine += (currentLine.length === 0 ? ' ' : ' ') + word;
      } else {
          lines.push(currentLine);
          currentLine = word;
      }
  }

  if (currentLine.length > 0) {
      lines.push(currentLine);
  }
  let newArr=lines?.map((item)=><>{item} <br/></>)
  if(newArr?.length>15){
    newArr=newArr?.slice(0,15);
    newArr?.push('...')
  }
  return newArr;
}

  // return lines.join('\n');
}
export function breakStringIntoLinesBySymbol(str, maxLineLength,symbol) {
  if(maxLineLength && str?.length>maxLineLength){
  if(str){
  const words = str.split(symbol);
  let currentLine = '';
  let lines = [];

  for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (currentLine.length + word.length <= maxLineLength) {
          currentLine += (currentLine.length === 0 ? ' ' : ' ') + word+symbol;
      } else {
          if(currentLine){
            if(currentLine?.includes(symbol)){
              currentLine;
            }
            else{
              currentLine=currentLine+symbol;
            }
          }
          lines.push(currentLine);
          currentLine = word;
      }
  }

  if (currentLine.length > 0) {
      lines.push(currentLine);
  }
  let newArr=lines?.map((item)=><>{item} <br/></>)
  if(newArr?.length>15){
    newArr=newArr?.slice(0,15);
    newArr?.push('...')
  }
  return newArr;
}

  // return lines.join('\n');
}
else{
  return str;
}
}

export function breakStringIntoLinesBySomeLength(str, len=10) {
  if(len && str?.length>len){
  if(str){
  let lines = [];
  let remaining='';
  let index=0;
  for(let i=0;i<str?.length;i++){
      if(index<str?.length && i>=index){
      let line=str?.substring(index,len+index);
      
      index=index+len;
      lines?.push(line);
      }
  }
  let newArr=lines?.map((item)=><>{item} <br/></>)
  return newArr;
 
}
}
return str;
}

export function makeUrlForNextPage(location,user_id,order_type){
   let indexOfQuestion=location?.indexOf("?");
   let IndexOfCompany=location?.indexOf("short_company_name");
   let newString=location?.substring(IndexOfCompany,location?.length);
   newString=newString+`&user_id=${user_id}&order_type=${order_type}`
   return newString;
}
export function makeUrlForNextPageLocation(location,order_type){
  let indexOfQuestion=location?.indexOf("page=");
  let newStringByPage=location?.substring(indexOfQuestion,location?.length);
  let indexOfAnd=newStringByPage?.indexOf('&');
  let actualString=newStringByPage?.substring(indexOfAnd+1,location?.length);
  actualString=actualString+`&order_type=${order_type}`;
  return actualString;
}


export function isJson(str) {
  let parsed = str
  try {
    parsed = JSON.parse(str);
  } catch (e) {
    return { status: false, data: null };
  }
  return { status: true, data: parsed };
}

export const createCommonSettingCategoryTableData = (data, parentKey) => {
  let parsedData = isJson(data)
  if (parsedData?.status) {
    let newData = parsedData.data
    let keys = Object.keys(newData)
    let updatedData = keys.map((item, index) => {
      return {
        'parent_key': parentKey,
        'key_name': item,
        'key_value': newData[item]
      }
    })
    return updatedData
  }
  return []
}

export const copyTextToClipBoard = (copyValue) => {
  navigator.clipboard.writeText(copyValue);
};

export const dateDiffInDaysOfDates = (date) => {
  let remainDays = new Date()?.getTime() - new Date(date)?.getTime();
  let TotalDays = Math.ceil(remainDays / (1000 * 3600 * 24));
  if (Number(TotalDays) < 0) {
    return 0;
  } else {
    return TotalDays - 1;
  }
};

export const statusText = (columnData) => {
  if (columnData === '0' || columnData === '1' || columnData === '2' || columnData === '3' || columnData === '4' || columnData === '5' || columnData === '6' || columnData === '7' || columnData === '8') {
    return columnData ? (columnData === '0' || columnData === 'Open' ? 'Open' :
      columnData === '1' || columnData === 'Close' ? 'Close' :
        columnData === '2' || columnData === 'PClose' ? 'PClose' :
          columnData === '3' || columnData === 'Sold' ? 'Sold' :
            columnData === '4' || columnData === 'Upgrade Sold' ? 'Upgrade Sold' :
              columnData === '5' || columnData === 'QPack Sold' ? 'QPack Sold' :
                columnData === '6' || columnData === 'DQuery Sold' ? 'DQuery Sold' :
                  columnData === '7' || columnData === 'DPack Sold' ? 'DPack Sold'
                    : 'Exclude Sold') : "-"
  }
  else return columnData;
}

export const showPaymentMethodName = (columnData) => {
  return columnData === 'ccavenue' ? 'CC Avenue'
    : columnData === 'razorpay' ? 'Razor Pay'
      : columnData === 'pay10' ? 'Pay 10'
        : columnData === 'american_express' ? 'American Express'
          : columnData === 'paypal' ? 'Paypal'
            : columnData === 'cheque_demand_draft' ? 'Cheque / Demand Draft'
              : columnData === 'neft_rtgs_wire_transfer' ? 'NEFT / RTGS / wise Transfer'
                : columnData === 'pay10' ? 'Pay10'
                  : columnData === 'zaakpay' ? 'ZaakPay'
                    : ''

}

export const checkForEnablePack = (packType = "", subscriptions = []) => {
  let packAvailable = subscriptions?.webSizePack?.length || subscriptions?.largeSizePack?.length || subscriptions?.smallSizePack?.length
  if (packType?.toLowerCase()?.includes("web")) {
    const webSizePack = subscriptions?.webSizePack
    return {
      result: "Web Size Pack",
      status: webSizePack?.length > 0 ? true : false,
      packAvailable: !!packAvailable
    }
  }
  if (packType?.toLowerCase()?.includes("large")) {
    const largeSizePack = subscriptions?.largeSizePack
    return {
      result: "Large Size Pack",
      status: largeSizePack?.length > 0 ? true : false,
      packAvailable: !!packAvailable
    }
  }
  if (packType?.toLowerCase()?.includes("small")) {
    const smallSizePack = subscriptions?.smallSizePack
    return {
      result: "Small Size Pack",
      status: smallSizePack?.length > 0 ? true : false,
      packAvailable: !!packAvailable
    }
  }
  return {
    result: "",
    status: false,
    packAvailable: !!packAvailable
  }
}