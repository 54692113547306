import { Edit } from '@material-ui/icons';
import { Article } from '@mui/icons-material';
import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Delete } from '../../Asset/svg';
import ApiConfig from '../../config/ApiConfig';
import { breakStringIntoLines, breakStringIntoLinesBySymbol, createSubString, crypt, formatDate, getNewParameters, getObjectSubset, getParameters, paymentModeModification } from '../../lib/helper';
import { orderListingItemsSelector } from '../../redux/orderListing';
import { customHistory } from '../../Route/CustomBrowserRouter';
import { generateIrn } from '../../Services/irnApi';
import { RejectedOrderListings } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import ConfirmationModal from '../Modals/ConfirmationModal';
import MatTable from '../Tables/MatTable';
import AllFilters from './AllFilters';
import { SearchByRejected } from '../../data/userData';


const column = [
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (
      <Link className='textDecoration-none link-text' to={`orderDetails/${columnData}`}>
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
        >
          {columnData}
        </Typography>
      </Link>
    ),
  },

  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
                 {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Order Date',
    id: 'ordered_on',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {' '}
        {formatDate(columnData)}
      </span>
    ),
  },
  {
    label: 'Payment Mode',
    id: 'payment_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData?paymentModeModification(columnData) : 'N/A'}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'Invoice ID',
    id: 'invoice_id',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => {
      if (row?.order_status > 0) {
        return columnData ? <Link className='textDecoration-none link-text' to={`../../invoice?orderId=${crypt('order_id', row?.order_id)}`} target={'_blank'}>{columnData}</Link> :
          <Typography variant='span' style={{ whiteSpace: 'nowrap' }}>{columnData}</Typography>
      }
      return null
    },
    nextLine:true
  },

  {
    label: 'Payment Status',
    id: 'payment_status',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (<>
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData == '0' ? 'Unpaid' : 'Paid'}
      </span>
      {row?.paid_on ? <Typography variant='p' display={'block'} className='colorHiglight2'>
        {formatDate(row.paid_on, 'dd/mm/yyyy')}
      </Typography> : null}
      {row?.previous_order_id ? <Typography variant='p' display={'block'} className='colorHiglight'>
        RefID:{row?.previous_order_id}
      </Typography> : null}
    </>
    ),
    nextLine:true,
  },
  {
    label: 'Order Status',
    id: 'order_status',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <>
        <Typography variant="span" sx={{ color: 'danger.main', display: 'inline-block' }}>Rejected</Typography>
        <Typography variant="span" component={'div'}>{row?.order_type}</Typography>
      </>
    ),
  },
  {
    label: 'Clearence Date',
    id: 'payment_on',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'dd/mm/yyyy')}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'Comments',
    id: 'comment',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (<Box style={{ maxWidth: 300, width: 'max-content', wordBreak: "break-word" }}>
      {!!columnData && <Tooltip title={columnData}><span style={{ display: 'inline-block', marginBottom: 10 }}>
        {/* {createSubString(columnData, () => { }, 62)} */}
        {breakStringIntoLines(columnData,20)}
      </span></Tooltip>}
      {!!row?.special_comment && <Tooltip title={row?.special_comment}><Box className="colorHiglight fontWeight500" style={{ display: 'block' }}>
        Spl comment: 
        {/* {createSubString(row?.special_comment, () => { }, 62)} */}
        {breakStringIntoLines(row?.special_comment,20)}
      </Box></Tooltip>}
      {columnData  || row?.special_comment ?'':'N/A' }
    </Box>
    ),
  },
];

function RejectedOrder() {
  const [orderListing, setOrderListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const [limit, setLimit] = useState(20);
  const [createCreditNoteModal, setCreateCreditNoteModal] = useState(false);
  const [creditNoteIRNData, setCreditNoteIRNData] = useState({});
  const { loading, rejectedOrders } = useSelector(orderListingItemsSelector);
  const dispatch = useDispatch();
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation();
  const [filtersArray, setFiltersArray] = useState([

    {
      label: 'From Date',
      id: 'start_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[1].value = e.target.value;
          return newState;
        });
       },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'end_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'Search By',
      id: 'search_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          newState[3].value='';
          return newState;
        });
      },
      style: {},
      dataArr: SearchByRejected,
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);
  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getOrderListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };
  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[2].isVisible = true;
      newState[0].value = '';
      newState[1].value = '';
      newState[2].value = '';
      return newState;
    });
  };
  const getOrderListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      order_status: String(2),
      ...allParams,
      ...params
    }
    if (payload?.start_date) {
      payload['start_date'] = formatDate(payload?.start_date, 'yyyy-mm-dd') + "  00:00:00"
    }
    if (payload?.end_date) {
      payload['end_date'] = formatDate(payload?.end_date, 'yyyy-mm-dd') + "  23:59:59"
    }
    dispatch(RejectedOrderListings(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getOrderListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getOrderListing();
  }, [allParams])

 
  //making data format for table input
  useEffect(() => {
    if (rejectedOrders?.items?.orders?.length >= 0) {
      let data = rejectedOrders?.items?.orders?.map((item) => {
        return {
          order_id: item.order_id,
          user_id: item.user_id,
          email: item.user.email,
          payment_type: item.payment_type,
          payment_status: item.payment_status,
          ordered_on: item.ordered_on,
          accepted_or_rejected_on: item.accepted_or_rejected_on,
          previous_order_id: item.previous_order_id,
          payment_on: item.payment_on,
          order_type: item.order_type,
          order_status: item.order_status,
          invoice_id: item?.invoice?.invoice_id || null,
          gst_number: item?.gst_number,
          paid_on: item?.paid_on,
          comment: item?.comment,
          irn_status: item?.irn_status,
          special_comment: item?.grouping_manage?.special_comment || null,
        }
      })
      setOrderListing(data)
    }
  }, [rejectedOrders.items]);

  const handleCreateCreditNoteIRNModalOpen = (value) => {
    setCreateCreditNoteModal(value);
  }

  const handleCreateCreditNoteIRN = async (value) => {
    if (value && creditNoteIRNData) {
      //API is same for To Create Credit Note same as Genreate IRN but only diffrence is 
      //to send type to IRN api that is managing from backend we just need to send orderId in payload.
      let resp = await generateIrn({ order_id: creditNoteIRNData })
      if (resp) {
        getOrderListing()
        setCreditNoteIRNData(null)
      }
    } else {
      setCreditNoteIRNData(null)
    }
  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "end" }}>
          {data?.gst_number && data.irn_status != '2' && data.order_status == '2' ? <CustomButton toolTipTittle={'Create Credit Note'} onClick={() => {
            handleCreateCreditNoteIRNModalOpen(true)
            setCreditNoteIRNData(data?.order_id)

          }} className="buttonDense" sx={{ bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText', whiteSpace: "nowrap" }}>Create Credit Note</CustomButton> : null}
          <Link className='textDecoration-none' to={`../creditNote?orderId=${crypt(ApiConfig.REACT_APP_SALT + 'order_id', data?.order_id)}`} target={'_blank'} style={{ color: theme.palette.primary.contrastText }}>
            <CustomButton toolTipTittle={'Credit Note'} className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Article /></CustomButton>
          </Link>
        </div>
      </>
    );
  };
  const actionHead = (data) => {
    return (<div style={{display: "flex"}}>
       hi
    </div>);
  };

  return (
    <>
      <MatTable
        loading={rejectedOrders?.loading}
        showCheckbox={false}
        columns={column}
        data={orderListing}
        onApplyFilter={(data = {}) => {
          getOrderListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        showPageLimit={true}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        perPage={limit}
        total={rejectedOrders?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getOrderListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getOrderListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        rangeDateFilter
        disableSearch
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
          />
        )}
      />
      {createCreditNoteModal && <ConfirmationModal open={createCreditNoteModal} handleConfirm={handleCreateCreditNoteIRN} setOpen={handleCreateCreditNoteIRNModalOpen} confirmationDesc={"Are you sure want to Create Credit Note IRN?"} />}
    </>
  );
}

export default RejectedOrder;
