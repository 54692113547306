import { IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { breakStringIntoLines, downloadBlobXLSFile, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { logsItemsSelector } from '../../redux/logs';
import MatTable from '../Tables/MatTable';
import { deleteLogs, getAllLogsListing, getLogsListingExportFile } from '../../Services/logsApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { Delete } from '@material-ui/icons';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from '../Modals/ConfirmationModal';
import { LogsSortData } from '../../data/logsData';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';


// const limit = 10;

const column = [
  {
    label: 'ID',
    id: 'search_id',
    format: (columnData) => (
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
        >
          {columnData}
        </Typography>
    ),
  },
  {
    label: 'Keywords',
    id: 'keywords',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData?breakStringIntoLines(columnData,20):'N/A'}
      </span>
    ),
  },
  {
    label: 'Search Count',
    id: 'total_count',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'Type',
    id: 'type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData==='1'?'Image':'Video'}
      </span>
    ),
  },
  {
    label: 'Total Record',
    id: 'total_record',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Created At',
    id: 'created_at',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'dd/mm/yyyy')}
      </span>
    ),
  },
];

function AllSearchLog() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, allLogs } = useSelector(logsItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [deleteLogsData, setDeleteLogsData] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmAllDelete, setConfirmAllDelete] = useState(false);
  const [expLoading, setExpLoading] = useState(false);
  const [limit, setLimit] = useState(20);

  const [filtersArray, setFiltersArray] = useState([

    {
      label: 'From Date',
      id: 'from',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[1].value =  e.target.value;
          if(e.target.value){
          if(e.target.value && newState[1].value ){
            CountryListCalling(e.target.value,newState[1].value)
          }
          if(e.target.value && newState[0].value && newState[4]?.value){
            stateListCallingPaid(e.target.value,newState[1].value,newState[4]?.value)
          }
        }
          return newState;
        });
        },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'to',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          if(newState[0].value> e.target.value){
            toast.error("To date should less than from date")
          }
          else{
          newState[index].value = e.target.value;
          if(e.target.value && newState[0].value ){
            CountryListCalling(newState[0].value,e.target.value)
          }
          if(e.target.value && newState[0].value && newState[4]?.value){
            stateListCallingPaid(newState[0].value,e.target.value,newState[4]?.value)
          }
          }
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'Order Type',
      id: 'image_type',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
        { label: 'IV', value: 'IV' },
        { label: 'NPlan', value: 'NPlan' },
      ],
      props: {},
      isVisible: true,
    },
    {
      label: 'Payment Type',
      id: 'payment_type',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
        { label: 'Cheque/demand draft', value: 'Cheque/demand draft' },
        { label: 'NEFT/RTGS/WT', value: 'NEFT/RTGS/WT' },
        { label: 'RazorPay', value: 'RazorPay' },
        { label: 'American Express', value: 'American Express' },
        { label: 'Pay10', value: 'Pay10' },
        { label: 'Zaakpay', value: 'Zaakpay' },
        { label: 'Paypal', value: 'Paypal' }

      ],
      props: {},
      isVisible: true,
    },
    {
      label: 'Select Country',
      id: 'country_id',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          if (e.target.value) {
            newState[0].isVisible = true;
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            if(newState[0].value && newState[1].value && e.target.value){
              stateListCallingPaid(newState[0].value,newState[1].value,e.target.value);
            }
            newState[5].value = '';
            newState[5].dataArr = [{ label: 'Select', value: '' }]
          }
          else {
            newState[0].isVisible = true;
            newState[5].isVisible=false
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            newState[3].isVisible = true;
            newState[4].isVisible = true;
            newState[5].value = '';
          }
          return newState;
        });
      },
      style: {},
      dataArr: [{ label: 'Select', value: '' }],
      props: {},
      isVisible: true,
    },
    {
      label: 'Select State',
      id: 'state',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });

      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
      ],
      props: {},
      isVisible: false,
    },
    
  
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },


  ]);

  const getListing = async (params={}) => {
    const payload = {
        page: String(1), 
        limit: limit,
        // order_status: String(1),
        type: "2",
        ...allParams,
        ...params
    }
    dispatch(getAllLogsListing(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])

  const handleConfirmDelete = async(value) =>{
    if(value && deleteLogsData){
      const resp = await deleteLogs({log_id:deleteLogsData?.search_id});
      if(resp){
        let payloadForLogs = {
          action:action?.delete,
          module: modulesNames?.logs,
          description:logsMessagesList?.searchLogs?.searchLogsDelete
        };
        await adminAddLogs(payloadForLogs);
        getListing({page: '1'})
        setPage(1)
      }
    }else{
      setDeleteLogsData(null)
    }
  }

  const handleConfirmAllDelete = async(value) =>{
    if(value ){
      const resp = await deleteLogs({flag:'all',type:'2'});
      if(resp){
        let payloadForLogs = {
          action:action?.delete,
          module: modulesNames?.logs,
          description:logsMessagesList?.searchLogs?.searchLogsAllDelete
        };
        await adminAddLogs(payloadForLogs);
        getListing({page: '1'})
        setPage(1)
      }
    }else{
      setDeleteLogsData(null)
    }
  }
  //making data format for table input
  useEffect(() => {
    if(allLogs?.items?.data?.length >= 0){
      setTableListing(allLogs?.items?.data)
    }
  }, [allLogs.items]);



  const getExportFile = async (params={}) => {
    try{

      let payload = {
        ...allParams,
        ...params,
        type:"2"
      }
       setExpLoading(true)
      let resp = await getLogsListingExportFile(payload)
      if(resp){
        downloadBlobXLSFile(resp?.data, `SearchLogs`)
      }else{
        toast.error('Something went wrong.')
      }
      setExpLoading(false)
    }catch(e){
      console.log(e)
      setExpLoading(false)
    }
  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{display: "flex"}}>
           <CustomButton className="noMinWidth buttonDense" onClick={()=>{
            handleCofirmModalOpen(true)
            setDeleteLogsData(data)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Delete/></CustomButton>
          
        </div>
      </>
    );
  };

  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  }

  const handleCofirmAllModalOpen = (value) => {
    setConfirmAllDelete(value);
  }

  const handlePendingOrderDelete = async (data, resetState) => {
    const resp = await deleteLogs({log_id:data});
      if(resp){
        let payloadForLogs = {
          action:action?.delete,
          module: modulesNames?.logs,
          description:logsMessagesList?.searchLogs?.searchLogsDelete
        };
        await adminAddLogs(payloadForLogs);
        getListing({page: '1'});
        setPage(1);
        resetState([]);
      }
  }

  return (
    <>
      <MatTable
        loading={allLogs?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data={})=>{
          getListing({
            page: String(1), 
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date','sort_by'),
          });
          if(Object.keys(data).length > 0){
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date','sort_by'),
            });
          }else{
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        showPageLimit={true}
        pageLimitArray={[20,50,100,200]}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        total={allLogs?.items?.totalCount || 0}
        checkboxEnable={true}
        customCheckboxDataKey={'search_id'}
        onTableToolbarRightClick={handlePendingOrderDelete}
        customLabel={'Delete'}
        onTableToolbarRightSection={<IconButton >
            <DeleteIcon />
        </IconButton>}
        setPage={setPage}
        handleSearch={(searchTerm)=>{
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage)=>{
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolbarEnable={true}
        dropdownFilter={true}
        selectFilterArray={[
          {
            name: 'sort_by',
            label: 'Sort By',
            placeholder: 'Sort By',
            options: LogsSortData
          },
        ]}
        toolBarButton={() => {
          return (
            <>
            {allLogs?.items?.totalCount>0 &&
            <>
              <CustomButton
                onClick={() => {
                  handleCofirmAllModalOpen(true)
                }}
                color={'buttonPrimary'}
                 sx={(theme)=>({ padding: '10px 10px', marginRight: 1, 
                [theme.breakpoints.down("md")]: {
                  flex: 1,
                }
              })}
              >
                Bulk Delete
              </CustomButton>

              <CustomButton sx={(theme)=>({ fontSize: '12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText',
            [theme.breakpoints.down("md")]: {
              flex: 1,
            }
           })} onClick={()=>{
            getExportFile()
          }} loading={expLoading}>Export XLS</CustomButton>
              </>
            }
            </>
          );
        }}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />
      {confirmDelete && <ConfirmationModal open={confirmDelete} handleConfirm={handleConfirmDelete} setOpen={handleCofirmModalOpen}/>}
      {confirmAllDelete && <ConfirmationModal open={confirmAllDelete} handleConfirm={handleConfirmAllDelete} setOpen={handleCofirmAllModalOpen}/>}

    </>
  );
}

export default AllSearchLog;
