import React, { useEffect, useMemo, useRef, useState } from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import './chat.css'; // Optional: for styling
import {
  AdminContributorQueryList,
  SendQueryToContributor,
} from '../../Services/ContributorApis';
import {
  action,
  logsMessagesList,
  modulesNames,
} from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

function ContributorChatPage({ qid, id, query }) {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const contributorData = JSON.parse(localStorage.getItem('contibuter_data'));
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    callChatHistory();
  }, []);

  const callChatHistory = async () => {
    let payload = { query_id: qid, contributor_id: id };
    let resp = await AdminContributorQueryList(payload);
    if (resp) {
      setMessages(resp?.data?.data?.rows);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (input.trim()) {
      setInput('');
      setSendButtonDisabled(true);
      let payload = { contributor_id: id, message: input, query_id: qid };
      let resp = await SendQueryToContributor(payload);
      if (resp?.status == 200) {
        let payloadForLogs = {
          action: action?.response,
          module: modulesNames?.contributors,
          description: logsMessagesList?.contributor?.contributorQueryRespond,
        };
        await adminAddLogs(payloadForLogs);
        setSendButtonDisabled(false);
        callChatHistory();
      }
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div>
      <CustomBox padding={2} marginBottom={2}>
        <b style={{ color: '#464255' }}>Query:</b> {query}
      </CustomBox>
      <CustomBox padding={3}>
        <div className="chat-container">
          <div className="chat-messages">
            {messages?.map((msg, index) => (
              <div
                key={index}
                className={`chat-message ${
                  msg.created_by === 'admin' ? 'User' : 'Other'
                }`}
              >
                {msg?.message}
                <div ref={messagesEndRef} />
              </div>
            ))}
            {messages?.length == 0 ? (
              <div className="no-chats">reply to this query</div>
            ) : (
              ''
            )}
          </div>
          <form onSubmit={handleSendMessage} className="chat-input">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type a message..."
            />
            <button type="submit" disabled={sendButtonDisabled}>
              Send
            </button>
          </form>
        </div>
      </CustomBox>
    </div>
  );
}

export default ContributorChatPage;
