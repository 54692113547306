import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { addVisibleKeyword } from '../../Services/keywordsApis';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import {
  adminAddLogs,
  getCountry,
  getJobDescription,
  getState,
} from '../../Services/commonService';
import {
  contributorBankAndOtherInfo,
  createContributor,
  editContributor,
} from '../../Services/ContributorApis';
import { commonItemsSelector } from '../../redux/common';
import { useDispatch, useSelector } from 'react-redux';
import { contributorItemsSelector } from '../../redux/contributor';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { useParams } from 'react-router-dom';
import ConfirmationModal from '../Modals/ConfirmationModal';
import {
  action,
  logsMessagesList,
  modulesNames,
} from '../../data/logsMessages';
const limit = 10;

function EditContributorInfo({ open, handleOpen, getListing }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { contributorId } = useParams();
  const { countryData, stateData } = useSelector(commonItemsSelector);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [countryShortName, setCountryShortName] = useState('in');
  const [selectedCountry, setSelcetedCountry] = useState('');
  const [selectedState, setSelectedState] = useState({});
  const { contributorBasicInfo } = useSelector(contributorItemsSelector);
  const selectedData = contributorBasicInfo?.items?.contributorDetails;
  const [confirmSave, setConfirmSave] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    const updatedValues = {
      ...formik.values,
      contributor_id: selectedData?.contributor_id,
    };
    let resp = await editContributor(updatedValues);
    if (resp) {
      let payloadForLogs = {
        action: action?.update,
        module: modulesNames?.contributors,
        description: logsMessagesList?.contributor?.contributorDetailUpdate,
      };
      await adminAddLogs(payloadForLogs);
      ContributorDataFetch();
    }

    setLoading(false);
  };
  useEffect(() => {
    if (stateData && Array.isArray(stateData?.items)) {
      let data = stateData?.items?.map((item) => {
        return {
          label: item?.state_name,
          value: item?.state_id,
        };
      });
      setStateList(data);
    }
  }, [stateData]);

  useEffect(() => {
    ContributorDataFetch();
  }, [contributorId]);

  useEffect(() => {
    if (countryData && Array.isArray(countryData?.items)) {
      let data = countryData?.items?.map((item) => {
        return {
          label: item?.country_name,
          value: item?.country_id,
        };
      });
      setCountryList(data);
    }
  }, [countryData]);
  useEffect(() => {
    getCountryDetails();
  }, []);

  const getCountryDetails = async () => {
    dispatch(getCountry());
  };

  const handleChangeMobile = (val) => {
    formik.setFieldValue('mobile', val);
  };
  const handleChangeCountry = async (e) => {
    formik.setFieldValue('country', e.target.value);
    formik.setFieldValue('state', '');
    setSelcetedCountry(e.target.value || '');
    setSelectedState({});
    let country_id = 0;
    countryData?.items?.filter((val) => {
      if (val.country_name === e.target.value) {
        country_id = val.country_id;
        setCountryShortName(val?.country_short_name?.toLowerCase());
        formik.setFieldValue('mobile', '');
      }
    });
    dispatch(getState(country_id));
  };
  const handleStateChange = (val) => {
    let stateSelected = stateData?.items?.filter(
      (item) => item.state_name == val,
    );
    if (stateSelected.length) {
      setSelectedState(stateSelected[0]);
    }
  };

  const handleCofirmModalOpen = (value) => {
    setConfirmSave(false);
    setLoading(false);
  };

  const showModel = () => {
    setConfirmSave(true);
  };
  const getStateData = async (user) => {
    if (user?.items?.countries) {
      dispatch(getState(user?.items?.countries?.country_id));
    }
  };
  const formik = useFormik({
    initialValues: {
      email: selectedData?.email || '',
      name: selectedData?.name || '',
      address: selectedData?.address || '',
      city: selectedData?.city || '',
      country: selectedData?.country || '',
      state: selectedData?.state || '',
      mobile: selectedData?.mobile || '',
      telephone: selectedData?.telephone || '',
      inden_code: selectedData?.inden_code || '',
      percentage: selectedData?.percentage || '',
      password: selectedData?.password || '',
      status: selectedData?.status || '0',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter Email')
        .required('Please enter Email'),
      name: Yup.string().required('Name is required'),
      address: Yup.string().required('Address is required'),
      city: Yup.string().required('City is required'),
      country: Yup.string().required('Country Name is required'),
      state:
        stateData.isStateAvailable === true &&
        Yup.string().required('State Name is required'),
      mobile: Yup.string()
        .max(18, 'Mobile Number is Invalid')
        .min(10, 'Mobile Number is Invalid')
        .required('Mobile Number is required'),
      telephone: Yup.string()
        .required('Telephone Number is required.')
        .matches(/^[\d -]+$/, 'It should be number only.')
        .max(50, 'Max length will be 50.'),
      inden_code: Yup.string().required('Inden Code is required'),
      percentage: Yup.string()
        .matches(
          /^([0-9]\.[0-9]{1}|[0-9]\.[0-9]{2}|\.[0-9]{2}|[1-9][0-9]\.[0-9]{1}|[1-9][0-9]\.[0-9]{2}|[0-9][0-9]|[1-9][0-9]\.[0-9]{2})$|^([0-9]|[0-9][0-9]|[0-99])$|^100$/gm,
          'Value must be number and should be in range of [0 - 100] with allowed upto two decimals.',
        )
        .required('Percentage is required'),

      password: Yup.string().required('Password is required'),
      status: Yup.string().required('Status is required'),
    }),
    onSubmit: showModel,
  });

  const ContributorDataFetch = async () => {
    if (contributorId) {
      let resp = await dispatch(
        contributorBankAndOtherInfo({ contributor_id: contributorId }),
      );
    }
  };

  const handleFormVal = (val) => {
    if (val) {
      handleConfirm();
    }
  };

  return (
    <div>
      <CustomBox>
        <Grid container spacing={2} marginBottom={4}>
          <Grid item xs={4} md={4} lg={4}>
            <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
              <Typography variant="span" className="fontWeight600">
                {' '}
                Total Images:
              </Typography>{' '}
              {contributorBasicInfo?.items?.totalImages || 0}
            </span>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
              <Typography variant="span" className="fontWeight600">
                {' '}
                Pending Images:
              </Typography>{' '}
              {contributorBasicInfo?.items?.pendingimages || 0}
            </span>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
              <Typography variant="span" className="fontWeight600">
                {' '}
                Sold Images:
              </Typography>{' '}
              {contributorBasicInfo?.items?.totalsoldImages || 0}
            </span>
          </Grid>
        </Grid>

        <Box noValidate component="form" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="email"
                label="Email"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="name"
                label="Name"
                placeholder="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="address"
                label="Address"
                placeholder="Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="city"
                label="City"
                placeholder="City"
                value={formik.values.city}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                name="country"
                label="Country"
                id="country"
                fullWidth
                placeholder="Country"
                SelectProps={{
                  native: true,
                }}
                select
                value={formik.values.country}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => handleChangeCountry(e)}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              >
                <option key={'Select Country'} value={''}>
                  Select Country
                </option>
                {countryList &&
                  countryList?.map((country) => (
                    <option key={country.value} value={country.label}>
                      {country.label}
                    </option>
                  ))}
              </CustomInput>
            </Grid>
            {stateData.isStateAvailable === true && (
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  name="state"
                  label="State"
                  fullWidth
                  placeholder="State"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  value={formik.values.state}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleStateChange(e.target.value);
                  }}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                >
                  <option key={'Select State'} value={''}>
                    {formik.values.country
                      ? 'Select state'
                      : 'Please select country first'}
                  </option>
                  {stateList &&
                    stateList?.map((state) => {
                      return (
                        <option key={state.value} value={state.label}>
                          {state.label}
                        </option>
                      );
                    })}
                </CustomInput>
              </Grid>
            )}
            <Grid item xs={12} md={6} lg={4}>
              <CustomPhoneInput
                label="Mobile"
                id="mobile"
                name="mobile"
                className="main-login-form"
                fullWidth
                placeholder="Mobile Number"
                native={true}
                value={formik.values.mobile}
                defaultCountry={`${countryShortName}`}
                country={`${countryShortName}`}
                divStyle={{
                  paddingTop: 4,
                }}
                variant="outlined"
                onChange={(newValue, info) => {
                  handleChangeMobile(newValue);
                }}
                onlyCountries={[countryShortName]}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="telephone"
                label="Telephone"
                placeholder="Telephone"
                value={formik.values.telephone}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={
                  formik.touched.telephone && Boolean(formik.errors.telephone)
                }
                helperText={formik.touched.telephone && formik.errors.telephone}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="inden_code"
                label="Inden Code"
                placeholder="Inden Code"
                value={formik.values.inden_code}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={
                  formik.touched.inden_code && Boolean(formik.errors.inden_code)
                }
                helperText={
                  formik.touched.inden_code && formik.errors.inden_code
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="percentage"
                label="Percentage"
                placeholder="Percentage"
                value={formik.values.percentage}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={
                  formik.touched.percentage && Boolean(formik.errors.percentage)
                }
                helperText={
                  formik.touched.percentage && formik.errors.percentage
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="password"
                label="Password"
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="status"
                label="Status"
                placeholder="Status"
                value={formik.values.status}
                select
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.status && Boolean(formik.errors.status)}
                helperText={formik.touched.status && formik.errors.status}
              >
                <option value={'0'}>Inactive</option>
                <option value={'1'}>Active</option>
              </CustomInput>
            </Grid>
          </Grid>
          <div
            style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
          >
            <CustomButton
              color={'buttonPrimary'}
              type="submit"
              loading={loading}
            >
              Confirm
            </CustomButton>
          </div>
        </Box>
      </CustomBox>
      {
        <ConfirmationModal
          open={confirmSave}
          handleConfirm={handleFormVal}
          setOpen={handleCofirmModalOpen}
        />
      }
    </div>
  );
}

export default EditContributorInfo;
