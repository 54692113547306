import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { formatDate } from '../../lib/helper';
import { addSaleFollowupFollowUps, createPaymentFollowUps } from '../../Services/CRMFollowups';
import { useDispatch, useSelector } from 'react-redux';
import { loginItemsSelector } from '../../redux/login';
import { KnowAboutIb, RequirementFollowups } from '../../data/followUpsdata';
import CustomCheckbox from '../CommonComp/CustomInput/CheckboxField';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import { commonItemsSelector } from '../../redux/common';
import { adminAddLogs, getCountry, getState } from '../../Services/commonService';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';

function CreateDailySaleFollow({ open, handleOpen, getListing, selectedData }) {
  const [loading, setLoading] = useState(false);
  const { user } = useSelector(loginItemsSelector);
  const { countryData, stateData } = useSelector(commonItemsSelector);
  const [countryList, setCountryList] = useState([]);
  const [countryShortName, setCountryShortName] = useState('in');
  const [stateList, setStateList] = useState([]);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let resp = await addSaleFollowupFollowUps(values);
    if (resp) {
      let payloadForLogs = {
        action: action?.followUp,
        module: modulesNames?.crm,
        description: logsMessagesList?.crm?.crmFollowUpTaken,
      };
      await adminAddLogs(payloadForLogs);
      resetForm();
      getListing();
      handleOpen(false);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      daily_sale_id: selectedData?.daily_sale_id || '',
      status: selectedData?.followup_status || '0',
      email: selectedData?.email || '',
      state: selectedData?.state || '',
      next_followup_date: formatDate(selectedData?.followup_date, 'yyyy-mm-dd') || '',
      description: '',
      order_id: selectedData?.order_id || '',
      contact_number: selectedData?.mobile_number || '',
      contact_person: selectedData?.first_name || '',
      followup_by: selectedData?.followup_by || 'call',
      requirement: selectedData?.requirement_type || "",
      created_by: selectedData?.first_created_by|| '',
      ib_option: selectedData?.ib_option || '',
      company_name: selectedData?.company_name || '',
      alternate_email: selectedData?.alternate_email || '',
      important: selectedData?.important ? true : false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      status: Yup.string().required('Requirement is required'),
      contact_number: Yup.string()
        .required("Contact number is required")
        .matches(/^\+?[0-9]+$/, "Must be only digits")
        .min(10, 'Contact number is required')
        .max(18, 'Contact number is required'),
      description: Yup.string().required('Description is required'),
      ib_option: Yup.string().required('This field is required'),
    }),
    onSubmit: handleConfirm,
  });


  useEffect(() => {
    if (selectedData.states) {
      getStateData(selectedData);
      if (selectedData?.countries) {
        setCountryShortName(selectedData?.countries?.country_short_name?.toLowerCase())
      }
    }
  }, [selectedData]);

  const getStateData = async (data) => {
    if (data?.countries) {
      dispatch(getState(data?.countries?.country_id || 1));
    }
  };

  useEffect(() => {
    if (stateData && Array.isArray(stateData?.items)) {
      let data = stateData?.items?.map((item) => {
        return {
          label: item?.state_name,
          value: item?.state_id,
        };
      });
      setStateList(data);
    }
  }, [stateData]);


  return (
    <div>
      <Dialog
        maxWidth={'md'}
        fullWidth
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
      >

        <DialogTitle variant="h3">Create Daily Sales Entry Follow Ups</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9} lg={9}>
              <Typography variant='h5' mb={2}>Email: {formik.values.email}</Typography>

            </Grid>
          </Grid>

          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="status"
                  label="Requirement followups"
                  placeholder="Requirement followups"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.status && Boolean(formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                >
                  <option key={'Select Status'} value={''}>Please Select</option>
                  {RequirementFollowups?.map((item, index) => {
                    return <option key={item?.value} value={item?.value}>{item?.radioLable}</option>
                  })}
                </CustomInput>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="followup_by"
                  label="Followups by"
                  placeholder="Followups by"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  value={formik.values.followup_by}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.followup_by &&
                    Boolean(formik.errors.followup_by)
                  }
                  helperText={
                    formik.touched.followup_by && formik.errors.followup_by
                  }
                >
                  <option key="call" value="call">Call</option>
                  <option key="email" value="email">Email</option>
                </CustomInput>
              </Grid>
              {stateData.isStateAvailable === true && selectedData.state ? (<Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  name="state"
                  label="State"
                  placeholder="State"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  value={formik.values.state}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  fullWidth
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleStateChange(e.target.value);
                  }}
                  error={
                    formik.touched.state &&
                    Boolean(formik.errors.state)
                  }
                  helperText={
                    formik.touched.state && formik.errors.state
                  }
                >
                  {stateList &&
                    stateList?.map((state) => {
                      return (
                        <option key={state.value} value={state.label}>
                          {state.label}
                        </option>
                      );
                    })}
                </CustomInput>
              </Grid>) : null}
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="ib_option"
                  label="How they came to know about IB"
                  placeholder="How they came to know about IB"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  value={formik.values.ib_option}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.ib_option && Boolean(formik.errors.ib_option)}
                  helperText={formik.touched.ib_option && formik.errors.ib_option}
                >
                  {KnowAboutIb?.map((item, index) => {
                    return <option key={item?.value} value={item?.value}>{item?.label}</option>
                  })}
                </CustomInput>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="company_name"
                  label="Company name"
                  placeholder="Company name"
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.company_name &&
                    Boolean(formik.errors.company_name)
                  }
                  helperText={
                    formik.touched.company_name && formik.errors.company_name
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  name="next_followup_date"
                  type="date"
                  label="Next Follow Ups Date"
                  placeholder="Next Follow Ups Date"
                  value={formatDate(formik.values.next_followup_date, 'yyyy-mm-dd')} //2017-06-13T13:00
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  inputProps={{
                    min: formatDate(new Date(), 'yyyy-mm-dd')
                  }}
                  error={
                    formik.touched.next_followup_date &&
                    Boolean(formik.errors.next_followup_date)
                  }
                  helperText={
                    formik.touched.next_followup_date &&
                    formik.errors.next_followup_date
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="contact_number"
                  id="mobile"
                  label="Contact Number"
                  placeholder="Contact Number"
                  value={formik.values.contact_number}
                  error={formik.touched.contact_number && Boolean(formik.errors.contact_number)}
                  helperText={formik.touched.contact_number && formik.errors.contact_number}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    formik.setFieldValue("contact_number", e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="contact_person"
                  label="Contact person"
                  placeholder="Contact person"
                  value={formik.values.contact_person}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.contact_person &&
                    Boolean(formik.errors.contact_person)
                  }
                  helperText={
                    formik.touched.contact_person && formik.errors.contact_person
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="alternate_email"
                  label="Alternate Email"
                  placeholder="Alternate Email"
                  value={formik.values.alternate_email}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.alternate_email &&
                    Boolean(formik.errors.alternate_email)
                  }
                  helperText={
                    formik.touched.alternate_email && formik.errors.alternate_email
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="requirement_type"
                  label="Requirement Type"
                  placeholder="Requirement Type"
                  value={formik.values.requirement}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  disabled
                  error={
                    formik.touched.requirement_type &&
                    Boolean(formik.errors.requirement_type)
                  }
                  helperText={
                    formik.touched.requirement_type && formik.errors.requirement_type
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="created_by"
                  label="Created By"
                  placeholder="Created By"
                  value={formik.values.created_by}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  disabled
                  error={
                    formik.touched.created_by &&
                    Boolean(formik.errors.created_by)
                  }
                  helperText={
                    formik.touched.created_by && formik.errors.created_by
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomCheckbox
                  name="important"
                  labelPlacement={'start'}
                  subLableText={'Important follow ups'}
                  value={formik.values.important}
                  handleChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="order_id"
                  label="Order ID"
                  placeholder="Order ID"
                  value={formik.values.order_id}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.order_id && Boolean(formik.errors.order_id)
                  }
                  helperText={formik.touched.order_id && formik.errors.order_id}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  name="description"
                  label="Description"
                  placeholder="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.description && Boolean(formik.errors.description)
                  }
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Confirm
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    email: '',
                    status: '',
                    next_followup_date: '',
                    heading: '',
                    description: '',
                    createdBy: '',
                  });
                  handleOpen(false);
                }}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CreateDailySaleFollow;
