import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { addVisibleKeyword } from '../../Services/keywordsApis';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';
const limit = 10;

function AddVisibleKeyword({ open, handleOpen, getListing }) {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await addVisibleKeyword(values);
    console.log("resp::")
    if (resp) {
      let payloadForLogs = {
        action: action?.add,
        module: modulesNames?.keywords,
        description: logsMessagesList?.keyword?.keywordVisibleAdd,
      };
      console.log("IN adn",payloadForLogs);
      await adminAddLogs(payloadForLogs);
      resetForm({
        visible_keyword_name: "",
        visible_keyword_status: "0",
      })
      getListing()
      handleOpen(false)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      visible_keyword_name: "",
      visible_keyword_status: "0",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      visible_keyword_name: Yup.string().required('Visible keyword name is required.'),
      visible_keyword_status: Yup.string().required('Visible keyword status is required.')
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
        fullWidth
      >
        <DialogTitle variant='h3'>Add Visible Keyword</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              // width: 400
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="visible_keyword_name"
                  label="Visible Keyword"
                  placeholder="Enter Visible Keyword"
                  value={formik.values.visible_keyword_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.visible_keyword_name && Boolean(formik.errors.visible_keyword_name)}
                  helperText={formik.touched.visible_keyword_name && formik.errors.visible_keyword_name}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="visible_keyword_status"
                  label="Status"
                  placeholder="Status"
                  value={formik.values.visible_keyword_status}
                  select
                  SelectProps={{
                    native: true
                  }}
                  InputLabelProps={{ shrink: true }}
                  onChange={formik.handleChange}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.visible_keyword_status && Boolean(formik.errors.visible_keyword_status)}
                  helperText={formik.touched.visible_keyword_status && formik.errors.visible_keyword_status}
                >
                  <option value={"0"}>Inactive</option>
                  <option value={"1"}>Active</option>
                </CustomInput>
              </Grid>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}>
              <CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Confirm</CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    visible_keyword_name: "",
                    visible_keyword_status: "0",
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >Cancel</CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddVisibleKeyword;
