import React, { useEffect, useMemo, useState } from 'react';
import ApiConfig from '../../config/ApiConfig';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import Divder from '../CommonComp/Divder';
import MatTable from '../Tables/MatTable';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ModelImagesListings } from '../../Services/modelApi';
import { formatDate, getNewParameters, getParameters } from '../../lib/helper';
import { modelItemsSelector } from '../../redux/model';

const column = [
 
  {
    label: '',
    id: 'image',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <>
        <CustomImage
          style={{ maxWidth: 150, borderRadius: 8 }}
          src={`${ApiConfig.BASE_IMAGE_URL}${row?.image?.shootid}/${row?.image?.rank}-${row?.image?.Name}.jpg`}
        />
      </>
    ),
  },
  {
    label: 'Name',
    id: 'imageid',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Model Release Id',
    id: 'modelreleaseid',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
   {
    label: 'Date',
    id: 'createdAt',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content"}}>{formatDate(columnData)}</span>)
   },
  //suspendate
  
];




function ModelImagesPage() {
  const limit = 10;
  const {sno} = useParams();
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, modelImagesList } = useSelector(modelItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const getListing = async (params={}) => {
    const payload = {
        page: String(1), 
        limit: limit,
        model_id: Number(sno),
        ...allParams,
        ...params
    }
    dispatch(ModelImagesListings(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])

  //making data format for table input
  useEffect(() => {
    if(modelImagesList?.items?.data?.length>=0){
      setTableListing(modelImagesList?.items?.data)
    }
  
  }, [modelImagesList?.items]);


  return (
    <div>
      
      <Divder spacing={1} />
      <MatTable
        loading={modelImagesList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        onApplyFilter={(data={})=>{
          getListing({
            page: String(1), 
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if(Object.keys(data).length > 0){
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          }else{
            setAllParams({
              page: String(1),
            });
          }
        }}
        // showFilterButton={true}
        // showSerialNo={false}
        // action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        // setFilters={setFilters}
        // filters={filters}
        total={modelImagesList?.items?.totalCount || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm)=>{
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage)=>{
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        // toolBarButton={()=>{
        //   return <>
        //     <CustomButton onClick={()=>{handleAdd(true)}} color={'buttonPrimary'}  sx={(theme)=>({ padding: '10px 10px', marginRight: 1, 
        //         [theme.breakpoints.down("md")]: {
        //           flex: 1,
        //         }
        //       })}>Add Model</CustomButton>
        //   </>
        // }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />
      
    </div>
  );
}

export default ModelImagesPage;
