import { useTheme } from '@mui/material';
import React, {  useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { crypt, currencyConversion, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { orderListingItemsSelector } from '../../redux/orderListing';
import { getProposalListing } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import ProposalInsertToCartModal from '../Modals/ProposalInsertToCartModal';
import PreviewIcon from '@mui/icons-material/Preview';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { deleteProposal } from '../../Services/proposalApis';
import ApiConfig from '../../config/ApiConfig';
import ConfirmationModal from '../Modals/ConfirmationModal';
import AllFilters from './AllFilters';
import { SearchByProposal } from '../../data/userData'; 
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';


const column = [
  {
    label: 'Proposal ID',
    id: 'proposal_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData}</span>)
  },
  {
    label: 'Heading',
    id: 'heading',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 170, minWidth: 150, width: '100%' }}>{columnData}</span>)
  },
  {
    label: 'Created By',
    id: 'user_name',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData}</span>)
  },
  {
    label: 'Created Date',
    id: 'created_at',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{formatDate(columnData) || 'N/A'}</span>
    ),
    nextLine:true,
  },
  {
    label: 'Images/ Videos Count',
    id: 'total_images',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData}</span>
    ),
    nextLine:true,
  },
  {
    label: 'Amount (INR)',
    id: 'amount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
        {columnData&&currencyConversion(columnData)  || 'N/A'}
        </span>
    ),
    nextLine:true,
  },
  {
    label: 'Discount (INR)',
    id: 'discount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
        {columnData&&currencyConversion(columnData)  || 'N/A'}

        </span>
    ),
    nextLine:true,
  },
  {
    label: 'Tax (INR)',
    id: 'service_tax',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
        {columnData&&currencyConversion(columnData)  || 'N/A'}
        </span>
    )
  },
  {
    label: 'Total Amount (INR)',
    id: 'final_amount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
               {columnData&&currencyConversion(columnData)  || 'N/A'}
        </span>
    ),
    nextLine:true
  },
  {
    label: 'Proposal Type',
    id: 'proposal_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData?.toLowerCase() == 'nplan' ? 'Normal' : 'Subscription'}</span>
    ),
    nextLine:true,
  },
];

function ProposalManagement() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [open, setOpen] = useState(false);
  const [confrimOpen, setConfirmOpen] = useState(false);
  const [proposalId, setProposalId] = useState("");
  const [allParams, setAllParams] = useState(getParameters());
  const theme = useTheme()
  const { loading, proposalListing } = useSelector(orderListingItemsSelector);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [filtersArray, setFiltersArray] = useState([

    {
      label: 'From Date',
      id: 'start_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[1].value = e.target.value;
          return newState;
        });
     
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'end_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
  
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'Search By',
      id: 'search_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          newState[3].value='';
          return newState;
        });
      },
      style: {},
      dataArr: SearchByProposal,
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...allParams,
      ...params,
    }
    if (payload?.start_date) {
      payload['start_date'] = formatDate(payload?.start_date, 'yyyy-mm-dd') + "  00:00:00"
    }
    if (payload?.end_date) {
      payload['end_date'] = formatDate(payload?.end_date, 'yyyy-mm-dd') + "  23:59:59"
    }
    dispatch(getProposalListing(payload))
    setPage(Number(payload.page))
  }
  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };
  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[2].isVisible = true;
      newState[3].isVisible = true;

      newState[0].value = '';
      newState[1].value = '';
      newState[2].value = '';
      newState[3].value = '';
      return newState;
    });
  };
  const handleOpen = (value, id = "") => {
    setProposalId(id);
    setOpen(value);
  }

  const handleProposalOpen = (value, id = "") => {
    setProposalId(id);
    setConfirmOpen(value);
  }

  const handleProposalDelete = async (value) => {
    try {
      if (value && deleteData) {
        const resp = await deleteProposal({ proposal_id: deleteData });
        if (resp) {
          let payloadForLogs = {
            action: action?.delete,
            module: modulesNames?.orders,
            description: logsMessagesList?.order?.proposalDeleted,
          };
          await adminAddLogs(payloadForLogs);
          getListing({ page: '1' })
          setPage(1)
        }
      } else {
        setDeleteData(null)
      }
    } catch (e) {
    }
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  useEffect(() => {
    if (proposalListing?.items?.elements?.length >= 0) {
      setTableListing(proposalListing?.items?.elements)
    }
  }, [proposalListing.items]);

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>

          {data?.proposal_type?.toLowerCase() == 'nplan' ? <CustomButton toolTipTittle={'Insert To Cart'} className="buttonDense noMinWidth" onClick={() => {
            handleOpen(true, data?.proposal_id)
          }} sx={{ fontSize: '12px', bgcolor: 'buttonSecondary.main', whiteSpace: 'nowrap', color: 'buttonSecondary.contrastText' }}><AddShoppingCartIcon /></CustomButton> : <></>}

          <CustomButton toolTipTittle={'Make Order'} className="buttonDense noMinWidth" onClick={() => {
            navigate(`/proposal/confirm?proposal_id=${crypt(ApiConfig.REACT_APP_SALT + "proposal_id", data?.proposal_id)}`)
          }} sx={{ fontSize: '12px', bgcolor: 'buttonSuccess.main', whiteSpace: 'nowrap', color: 'buttonSuccess.contrastText' }}><AssignmentTurnedInIcon /></CustomButton>

          <Link className='textDecoration-none' to={`/quotation?proposal_id=${crypt('proposal_id', data?.proposal_id)}`} target={'_blank'}
            style={{ color: theme.palette.primary.contrastText }}>
            <CustomButton toolTipTittle={'View Proposal'} className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonInfo.main' }}><PreviewIcon /></CustomButton></Link>
            <CustomButton onClick={() => {
            handleCofirmModalOpen(true)
            setDeleteData(data?.proposal_id)
          }} toolTipTittle={'Delete'} className="buttonDense noMinWidth" sx={{ fontSize: '12px', bgcolor: 'buttonDanger.main', color: 'buttonDanger.contrastText' }}><DeleteOutlineIcon /></CustomButton>
        </div>
      </>
    );
  };

  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  }

  return (
    <>
      <MatTable
        loading={proposalListing?.loading}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        showPageLimit={true}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        total={proposalListing?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })

        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}

        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        rangeDateFilter
        disableSearch
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
            extraButton={() => {
              return (
                <CustomButton onClick={() => {
                  navigate(`../proposal/createProposal`)
                }} color={'buttonPrimary'} sx={(theme) => ({
                  padding: '10px 10px', marginRight: 1, marginTop: 1,
                  [theme.breakpoints.down("md")]: {
                    flex: 1,
                  }
                })}>Create Proposal</CustomButton>
              )
            }}
          />
        )}
      />
      {open && <ProposalInsertToCartModal open={open} handleOpen={handleOpen} setPage={setPage} proposalId={proposalId} />}
      {confirmDelete && <ConfirmationModal open={confirmDelete} handleConfirm={handleProposalDelete} setOpen={handleCofirmModalOpen} />}

    </>
  );
}

export default ProposalManagement;
