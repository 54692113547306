import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';
import { DATA_LIMIT } from '../../data/constants';
import { addToCartFromAdmin, addToWishlistFromAdmin, deleteFromWishlist, userCartsApi, userWishlistApi } from '../../Services/userApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import ConfirmationModal from '../Modals/ConfirmationModal';
import MatTable from '../Tables/MatTable';
import { useNavigate, useParams } from 'react-router-dom';
import { userItemsSelector } from '../../redux/user';
import { toast } from 'react-toastify';
import AddToCartWishListModal from '../Modals/AddToCartWishListModal';
import { getNewParameters, getParameters } from '../../lib/helper';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'Content',
    id: 'image_url',
    format: (columnData) => (<span style={{ display: 'inline-block', width: "100%", textAlign: "left" }}

    > 
    <img style={{ maxWidth: 80, maxHeight: 200, borderRadius: 8 }} src={`${columnData}`} /></span>)
  },
  {
    label: 'ID',
    id: 'wishlist_id',
    format: (columnData) => (<span style={{
      display: 'inline-block', whiteSpace: "nowrap",
      cursor: "pointer"
    }}>{columnData}</span>)
  },
  {
    label: 'Image/Video ID',
    id: 'image_id',
    format: (columnData) => (<span style={{
      display: 'inline-block', whiteSpace: "nowrap",
      cursor: "pointer"
    }}>{columnData}</span>)
  },
  {
    label: 'Name',
    id: 'image_name',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Type',
    id: 'type',
    format: (columnData, row) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
      {columnData || 'N/A'}
    </span>)
  },
  {
    label: 'Shoot ID',
    id: 'shootid',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Rank',
    id: 'rank',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>)
  }
];

function UserWishlistData({ rightSection }) {
  const [tableListing, setTableListing] = useState([])
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allParams, setAllParams] = useState(getParameters());
  const [page, setPage] = useState(1);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const { userId } = useParams();
  const { singleUserWishlistDetail } = useSelector(userItemsSelector);
  const [from, setFrom] = useState(0);
  const [initialData, setInitialData] = useState([]);
  const [addToModalOpen, setAddToModalOpen] = useState(false);

  const getWishlistListing = async (params = {}, setPageState = () => { }) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...params,
    };
    dispatch(userWishlistApi(userId, payload));
    if (setPageState) {
      setPageState(Number(payload.page))
    }

  };

  const getListing = async (params = {}, setPage) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...params,
    };
    dispatch(userWishlistApi(userId, payload));
    setPage(Number(payload.page))
  };

  useEffect(() => {
    getWishlistListing();
  }, []);

  const getUserWishList = () => {
    let listPayload = {
      page: String(1),
      limit: limit - 5,
    };
    dispatch(userWishlistApi(userId, listPayload));
  }

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getWishlistListing();
  }, [allParams])

  useEffect(() => {
    if (singleUserWishlistDetail?.items?.wishlist?.rows?.length >= 0) {
      let data = singleUserWishlistDetail?.items?.wishlist?.rows?.map((item) => {
        return {
          image_url: `${ApiConfig.BASE_IMAGE_URL}${item?.shoot_id}/${item?.rank}-${item?.Name}.jpg` || '',
          image_id: item?.image_id,
          wishlist_id: item?.wishlist_id,
          image_name: item?.Name || '',
          shootid: item?.shoot_id || '',
          rank: item?.rank || '',
          sno: item?.sno || '',
          value: item?.Name || '',
          label: item?.Name || '',
          quality: null,
          type: item?.type == 2 ? 'video' : 'image'
        }
      })
      setTableListing(data)
    }
  }, [singleUserWishlistDetail?.items]);


  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <CustomButton onClick={async () => {
            handleMoveToCart(data)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', whiteSpace: 'nowrap', bgcolor: 'buttonInfo.main' }}>Move to cart</CustomButton>
          <CustomButton onClick={async () => {

            handleCofirmModalOpen(true)
            setDeleteData(data.image_id)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonInfo.main' }}>Delete</CustomButton>
        </div>
      </>
    );
  };

  const handleDelete = async (value) => {
    try {
      if (value && deleteData) {
        const resp = await deleteFromWishlist({
          user_id: userId,
          image_id: deleteData
        });
        let payloadForLogs = {
          action: action?.delete,
          module: modulesNames?.users,
          description: logsMessagesList?.users?.userDeleteFromWishList,
        };
        adminAddLogs(payloadForLogs);
        if (resp) {
          dispatch(userWishlistApi(userId, {
            page: 1,
            limit: 5,
          }));
          setPage(1)
        }
      } else {
        setDeleteData(null)
      }
    } catch (e) {
    }
  }

  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  }

  const handleMoveToCart = async (item, index, length) => {
    let data = [item];
    setInitialData(data);
    handleAddToModalOpen(true, 0);
  };

  const handleAddToModalOpen = (value, from) => {
    setAddToModalOpen(value);
    setFrom(from);
    if (value == false) {
      setInitialData([]);
    }
  };

  const handleAddToCart = async (item, index, length, values, state, price) => {
    if (item?.quality) {
      let resp;
      if (values?.is_star == "1") {
        resp = await addToCartFromAdmin({
          user_id: userId,
          image_id: item.sno,
          image_type: item.quality,
          type: item?.type === 'image' ? '1' : item?.type === 'video' ? '2' : '',
          country: values?.is_star == "1" ? values.country : "",
          duration: values.duration,
          industry: values.industry,
          state: values.state?.length ? values.state?.map((i) => `${i.label}`).join(',') : "",
          is_star: values.is_star,
          license: values.license,
          price: price
        });
        let payloadForLogs = {
          action: action?.AddToCart,
          module: modulesNames?.users,
          description: logsMessagesList?.users?.userCart,
        };
        adminAddLogs(payloadForLogs);
      }
      else {
        resp = await addToCartFromAdmin({
          user_id: userId,
          image_id: item.sno,
          image_type: item.quality,
          type: item?.type === 'image' ? '1' : item?.type === 'video' ? '2' : '',
        });
        let payloadForLogs = {
          action: action?.AddToCart,
          module: modulesNames?.users,
          description: logsMessagesList?.users?.userCart,
        };
        adminAddLogs(payloadForLogs);
      }
      if (resp) {
        dispatch(userCartsApi(userId, { page: 1, limit: 5 }));
        if (length == 1) {
          handleAddToModalOpen(false, 0);
        }
        return true;
      }
      return false;
    } else {
      toast.error(`Please select image quality for image ${item.image_name}`);
      return false;
    }
  };

  const handleAddToWishlist = async (item, index, length, values, state, price) => {
    if (item.sno) {
      let resp;
      if (values?.is_star == "1") {
        resp = await addToWishlistFromAdmin({
          user_id: userId,
          image_id: item.sno,
          type: item?.type === 'image' ? '1' : item?.type === 'video' ? '2' : '',
          country: values?.is_star == "1" ? values.country : "",
          duration: values.duration,
          industry: values.industry,
          state: values.state?.length ? values.state?.map((i) => `${i.label}`).join(',') : "",
          is_star: values.is_star,
          license: values.license,
          price: price
        });
        let payloadForLogs = {
          action: action?.AddToWishList,
          module: modulesNames?.users,
          description: logsMessagesList?.users?.userWishlist,
        };
        adminAddLogs(payloadForLogs);
      }
      else {
        resp = await addToWishlistFromAdmin({
          user_id: userId,
          image_id: item.sno,
          image_type: item.quality,
          type: item?.type === 'image' ? '1' : item?.type === 'video' ? '2' : '',
        });
        let payloadForLogs = {
          action: action?.AddToWishList,
          module: modulesNames?.users,
          description: logsMessagesList?.users?.userWishlist,
        };
        adminAddLogs(payloadForLogs);
      }
      if (resp) {
        dispatch(userWishlistApi(userId, { page: 1, limit: 5 }));
        if (length == 1) {
          handleAddToModalOpen(false, 0);
        }
        return true;
      }
      return false;
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: (theme) => theme.spacing(3), py: (theme) => theme.spacing(2) }}>
        {rightSection ? rightSection() : null}
      </Box>
      <MatTable
        showCheckbox={false}
        columns={column}
        data={tableListing}
        loading={singleUserWishlistDetail?.loading}

        action={actionBody}
        page={page}
        perPage={limit}
        total={singleUserWishlistDetail?.items?.wishlist?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getWishlistListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getWishlistListing({
            page: String(newPage)
          }, setPage)
        }}
        toolbarEnable={true}
        pagination={true}
        toolBarButton={() => {
          return (
            <>
              <CustomButton
                onClick={() => {
                  handleAddToModalOpen(true, 1);
                }}
                color={'buttonPrimary'}
                sx={(theme) => ({
                  padding: '10px 10px', marginRight: 1,
                  [theme.breakpoints.down("md")]: {
                    flex: 1,
                  }
                })}
              >
                Add to wishlist
              </CustomButton>
            </>
          );
        }}
      />
      {confirmDelete && <ConfirmationModal open={confirmDelete} handleConfirm={handleDelete} setOpen={handleCofirmModalOpen} />}
      {addToModalOpen && (
        <AddToCartWishListModal
          open={addToModalOpen}
          handleOpen={handleAddToModalOpen}
          from={from}
          heading={from == 1 ? 'Add To WishList' : 'Add To Cart'}
          initialData={initialData}
          handleAddFunctionality={
            from == 1 ? handleAddToWishlist : handleAddToCart
          }
          userId={userId}
        />
      )}
    </>

  );
}

export default UserWishlistData;
