
export const logsMessagesList = { 
    users:{
      userEdit:"User information has been updated!",
      userAdd:"A new user has been added!",
      userDelete:"User has been deleted!",
      userCart:"The image has been added to the cart!",
      userWishlist:"The image has been Added to Wishlist!",
      userDeleteFromCart:"The image has been deleted from cart!",
      userDeleteFromWishList:"The image has been deleted from WishList!",
      userCheckout:"The image has been Checkout!",
      userImageDownload:"The image has been Downloaded!",
      userImageOrder:"The image has been ordered!",
      userIVSOrder:"An IVS Order has been created!",
      userIVSOrderRenew:"An IVS Order has been renewed!",
      userUpgradeImageAddedToCart:"Image has been upgraded and added to the cart!",
    },
    contributor:{
        contributorAdd:"Contributor has been added.",
        contributorBlocked:"Contributor has been blocked.",
        contributorUnBlock:"Contributor has been unBlocked.",
        contributorDetailUpdate:"Contributor information has been updated ",
        contributorBankInfoUpdate:"Contributor bank details has been updated.",
        contributorPaymentAdd:"Payment has been added to contributor.",
        contributorPaymentUpdate:"Contributor payment has been updated.",
        contributorNOCDelete:"Contributor NOC has been deleted.",
        contributorPartPaymentMade:"Payment has been marked as paid in Contributor.",
        contributorFolderLimitUpdate:"Contributor Folder Limit has been updated",
        contributorFolderDownload:"Contributor folder has been downloaded",
        contributorMailSentForImages:"Mail has been sent to contributor for uploaded images",
        contributorImageDownload:"Contributor's image has been downloaded",
        contributorFolderImageReject:"Contributor's image has been rejected",
        contributorFolderImageApprove:"Contributor's image has been approved",
        contributorQueryResolve:"Contributor query has been resolved",
        contributorQueryPending:"Contributor query has been moved to pending",
        contributorQueryDelete:"Contributor query has been deleted",
        contributorQueryRespond:"Response sent for contributor's query "

    },
    keyword:{
      keywordVisibleCSVUpload:"Visible Keyword's CSV file has been uploaded",
      keywordVisibleAdd:"Visible keyword has been added",
      keywordVisibleLinked:"Visible keyword has been linked",
      keywordVisibleEdit:"Visible keyword has been updated",
      keywordHiddenAdd:"Hidden keyword has been added",
      keywordHiddenEdit:"Hidden keyword has been updated",
      keywordAutoSuggestionCSVUpload:"Keyword Auto Suggestion CSV has been added",
      keywordAutoSuggestionAdd:"Keyword Auto Suggestion has been added",
      keywordAutoSuggestionEdit:"Keyword Auto Suggestion has been updated",
      keywordAutoSuggestionDelete:"Keyword Auto Suggestion has been deleted"
    },
    image:{
      imageSuspend:"Image has been suspended!",
      imageUpdate:"Image data has been updated!",
      imageKeywordUpdated:"The keywords have been updated for the image!",
      imageCSVUpload:"The CSV file has been uploaded for images!",
      imageRepairKeyword:"The CSV file for repair Keywords has been uploaded in images!",
      imageAddedToElastic:"The images has been added for elastic search!",
      imageDelete:"The image has been deleted!",
      shootCategoryChange:"Shoot's category has been updated!",
      shootMerged:"Shoot has been mereged!",
      shootBlocked:"Shoot has been blocked!",
      shootUnBlocked:"Shoot has been unblocked!",
      shootImagesAssignedWaterMark:"Watermark has been assigned to the image!",
      shootImageMadeAsStar:"The image has been designated as the star image!",

    },
    video:{
      videoKeywordUpdated:"The keywords have been updated for the video!",
      videoDetailUpdate:"Video details have been updated!",
      videoSuspend:"Video has been suspended!",
      videoCSVUpload:"The CSV file has been uploaded for videos!",
      videoRepairKeyword:"The CSV file for repair Keywords has been uploaded in videos!",
      videoUpdate:"Video data has been updated!",
      videoAddedToElastic:"Video has been added for elastic search!",
    },
    order:{
      orderUpdate:"Order details has been updated!",
      pendingOrderMailSend:"The email regarding the order has been sent!",
      pendingOrderDeleted:"Pending Order has been removed!",
      pendingOrderFollowUp:"A follow-up has been made!",
      confirmOrderPoFileUpload:"A PO file has been uploaded!",
      confirmOrderInvoiceEdit:"The invoice has been revised!",
      confirmOrderInvoiceRejected:"The invoice has been declined!",
      confirmOrderMarkAsPaid:"Order has been marked as paid!",
      confirmOrderIRNGenerate:"An IRN has been generated!",
      confirmOrderMarkAsUnPaid:"Order has been marked as unpaid!",
      proposalCreate:"A proposal has been submitted!",
      proposalStarCreate:"A proposal has been submitted!",
      proposalAddedToCart:"The proposal has been transferred to the user cart!",
      proposalConfirmed:"The proposal has been approved and order created!",
      proposalDeleted:"The proposal has been deleted!",
      ivsDownload:"The client name has been updated from IVS download!",
      expiryMail:"An expiration email has been sent for the subscription package!",
    },
    subscription:{
      susbscriptionAddStandard:"A standard subscription plan has been added!",
      susbscriptionAddSpecial:"A special subscription plan has been added!",
      subscriptionUpdateStandard:"A standard subscription plan has been updated!",
      susbscriptionUpdateSpecial:"A special subscription plan has been updated!",
    },
    setting:{
     emailSetUpAdded:"A new email and department setup has been added!",
     emailSetUpUpdated:"Email setup has been updated!",
     exclusivePriceAdd:"A new exclusive pricing has been added!",
     exclusivePriceUpdate:"Exclusive pricing has been updated!",
     starCategoryAdd:"A new star category-based pricing has been added!",
     starCategoryUpdate:"Star category-based pricing has been updated!",
     starDurationAdd:"A new star duration-based pricing has been added!",
     starDurationUpdate:"Star duration-based pricing has been updated!",
     starStatewiseAdd:"A new pricing based on state has been added!",
     starStatewiseUpdate:"Star state-based pricing has been updated!",
     starCountrywiseAdd:"A new pricing based on country has been added!",
     starCountrywiseUpdate:"Star country-based pricing has been updated!",
     jobRoleAdded:"A new job role has been added!",
     jobRoleUpdated:"Job role has been updated!",
     countryAdd:"A new country has been added!",
     countryUpdate:"Country details has been updated!",
     stateAdd:"A new state has been added for the country!",
     stateUpdate:"State details has been updated!",
     promotionalMailSent:"A new notification email has been released!",
     paymentMethodDisbaled:"Payment method has been disbabled!",
     paymentMethodEnabled:"Payment method has been enabled!"
    },
    contactUs:{

    },
    adminAndAccess:{
      adminAdd:"A new admin has been added!",
      adminSuper:"A new super-admin has been added!",
      adminUpdate:"Admin's information has been upadted!",
      adminModuleAccess:"Module access has been updated for the admin!",
      adminPasswordUpdate:"Password has been updated for the admin!",
      adminDelete:"Admin has been deleted!",
    },
    searchLogs:{
      searchLogsDelete:"Search logs has been deleted!",
      searchLogsAllDelete:"All search logs has been removed!",
      zeroLogsDelete:"Zero logs has been deleted!",
      zeroLogsAllDelete:"All zero logs has been deleted!"
    },
    crm:{
      crmDailySalesAdd:"A new daily sales entry has been added!",
      crmFollowUpTaken:"Follow-up has been taken!",
      crmFollowUpUpdate:"Follow-up entry has been updated!",
      pendingFollowUp:"Follow-up on pending orders has been taken!",
      pendingMultipleFollowUp:"Follow-up for multiple pending orders has been taken!",
      outstandingPaymentMarkAsUnpaid:"Outstanding payment has been marked as unpaid!",
      companyNameUpdate:"Company name has been updated!",
      partPaysPaymentUpdated:"Partial payment has been updated!"
    },
    company:{
      companyNameAssigned:"Company name and association has been assigned!",
      companyNameUpdated:"Company name and association has been updated!",



    }

  };

  export const modulesNames={
    users:"Users",
    contributors:"Contributors",
    keywords:"Keywords",
    images:"Images",
    videos:"Videos",
    subscriptions:"Subscriptions",
    orders:"Orders",
    IRN:"IRN",
    settings:"Settings",
    contact_us:"Contact Us",
    adminAndAccess:"Admin Users & Access",
    logs:"Logs",
    crm:"CRM",
    categories:"Categories",
    companies:"Companies",
    star_indutries:"Star Industries",
    report:"Reports",
    cms:"CMS",
    model:"Models"
  }

  export const modulesNameForFilter=['Users','Contributors','Keywords','Images','Videos','Orders','Subscriptions','Settings','Contact Us','Admin Users & Access','Logs',
                                       'CRM','Categories','Companies','Star Industries','Reports','CMS','Models']

 export const action={
    add:"add",
    delete:"delete",
    update:"update",
    export:"export",
    AddToCart:"AddToCart",
    AddToWishList:"AddToWishList",
    DeleteFromCart:"DeleteFromCart",
    checkout:"checkout",
    block:"block",
    unblock:"unblock",
    markAsPaid:"mark_as_Paid",
    markAsUnpaid:"mark_as_unpaid",
    download:"download",
    emailSent:"mail_sent",
    approve:"image_approve",
    reject:"image_reject",
    resolve:"query_resolve",
    pending:"query_pending",
    response:"query_response",
    csvUpload:"CSV_upload",
    link:"link",
    suspend:"suspend",
    makeStar:"make_star",
    addWaterMark:"add_waterMark",
    add_keyword:"add_keyword",
    addedToElastic:"added_to_elastic",
    followUp:"Follow_up",
    fileUpload:"Upload_file",
    invoiceReject:"Invoice_reject",
    irnGenerate:"IRN_generate",
    order:"order",
    renew:"renew",
    upgrade:"upgrade",
    disbale:"disable",
    enable:"enable",
    assign:"assign",
  }



 
 
  