import React from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import { Grid } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomCheckbox from '../CommonComp/CustomInput/CheckboxField';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import DownloadingIcon from '@mui/icons-material/Downloading';
import { LoadingButton } from '@mui/lab';

function AllFilters({
  filtersArray,
  handleClick,
  handleClear,
  hasDownloadExcelButton,
  handleDownloadExcel,
  hasAddNew,
  handleAddNewModal,
  addText,
  handleAddtionalButton,
  addionalButton,
  addtionalText,
  hasUploadCSV,
  handleAddCSV,
  selectRefCompany,
  loading,


  extraButton = () => { }
}) {
  if (filtersArray?.length < 0) {
    return null;
  }
  return (
    <>
      <Grid spacing={2} py={0.5} container>
        {filtersArray?.map((item, index) => {
          if (!item?.isVisible) {
            return null;
          }
          if (item?.type === 'input') {

            return (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                {item?.type == 'select' ? (
                  <CustomInput
                    select
                    SelectProps={{
                      native: true,
                    }}
                    name={item?.id}
                    label={item?.label}
                    placeholder={item?.label}
                    value={item.value}
                    onChange={(e) => item?.onChange(e, index, item)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      classes: {
                        notchedOutline: 'rounded',
                      },
                    }}
                    sx={{
                      width: '100%',
                    }}
                    {...item.props}
                  >
                    {item?.dataArr?.length &&
                      item?.dataArr?.map((option, index) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                  </CustomInput>
                ) : (
                  <CustomInput
                    name={item?.id}
                    key={index}
                    label={item?.label}
                    placeholder={item?.label}
                    value={item.value}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      classes: {
                        notchedOutline: 'rounded',
                      },
                    }}
                    onChange={(e) => item?.onChange(e, index, item)}
                    onKeyDown={(e) => {

                      if (e?.keyCode == 13) {
                        let arr =
                          filtersArray?.filter((e) => e.isVisible) || filtersArray;
                        let allDatavalues = {};
                        let dataWithValue = {};
                        arr?.forEach((element) => {
                          allDatavalues = {
                            ...allDatavalues,
                            [element?.id]: element?.value,
                          };
                          if (element.value) {
                            dataWithValue = {
                              ...dataWithValue,
                              [element?.id]: element?.value,
                            };
                          }
                        });
                        handleClick(arr, allDatavalues, dataWithValue);

                      }
                    }
                    }
                    sx={{
                      width: '100%',
                    }}
                    {...item?.props}
                  />
                )}
              </Grid>
            );
          }
          else if (item?.type === 'checkbox') {
            return (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3} display={'flex'} alignItems={'center'}>
                <CustomCheckbox
                  name={item?.id}
                  key={index}
                  value={item.value}
                  labelPlacement={'end'}
                  subLableText={item?.label}
                  handleChange={(e) => item?.onChange(e, index, item)}
                  {...item?.props}
                />
              </Grid>
            );
          }
          else if (item?.type === 'async-select') {
            return (
              <Grid key={index} item xs={12} sm={6} md={4} lg={4} display={'flex'} alignItems={'center'}>
                <CustomAsyncSelect
                  promiseOptions={item?.getListings}
                  // error={error}
                  // helperText={helperText}
                  closeMenuOnSelect={false}
                  controlStyle={{
                    padding: 5,
                    height: 56,
                  }}
                  cacheOptions={false}
                  value={item?.listingData}
                  handleChange={(e) => {
                    item?.handleChangeAsyncSelect(e);
                  }}

                  width={'100%'}
                  dropDownZIndex={100000}
                  ref={item?.asyncRef}
                  labelShrink={true}
                  label={item?.label}
                  isMulti={false}
                  onFocus={() => {
                    item?.setListingData(null);
                  }}
                  {...item.props}
                />

              </Grid>
            );
          }
          else {
            return (
              <>
              {item?.rowFlexible?(<Grid key={index} item xs={12} sm={6} md={item?.row} lg={item?.row}>
                {item?.type == 'select' ? (
                  <CustomInput
                    select
                    SelectProps={{
                      native: true,
                    }}
                    name={item?.id}
                    label={item?.label}
                    placeholder={item?.label}
                    value={item.value}
                    onChange={(e) => item?.onChange(e, index, item)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      classes: {
                        notchedOutline: 'rounded',
                      },
                    }}
                    sx={{
                      width: '100%',
                    }}
                    {...item.props}
                  >
                    {item?.dataArr?.length &&
                      item?.dataArr?.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                  </CustomInput>
                ) : (
                  <CustomInput
                    name={item?.id}
                    key={index}
                    label={item?.label}
                    placeholder={item?.label}
                    value={item.value}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      classes: {
                        notchedOutline: 'rounded',
                      },
                    }}
                    onChange={(e) => item?.onChange(e, index, item)}
                    sx={{
                      width: '100%',
                    }}
                    {...item?.props}
                  />
                )}

              </Grid>):(
              <Grid key={index} item xs={12} sm={6} md={4} lg={2}>
                {item?.type == 'select' ? (
                  <CustomInput
                    select
                    SelectProps={{
                      native: true,
                    }}
                    name={item?.id}
                    label={item?.label}
                    placeholder={item?.label}
                    value={item.value}
                    onChange={(e) => item?.onChange(e, index, item)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      classes: {
                        notchedOutline: 'rounded',
                      },
                    }}
                    sx={{
                      width: '100%',
                    }}
                    {...item.props}
                  >
                    {item?.dataArr?.length &&
                      item?.dataArr?.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                  </CustomInput>
                ) : (
                  <CustomInput
                    name={item?.id}
                    key={index}
                    label={item?.label}
                    placeholder={item?.label}
                    value={item.value}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      classes: {
                        notchedOutline: 'rounded',
                      },
                    }}
                    onChange={(e) => item?.onChange(e, index, item)}
                    sx={{
                      width: '100%',
                    }}
                    {...item?.props}
                  />
                )}

              </Grid>
            )}
              </>
            );
          }
        })}
        <Grid item>
          <CustomButton
            type="submit"
            color={'buttonPrimary'}
            sx={{ mt: 1 }}
            onClick={() => {
              let arr =
                filtersArray?.filter((e) => e.isVisible) || filtersArray;
              let allDatavalues = {};
              let dataWithValue = {};
              arr?.forEach((element) => {
                allDatavalues = {
                  ...allDatavalues,
                  [element?.id]: element?.value,
                };
                if (element.value) {
                  dataWithValue = {
                    ...dataWithValue,
                    [element?.id]: element?.value,
                  };
                }
              });
              handleClick(arr, allDatavalues, dataWithValue);
            }}
          >
            Submit
          </CustomButton>
          <CustomButton
            type="submit"
            color={'buttonSecondary'}
            sx={{ mt: 1 }}
            onClick={() => {
              handleClear();
            }}
          >
            Clear Filter
          </CustomButton>
          <>
            {hasDownloadExcelButton && (
              <CustomButton
                type="submit"
                color={'buttonPrimary'}
                sx={{ mt: 1 }}
                onClick={() => {
                  let arr =
                    filtersArray?.filter((e) => e.isVisible) || filtersArray;
                  let allDatavalues = {};
                  let dataWithValue = {};
                  arr?.forEach((element) => {
                    allDatavalues = {
                      ...allDatavalues,
                      [element?.id]: element?.value,
                    };
                    if (element.value) {
                      dataWithValue = {
                        ...dataWithValue,
                        [element?.id]: element?.value,
                      };
                    }
                  });
                  handleDownloadExcel(arr, allDatavalues, dataWithValue);
                }}
              >
                {loading? 'Loading...': 'Export XLS'}
               
              </CustomButton>
              
            )}
          </>
          {
            hasAddNew && <CustomButton type="submit" color={'buttonPrimary'} sx={{ mt: 1 }} onClick={() => {
              handleAddNewModal()
            }}> {addText ? addText : 'Add Model'}</CustomButton>
          }
          {
            addionalButton && <CustomButton type="submit" sx={{ mt: 1 }} onClick={() => {
              handleAddtionalButton()
            }}> {addtionalText ? addtionalText : 'Addional'}</CustomButton>
          }

          {
            hasUploadCSV && <CustomButton type="submit" color={'buttonPrimary'} sx={{ mt: 1 }} onClick={() => {
              handleAddCSV()
            }}>Upload CSV</CustomButton>
          }
          {extraButton && extraButton()}
        </Grid>

      </Grid>
    </>
  );
}

export default AllFilters;
