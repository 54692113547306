import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { insertProposalToCart } from '../../Services/proposalApis';
import { getProposalListing } from '../../Services/OrderApis';
import { adminAddLogs } from '../../Services/commonService';
import {
  action,
  logsMessagesList,
  modulesNames,
} from '../../data/logsMessages';
const limit = 10;

function ProposalInsertToCartModal({ open, handleOpen, setPage, proposalId }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await insertProposalToCart(values)
    if(resp){
      let payloadForLogs = {
        action: action?.add,
        module: modulesNames?.orders,
        description: logsMessagesList?.order?.proposalAddedToCart,
      };
      await adminAddLogs(payloadForLogs);
      resetForm({
        user_name : "",
        proposal_id : "",
      })
      setPage(1)
      dispatch(getProposalListing({
        page: String(1),
        limit: limit
      }))
      handleOpen(false)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      user_name : "",
      proposal_id : proposalId || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      user_name: Yup.string().email('Please enter valid email.').required('Username is required.'),
      proposal_id: Yup.string().required('Proposal Id is required.')
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
        fullWidth
      >
        <DialogTitle variant='h3'>Insert Proposal To Cart</DialogTitle>
        <DialogContent>
        <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="proposal_id"
                  label="Proposal ID"
                  placeholder="Proposal ID"
                  value={formik.values.proposal_id}
                  disabled
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.proposal_id && Boolean(formik.errors.proposal_id)}
                  helperText={formik.touched.proposal_id && formik.errors.proposal_id}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="user_name"
                  label="Username"
                  placeholder="Username"
                  value={formik.values.user_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.user_name && Boolean(formik.errors.user_name)}
                  helperText={formik.touched.user_name && formik.errors.user_name}
                />
              </Grid>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}>
              <CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Confirm</CustomButton>
              <CustomButton
                onClick={() => {
                    formik.resetForm({
                      user_name : "",
                      proposal_id : "",
                    });
                    handleOpen(false)}
                }
                disabled={loading}
              >Cancel</CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ProposalInsertToCartModal;
