import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { crypt, formatDate, getNewParameters, getObjectSubset, getParameters, showPaymentMethodName } from '../../lib/helper';
import { imageListingItemsSelector } from '../../redux/image';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MatTable from '../Tables/MatTable';
import LockIcon from '@mui/icons-material/Lock';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import ConfirmationModal from '../Modals/ConfirmationModal';
import { adminAddLogs, appSettingApi, updateSettingApi } from '../../Services/commonService';
import { toast } from 'react-toastify';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';

const column = [

  {
    label: 'SNo.',
    id: 'sr',
    format: (columnData, rowIndex, formatCallback, func, errors, row) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap", marginLeft: '2px' }} >
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}

        >
          {rowIndex + 1}

        </Typography>
      </span>

    )
  },

  {
    label: 'Payment Method',
    id: 'key_name',
    format: (columnData, rowIndex, formatCallback, func, errors, row) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
        >
          {showPaymentMethodName(row?.key_name)}

        </Typography>
      </span>

    )
  },


];

function PaymentMethods({ data }) {
  const [tableListing, setTableListing] = useState([data]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, allShootIds } = useSelector(imageListingItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [deleteData, setDeleteData] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [limit, setLimit] = useState(20);


  const getListing = async (params = {}) => {
    dispatch(appSettingApi());
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  const handleConfirmBlock = async (value) => {
    if (value && deleteData) {
      try {
        let payload = {
          parent_key: 'payment_methods',
          key_name: deleteData?.key_name,
          key_value: JSON.stringify(deleteData?.key_value == 1 ? 0 : 1)
        }
        // Using update api here because adding new field here is going inside key_value as stringify json
        let resp = await updateSettingApi(payload)
        if (resp) {
          await getListing();
          let descriptionPayload=deleteData?.key_value == 1?logsMessagesList?.setting?.paymentMethodDisbaled:logsMessagesList?.setting?.paymentMethodEnabled
          let payloadForLogs = {
            action:deleteData?.key_value == 1?action?.disbale:action?.enable,
            module: modulesNames?.settings,
            description:descriptionPayload
          };
          await adminAddLogs(payloadForLogs);
        }

      } catch (e) {
        console.log(e, 'error');
      }

    }
  }

  //making data format for table input
  useEffect(() => {
    if (data?.length > 0) {
      setTableListing(data);
    }
  }, [data]);

  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  };

  const handleIfTwoMethopdsEnabled = (data1) => {
    let enabledItem = data?.filter((meth) => meth?.key_value === '1');
    if (enabledItem?.length < 3) {
      toast.error("Atleast 2 methods should be Enabled!", {
        autoClose: 2500,
      });
    }
    else {
      handleCofirmModalOpen(true);
      setDeleteData(data1);

    }
  }
  
  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          {data?.key_value == 1 ? <>
            <CustomButton
              toolTipTittle={'Disable'}
              onClick={() => {
                handleIfTwoMethopdsEnabled(data)
              }}
              className="buttonDense noMinWidth"
              sx={{
                bgcolor: 'buttonSuccess.main',
                color: 'buttonSuccess.contrastText',
              }}
            >
              <LockPersonIcon />

            </CustomButton>
          </> : <>
            <CustomButton
              toolTipTittle={'Enable'}
              onClick={() => {
                handleCofirmModalOpen(true);
                setDeleteData(data);
              }}
              className="buttonDense noMinWidth"

              sx={{
                bgcolor: 'buttonDanger.main',
                color: 'buttonDanger.contrastText',
              }}
            >
              <LockIcon />
            </CustomButton>
          </>}

        </div>

      </>
    );
  };

  return (
    <>
      <MatTable
        loading={allShootIds?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        showPageLimit={true}
        pageLimitArray={[20, 50, 100, 200]}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        total={data?.length || 0}
        setPage={setPage}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        pagination={true}
        filtersUrlData={allParams || {}}
      />

      {confirmDelete && (
        <ConfirmationModal
          open={confirmDelete}
          handleConfirm={handleConfirmBlock}
          setOpen={handleCofirmModalOpen}
        />
      )}
    </>
  );
}

export default PaymentMethods;
