import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { categoryOfShoot } from '../../data/appSettingsData';
import { chnageShootCategory } from '../../Services/imageApis';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

function ChangeShootsCategoryModal({ open, handleOpen, data, setPage, handleLast }) {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await chnageShootCategory(values)
    if (resp) {
      let payloadForLogs = {
        action: action?.update,
        module: modulesNames?.images,
        description: logsMessagesList?.image?.shootCategoryChange,
      };
      await adminAddLogs(payloadForLogs);
      resetForm({
        category: "",
        shoot_id: ""
      })
      setPage(1)
      handleLast();
      handleOpen(false)
    }
    setLoading(false)

  };

  const formik = useFormik({
    initialValues: {
      category: data?.category,
      shoot_id: data?.shoot_id,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      category: Yup.string().required('Category is required.'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        fullWidth
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Change Category

        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Typography
              variant='span'
              // style={{ whiteSpace: 'nowrap' }}
              paddingBottom={5}
            >
              Shoot:{data?.shoot_id}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sm={12}>

                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="category"
                  label="Category"
                  placeholder="Category"
                  value={formik.values.category}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                  onChange={formik.handleChange}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.category && Boolean(formik.errors.category)}
                  helperText={formik.touched.category && formik.errors.category}
                >
                  <option value={''}>Select</option>
                  {categoryOfShoot?.map((dep, index) => {
                    return <option value={dep?.value} key={`${dep}${index}`}>{dep?.value}</option>
                  })}
                </CustomInput>
              </Grid>

            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Submit
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    category_name: '',

                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ChangeShootsCategoryModal;
