import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { editAutoSuggestion } from '../../Services/autoSuggestion';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

function EditAutoSuggestion({ data, open, handleOpen, setPage, handleLast }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await editAutoSuggestion(values)
    if (resp) {
      let payloadForLogs = {
        action: action?.update,
        module: modulesNames?.keywords,
        description: logsMessagesList?.keyword?.keywordAutoSuggestionEdit,
      };
      await adminAddLogs(payloadForLogs);
      resetForm({
        autosuggest_id: "",
        keyword: "",
        display_keyword: "",
        rank: "",

      })
      setPage(1)
      handleLast();
      handleOpen(false)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      autosuggest_id: data?.sno,
      keyword: data?.Keyword || "",
      display_keyword: data?.displayKeyword || "",
      rank: data?.rank || "",

    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      autosuggest_id: Yup.string().required('SNO is required.').matches(/^[0-9]+$/, "Must be only digits"),
      rank: Yup.string().required('Rank is required.').matches(/^[0-9]+$/, "Must be only digits"),
      display_keyword: Yup.string().required('Display Keyword  is required.'),
      keyword: Yup.string().required('Keyword is required.')
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Edit Suggestion
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="autosuggest_id"
                  label="Sr number"
                  disabled
                  placeholder="Sr number"
                  value={formik.values.autosuggest_id}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.sno && Boolean(formik.errors.sno)}
                  helperText={formik.touched.sno && formik.errors.sno}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="rank"
                  label="Rank"
                  placeholder="Rank"
                  value={formik.values.rank}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.rank && Boolean(formik.errors.rank)}
                  helperText={formik.touched.rank && formik.errors.rank}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="keyword"
                  label="Keyword"
                  placeholder="Keyword"
                  value={formik.values?.keyword}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.keyword && Boolean(formik.errors.keyword)}
                  helperText={formik.touched.keyword && formik.errors.keyword}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="display_keyword"
                  label="Display Keyword"
                  placeholder="Display Keyword"
                  value={formik.values.display_keyword}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.display_keyword && Boolean(formik.errors.display_keyword)}
                  helperText={formik.touched.display_keyword && formik.errors.display_keyword}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Save
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    autosuggest_id: data?.sno,
                    Keyword: data?.Keyword || "",
                    displayKeyword: data?.displayKeyword || "",
                    rank: data?.rank || "",
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EditAutoSuggestion;
